<template>
    <el-dialog
      :ref="dialoginfo.modlecode + 'dialog'"
      :title="dialoginfo.name +dialogremark"
      v-model="visiable"
      width="1700px"
      :show-close="false"
      @close="cleardata"
      center
    >
      <span>
        <el-form
          :ref="dialoginfo.modlecode + 'dialogform'"
          inline
          :model="thisdata"
          :rules="thisrules"
          label-width="100px"
        >
          <el-form-item label="合同归属" >
            <ContacrsSelect
                :disabled="dialoginfo.modle == 'view' ||dialoginfo.modle == 'edit' || thisdata.status >= 0 || thisdata.bills.length > 0 || thisdata.frees.length > 0 "
                :code="contacts_code_select"
                width="450"
                :clearable="true"
                @datachange="contacrscg"
            />
          </el-form-item>
         
  
          <el-form-item label="单据摘要" >
            <el-input
              :disabled="dialoginfo.modle == 'view' || thisdata.status >=0"
              v-model="thisdata.remark"
              style="width: 260px"
              autocomplete="off"
              
            />
          </el-form-item>
          &nbsp;&nbsp;
          <el-upload
          v-model:file-list="fileList"
          class="upload-demo"
          method="post"
          style="display: inline-block"
          action="/api_v3/interface/uploadFile"
          accept=".xlsx"
          :data="{ act:'updatainvoapp',userid:this.userid ,contacts_code:this.contacts_code,longtimeop:this.longtimeop}"
          :show-file-list="false"
          :before-upload="uploadbefore"
          :on-success="uploadsuccess"
          :on-error="uploaderror"
          :limit="1"
          ><el-button size="small">上传对账开票文件</el-button>
          
          </el-upload>&nbsp;
          <el-button  @click="downmobi" size="small">下载模板</el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;应开：{{(this.totalbillamount/100).toFixed(2)}}-{{(this.totalbeforamount/100).toFixed(2)}}-{{(this.totalfreeamount/100).toFixed(2)}}={{((this.totalbillamount-this.totalbeforamount-this.totalfreeamount)/100).toFixed(2)}}；实开：{{(this.totalinvamount/100).toFixed(2)}}；票折：<span v-if="(this.totalbillamount-this.totalbeforamount)!=0">{{(this.totalfreeamount/(this.totalbillamount-this.totalbeforamount)*100).toFixed(2)}}%</span><span v-else>--</span>
          

          
  
         
        </el-form>
  
        <el-tabs v-model="activeName">
          <el-tab-pane label="账单明细" name="first">
            <el-button
              @click="openaddbill"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >= 50
              "
              size="small"
            >
              添加
            </el-button>
           
            &nbsp; &nbsp; 
            <el-button
              @click="dellgroup"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >= 50
              "
              size="small"
            >
              解除绑定
            </el-button>
            <el-table
              :data="thisdata.bills"
              style="width: 100%; margin-top: 5px"
              height="450px"
              :header-cell-style="tableheadClass"
              @selection-change="billsselechange"
              stripe
              row-key="row_code"
              border
              default-expand-all
              :tree-props="{}"
            >
              <el-table-column type="selection" width="40" align="center" />
              <el-table-column
                prop="bill_no"
                label="单据编号"
                width="180"
                align="center"
              />
              <el-table-column
                prop="buyer_order_num"
                label="客户订单号"
                width="130"
              />
              <el-table-column prop="buyer_name" label="客户名称" width="200" />
  
              <el-table-column
                prop="posted_time"
                label="过账日期"
                width="100"
                :formatter="dateformart"
                align="center"
              />
              <el-table-column prop="goods_name" label="商品名称" width="200" />
              <el-table-column label="账单数量" width="100" align="right">
                <template #default="scope">
                  {{(scope.row.main_num / 10000).toFixed(2)+scope.row.main_unit+"\n"+ (scope.row.sec_num / 10000).toFixed(2)+scope.row.sec_unit }}
                </template>
              </el-table-column>
             
              
              <el-table-column label="本次开票数量" width="120" align="right">
                <template #default="scope">
                  {{(scope.row.reconciliation_num / 10000).toFixed(2)+scope.row.main_unit+"\n"+ (scope.row.sec_num*scope.row.reconciliation_num/scope.row.main_num / 10000).toFixed(2)+scope.row.sec_unit }}
                </template>
              </el-table-column>
              <el-table-column label="金额" width="180" align="right">
                <template #default="scope">
                  {{"账单金额："+ (scope.row.sale_amount / 100).toFixed(2)+"\n剩余金额："+(scope.row.remain_amount / 100).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="本次开票金额" width="120" align="right">
                <template #default="scope">
                  {{(scope.row.reconciliation_amount / 100).toFixed(2)}}
                </template>
              </el-table-column>
             
              <el-table-column prop="remark" label="单据备注" width="200" />
              <el-table-column width="80" align="center">
                <template #header> </template>
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="dellbill(scope.row)"
                    :disabled="
                      dialoginfo.modle == 'view' ||
                      thisdata.canchange == false ||
                      thisdata.status >= 50
                    "
                    size="small"
                  >
                    删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="账单调整" name="five">

            <el-button
              @click="openaddadfree2"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >=0
              "
              size="small"
              >添加费用明细</el-button
            >


            <el-table
              :data="thisdata.beforinv"
              style="width: 100%; margin-top: 5px"
              height="450px"
              :header-cell-style="tableheadClass"
              stripe
              row-key="row_code"
              border
              default-expand-all
              :tree-props="{}"
            >
              
  
              <el-table-column label="折扣项目名称" width="400">
                <template #default="scope">
                  <span v-if="scope.row.v_name == ''"></span>
                  <span v-else>{{ scope.row.free_name }}</span>
                </template>
              </el-table-column>
              
  

              <el-table-column label="本次对账金额" width="220" align="right">
                <template #default="scope">
                  {{ (scope.row.reconciliation_amount / 100).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="discountremark" label="折扣摘要" width="400"
                ><template #default="scope">
                  {{
                    
                    scope.row.remark 
                  }}
                </template>
              </el-table-column>
              <el-table-column width="80" align="center">
                <template #header> </template>
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="dellbefor(scope.row)"
                    :disabled="
                      dialoginfo.modle == 'view' ||
                      thisdata.canchange == false ||
                      thisdata.status >= 0
                    "
                    size="small"
                  >
                    删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          
          <el-tab-pane label="票折明细" name="second">
            <el-button
              @click="addbillfree"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >= 0
              "
              size="small"
            >
              引入匹配费用</el-button
            >

            <el-button
              @click="openaddfree"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >= 0
              "
              size="small"
              >选择费用明细</el-button
            >
            <el-button
              @click="openaddadfree"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >= 0
              "
              size="small"
              >添加费用明细</el-button
            >
            <el-button
              @click="cleanbillfree"
              :disabled="
                dialoginfo.modle == 'view' ||
                thisdata.canchange == false ||
                thisdata.status >= 0
              "
              size="small"
              >清空费用明细</el-button
            >

            <el-table
              :data="thisdata.frees"
              style="width: 100%; margin-top: 5px"
              height="450px"
              :header-cell-style="tableheadClass"
              stripe
              row-key="row_code"
              border
              default-expand-all
              :tree-props="{}"
              @selection-change="freesselechange"
            >
              <el-table-column type="selection" width="40" align="center" />
              <el-table-column
                prop="bill_no"
                label="单据编号"
                width="180"
                align="center"
              />
              <el-table-column
                prop="customer_order_no"
                label="客户订单号"
                width="130"
              />
              <el-table-column prop="buyer_name" label="客户名称" width="200" />
  
              <el-table-column label="折扣项目名称" width="200">
                <template #default="scope">
                  <span v-if="scope.row.v_name == ''"></span>
                  <span v-else>{{ scope.row.free_name }}</span>
                </template>
              </el-table-column>
              
  
              <el-table-column label="折扣金额" width="120" align="right">
                <template #default="scope">
                  {{ (scope.row.free_amount / 100).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="剩余金额" width="120" align="right">
                <template #default="scope">
                  {{ (scope.row.remain_free_amount / 100).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="本次对账金额" width="120" align="right">
                <template #default="scope">
                  {{ (scope.row.reconciliation_amount / 100).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="discountremark" label="折扣摘要" width="300"
                ><template #default="scope">
                  {{
                    
                    scope.row.remark 
                  }}
                </template>
              </el-table-column>
              <el-table-column width="80" align="center">
                <template #header> </template>
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="dellfree(scope.row)"
                    :disabled="
                      dialoginfo.modle == 'view' ||
                      thisdata.canchange == false ||
                      thisdata.status >= 0
                    "
                    size="small"
                  >
                    删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="开票清单" name="four">
            <el-button
              @click="openmakeinv"
              :disabled="dialoginfo.modle == 'view' || thisdata.status >= 0 || thisdata.canchange==false"
              size="small"
            >
              生成发票</el-button
            >
            <el-button
              @click="pullinvovisable = true"
              :disabled="dialoginfo.modle == 'view' || thisdata.status >= 0 || thisdata.canchange==false"
              size="small"
              >引入发票
            </el-button>
  
            
            <el-table
              :data="thisdata.invos"
              style="width: 100%; margin-top: 5px"
              height="450px"
              :header-cell-style="tableheadClass"
              stripe
              row-key="row_code"
              border
              default-expand-all
              :tree-props="{}"
            >
              <el-table-column width="120" align="left">
                <!--  -->
                <template #header>开票抬头 </template>
                <template #default="scope">
                  {{ scope.row.platename }}
                </template>
              </el-table-column>
              <el-table-column width="680" align="left">
                <template #default="scope">
                  <span style="padding: 0px; margin: 0px; text-indent: -12em"
                    ><b>购货客户名称：</b>{{ scope.row.buyername }}</span
                  ><br />
                  <span style="padding: 0px; margin: 0px; text-indent: -12em"
                    ><b>购货客户税号：</b>{{ scope.row.buyertaxnum }}</span
                  ><br />
                  <span style="padding: 0px; margin: 0px; text-indent: -12em"
                    ><b>电子邮件：</b>{{ scope.row.email }}</span
                  >
                 
                </template>
              </el-table-column>
              <el-table-column prop="inv_type" width="260" align="left">
                <template #default="scope">
                  <span style="padding: 0px; margin: 0px; text-indent: -12em"
                    ><b>发票日期：</b>
                    {{ dateformart2(scope.row.inv_data) }}</span
                  ><br />
                  <span style="padding: 0px; margin: 0px; text-indent: -12em"
                    ><b>发票号码：</b> {{ scope.row.tax_code+scope.row.tax_num }}</span
                  ><br />
                  <span
                    style="padding: 0px; margin: 0px; text-indent: -12em"
                    v-if="scope.row.inv_type == '000'"
                    ><b>发票类型：</b>待定</span
                  >
                  <span
                    style="padding: 0px; margin: 0px; text-indent: -12em"
                    v-else-if="scope.row.inv_type == '007'"
                    ><b>发票类型：</b>纸普</span
                  >
                  <span
                    style="padding: 0px; margin: 0px; text-indent: -12em"
                    v-else-if="scope.row.inv_type == '026'"
                    ><b>发票类型：</b>数电票（普通）</span
                  >
                  <span
                    style="padding: 0px; margin: 0px; text-indent: -12em"
                    v-else-if="scope.row.inv_type == '004'"
                    ><b>发票类型：</b>纸专</span
                  >
                  <span
                    style="padding: 0px; margin: 0px; text-indent: -12em"
                    v-else-if="scope.row.inv_type == '028'"
                    ><b>发票类型：</b>数电票（专用）</span
                  >
                  <span
                    style="padding: 0px; margin: 0px; text-indent: -12em"
                    v-else
                    ><b>发票类型：</b>数据错误</span
                  >
                  
                </template>
              </el-table-column>
              <el-table-column label="发票金额" width="100" align="right">
                <template #default="scope">
                  {{ (scope.row.total_amount / 100).toFixed(2) }}
                </template>
              </el-table-column>
  
              <el-table-column  label="备注" width="190" >
      <template #default="scope">
        <span v-if="scope.row.old_codenum != ''">原蓝字发票号：{{ scope.row.old_codenum }}</span>
        <span v-if="scope.row.red_radcode != ''">红字申请单号：{{ scope.row.red_radcode }}</span>
        
        <span v-if="scope.row.status == '10'">
          错误信息：<el-tooltip :content=scope.row.request placement="top" ><span>[{{ scope.row.request.substring(0,24)+"…" }}]</span></el-tooltip><br/>
        </span>
        
        {{ scope.row.remark }}
      </template>
    </el-table-column>

              <el-table-column label="发票状态" width="100" align="right">
                <template #default="scope">
                  <span v-if="scope.row.status == '0'">待开票</span>
                  <span v-else-if="scope.row.status == '-1'">待确认</span>
                  <span v-else-if="scope.row.status == '10'">出现错误</span>
                  <span v-else-if="scope.row.status == '20'">发送中</span>
                  <span v-else-if="scope.row.status == '30'">待红冲</span>
                  <span v-else-if="scope.row.status == '40'">已开具</span>
                  <span v-else-if="scope.row.status == '95'">已红冲</span>
                  <span v-else-if="scope.row.status == '99'">未开删除</span>
                  <span v-else>数据错误</span>
                </template>
              </el-table-column>
              <el-table-column width="80" align="center">
                <template #header> 操作</template>
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="viewinvos(scope.row)"
                    size="small"
                  >
                    查看</el-button
                  ><br />
                  <span
                    v-if="
                      !(dialoginfo.modle == 'view')
                    "
                  >
                    <el-button
                      type="text"
                      @click="dellinvo(scope.row)"
                      :disabled="
                        dialoginfo.modle == 'view' ||
                        !((scope.row.status>=0 && scope.row.source ==3 ) || (scope.row.status<0 && scope.row.source ==2))
                      "
                      size="small"
                    >
                      删除</el-button
                    ><br />
                  </span>
                  <span v-if="scope.row.status == 40 && false">
                    <el-button
                      type="text"
                      @click="disableinvos(scope.row)"
                      :disabled="
                        (scope.row.inv_type != '007' &&
                          scope.row.inv_type != '004') ||
                        getmounth(scope.row.inv_data) != getmounth('') ||
                        scope.row.status != 40 ||
                        scope.row.inv_direction != 1
                      "
                      size="small"
                      >作废</el-button
                    ><br />
                  </span>
                  <span v-if="scope.row.status == 40 && scope.row.inv_direction ==1 && this.operar.check">
                    <el-button
                      type="text"
                      @click="redinvos(scope.row)"
                      :disabled=" thisdata.canchange==false "
                      size="small"
                    >
                      红冲</el-button
                    >
                  </span>
                  <span v-if=" scope.row.status == 40 && scope.row.inv_direction ==2">
                    <el-button
                      type="text"
                      @click="renewinvos(scope.row)"
                      :disabled=" thisdata.canchange==false"
                      size="small"
                    >
                      重开</el-button
                    >
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </span>
  
      <template #footer>
        <span class="dialog-footer"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button @click="visiable = false">关 闭</el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-button
            
            v-show="dialoginfo.modle != 'view' && thisdata.status < 0 "
            :disabled=" totalbillamount-totalfreeamount==0 || !thisdata.canchange"
            @click="savedata"
            >保 存
          </el-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button
            type="primary"
            v-show="dialoginfo.modle != 'view' && thisdata.status < 0 && this.operar.check "
            :disabled=" totalbillamount-totalbeforamount-totalfreeamount!=totalinvamount || totalbillamount-totalbeforamount-totalfreeamount==0  || !thisdata.canchange"
            @click="checkdata"
            >保 存 && 确 认
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="添加账单明细"
      v-model="addbillvisiable"
      width="1550px"
      :show-close="false"
      @close="clearaddbilldata"
      center
      ><span>
        <el-form
          :model="addbillsearchdata"
          ref="searchform"
          :inline="true"
          label-width="150px"
        >
          <el-form-item label="单据日期：" prop="doctimes">
            <el-date-picker
              v-model="addbillsearchdata.doctimes"
              type="daterange"
              range-separator="至"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
              @change="addbillsdataareachange_doctimes"
            />
          </el-form-item>
          <el-form-item label="审核日期：" prop="posttimes">
            <el-date-picker
              v-model="addbillsearchdata.posttimes"
              type="daterange"
              range-separator="至"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
              @change="addbullsdataareachange_posttimes"
            />
          </el-form-item>
          
          
       
          <el-form-item label="购货客户:" prop="buyerinfor">
            <BuyerInformationSeltct
              :code="addbillsearchdata.buyerinfor"
              width="200"
              :ismultiple="true"
              @datachange="addbillsbuyerinforcg"
              :iscleanable="true"
            />
          </el-form-item>
          <el-form-item label="仓库名称:" prop="warehouse">
            <el-input
              v-model="addbillsearchdata.warehouse"
              style="width: 220px"
              autocomplete="off"
            />
            
          </el-form-item>
  

          <el-form-item label="单据信息过滤 ：" prop="docnum">
            <el-input
              v-model="addbillsearchdata.docnum"
              width="300"
              clearable
              type="textarea"
              class="searchinput"
              autocomplete="off"
              placeholder="支持出入库单号、账单号、客户订单号、备注"
            />
          </el-form-item>
       

          <el-button @click="clearaddbilldata" size="small">重 置</el-button>
          <el-button @click="addbillsseacrchstart" size="small">查 询</el-button>
          <el-button @click="addbillsviewsele" size="small">选中项</el-button>
          合计金额:{{ addbilltotalamountstr  }}
        </el-form>
        <el-table
          :data="billssearchresults.rows"
          style="width: 100%; margin-top: 5px"
          height="450px"
          :header-cell-style="tableheadClass"
          stripe
          row-key="bill_dtl_code"
          border
          default-expand-all
          :tree-props="{}"
          ref="addbillstables"
          @selection-change="addbilltableselechange"
          @cell-click="billsadddetabClick"
        >
          <el-table-column type="selection" width="40" align="center" />
          <el-table-column
            prop="bill_no"
            label="单据编号"
            width="180"
            align="center"
          >
          <template #default="scope">
              {{ scope.row.bill_no  +"\n"+scope.row.out_no  }}
            </template>
        </el-table-column>
          <el-table-column
            prop="customer_order_no"
            label="客户订单号"
            width="120"
          />
          <el-table-column prop="buyer_name" label="客户名称" width="160" />
  
          <el-table-column
            prop="posted_time"
            label="过账日期"
            width="110"
            :formatter="dateformart"
            align="center"
          />
          <el-table-column prop="goods_name" label="商品名称" width="160" />
          <el-table-column label="账单数量" width="100" align="right">
            <template #default="scope">
              {{ (scope.row.main_num / 10000).toFixed(2)+scope.row.main_unit +"\n"+(scope.row.sec_num / 10000).toFixed(2)+scope.row.sec_unit }}
            </template>
          </el-table-column>
          <el-table-column label="剩余数量" width="100" align="right">
            <template #default="scope">
              {{ (scope.row.main_remain_inv_num / 10000).toFixed(2)+scope.row.main_unit +"\n"+(scope.row.main_remain_inv_num/scope.row.main_num*scope.row.sec_num / 10000).toFixed(2)+scope.row.sec_unit }}
            </template>
          </el-table-column>
          
          <el-table-column label="含税金额" width="150" align="right">
            <template #default="scope">
              {{ "账单金额："+(scope.row.sale_amount / 100).toFixed(2)+"\n剩余金额："+(scope.row.sale_remain_inv_amount / 100).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="now_edit" label="本次核销" width="150" align="right">
            <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'now_edit'
              ">
                {{ "金额："+(scope.row.nowcc_amount / 100).toFixed(2)+"\n数量："+(scope.row.nowcc_num / 10000).toFixed(2) +scope.row.main_unit }}
                    
                </span><span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 90px"
                @change="((val)=>{tableeditchange(val,'billsaddre',scope.row)})"
              />
            </span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="单据备注" width="220" />
        </el-table>
        <el-pagination
          background
          @size-change="addbillshandleSizeChange"
          @current-change="addbillshandleCurrentChange"
          :current-page="addbillspagenow"
          :page-sizes="[50, 100, 300, 500, 1000]"
          :page-size="addbillspagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="addbillstotalrow"
        >
        </el-pagination>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addbillvisiable = false">取 消</el-button>
          <el-button type="primary" @click="saveaddbilldata">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="添加票折明细"
      v-model="addfreevisiable"
      width="1550px"
      :show-close="false"
      @close="clearaddfreedata"
      center
      ><span>
        <el-form :model="addfreesearchdata" :inline="true" label-width="130px">
          <el-form-item label="单据日期：" prop="doctimes">
            <el-date-picker
              v-model="addfreesearchdata.doctimes"
              type="daterange"
              range-separator="至"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
              @change="addfreesdataareachange_doctimes"
            />
          </el-form-item>
          <el-form-item label="审核日期：" prop="posttimes">
            <el-date-picker
              v-model="addfreesearchdata.posttimes"
              type="daterange"
              range-separator="至"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
              @change="addfreesdataareachange_posttimes"
            />
          </el-form-item>
          

          
          
       
          <el-form-item label="购货客户:" prop="buyerinfor">
            <BuyerInformationSeltct
              :code="addfreesearchdata.buyerinfor"
              width="200"
              :ismultiple="true"
              @datachange="addfreesbuyerinforcg"
              :iscleanable="true"
            />
          </el-form-item>
          <el-form-item label="仓库名称:" prop="warehouse">
            <el-input
              v-model="addfreesearchdata.warehouse"
              style="width: 220px"
              autocomplete="off"
            />
            
          </el-form-item>
          <el-form-item label="单据信息过滤 ：" prop="docnum">
            <el-input
              v-model="addfreesearchdata.docnum"
              width="300"
              clearable
              type="textarea"
              class="searchinput"
              autocomplete="off"
              placeholder="支持出入库单号、账单号、客户订单号、备注"
            />
          </el-form-item>
          <el-button @click="clearaddfreedata" size="small">重 置</el-button>
          <el-button @click="addfreesseacrchstart" size="small">查 询</el-button>
          <el-button @click="addfreesviewsele" size="small">选中项</el-button>
          合计金额:{{ addfreetotalamountstr  }}
        </el-form>
        <el-table
          :data="freessearchresults"
          style="width: 100%; margin-top: 5px"
          height="450px"
          :header-cell-style="tableheadClass"
          @cell-click="billsadddetabClick"
          stripe
          row-key="bill_dtl_code"
          border
          default-expand-all
          :tree-props="{}"
          ref="addfreestables"
          @selection-change="addfreetableselechange"
        >
          <el-table-column type="selection" width="40" align="center" />
          <el-table-column
            prop="bill_no"
            label="对应单据编号"
            width="180"
            align="center"
          >
            <template #default="scope">
              {{ scope.row.bill_no  +"\n"+scope.row.out_no  }}
            </template>
          </el-table-column> 
          <el-table-column
            prop="customer_order_no"
            label="客户订单号"
            width="220"
          />
          <el-table-column prop="buyer_name" label="客户名称" width="180" />
  
          <el-table-column label="折扣项目名称" width="200">
            <template #default="scope">
              <span v-if="scope.row.v_name == ''"></span>
              <span v-else>{{ scope.row.v_name }}</span>
            </template>
          </el-table-column>
         
  
          <el-table-column label="折扣金额" width="100" align="right">
            <template #default="scope">
              {{ (scope.row.free_amount / 100).toFixed(2) }}
            </template>
          </el-table-column>
          
          <el-table-column label="剩余折扣金额" width="100" align="right">
            <template #default="scope">
              {{ (scope.row.remain_amount / 100).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="now_edit" label="本次核销" width="130" align="right">
            <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'now_edit'
              ">
                {{ (scope.row.nowcc_amount / 100).toFixed(2)}}
                    
                </span><span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 90px"
                @change="((val)=>{tableeditchange(val,'freesaddre',scope.row)})"
              />
            </span>
            </template>
          </el-table-column>
  
          <el-table-column prop="discountremark" label="折扣摘要" width="300" />
        </el-table>
        <el-pagination
          background
          @size-change="addfreeshandleSizeChange"
          @current-change="addfreeshandleCurrentChange"
          :current-page="addfreespagenow"
          :page-sizes="[50, 100, 300, 500, 1000]"
          :page-size="addfreespagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="addfreestotalrow"
        >
        </el-pagination>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addfreevisiable = false">取 消</el-button>
          <el-button type="primary" @click="saveaddfreedata">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="添加票折明细"
      v-model="addadfreevisiable"
      width="600px"
      :show-close="false"
      @close="clearaddadfreedata"
      center
      ><span>
        <el-form :model="addadfreesdata" label-width="130px">
          <el-form-item label="选择折扣项目:" prop="discount">
            <ContactsdiscountSelect
              :code="addadfreesdata.discount"
              width="450"
              :clearable="true"
              @datachange="addadfreesdatadiscountcg"
              :isview="true"
              :contaccodes="[this.contacts_code]"
            />
          </el-form-item>
          <el-form-item label="折扣项目名称：">
            <el-input
              :disabled="true"
              v-model="addadfreesdata.v_name"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣项生效时间：">
            <el-input
              :disabled="true"
              v-model="addadfreesdata.time"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣项摘要：">
            <el-input
              :disabled="true"
              v-model="addadfreesdata.remark"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣金额：">
            <el-input
              type="number"
              v-model="addadfreesdata.number"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣备注：">
            <el-input
              v-model="addadfreesdata.atremark"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
        </el-form> </span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="addadfreevisiable = false">取 消</el-button>
          <el-button type="primary" @click="saveaddadfreedata">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="添加票折明细"
      v-model="addadfreevisiable2"
      width="600px"
      :show-close="false"
      @close="clearaddadfreedata"
      center
      ><span>
        <el-form :model="addadfreesdata" label-width="130px">
          <el-form-item label="选择折扣项目:" prop="discount">
            <ContactsdiscountSelect
              :code="addadfreesdata.discount"
              width="450"
              :clearable="true"
              @datachange="addadfreesdatadiscountcg"
              :isview="true"
              :contaccodes="[this.contacts_code]"
            />
          </el-form-item>
          <el-form-item label="折扣项目名称：">
            <el-input
              :disabled="true"
              v-model="addadfreesdata.v_name"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣项生效时间：">
            <el-input
              :disabled="true"
              v-model="addadfreesdata.time"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣项摘要：">
            <el-input
              :disabled="true"
              v-model="addadfreesdata.remark"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣金额：">
            <el-input
              type="number"
              v-model="addadfreesdata.number"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣备注：">
            <el-input
              v-model="addadfreesdata.atremark"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
        </el-form> </span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="addadfreevisiable2 = false">取 消</el-button>
          <el-button type="primary" @click="saveaddadfreedata2">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="生成发票"
      v-model="addinvoappvisiable"
      width="1550px"
      :show-close="false"
      @close="clearmakeinvdata"
      center
      ><span>
        <el-steps style="max-width: 1550px" align-center :active="stepsa" finish-status="success">
          <el-step title="选择发票抬头" />
          <el-step title="项目名称及税码税率设置" />
          <el-step title="拆分条件及备注设置" />
          <el-step title="发票数据预览" />
        </el-steps>
        <div v-if="this.stepsa==1" style="height: 450px;margin-top:30px;margin-left:400px;" >
          <el-form
          
          label-width="150px"
        >
         
          <el-form-item label="开票抬头" >
            <span v-if="this.tax_plate_select==''"><TaxplateSelect
                code=''
                width="450"
                :clearable="true"
                @datachange="taxplatecg"
            /></span>  
            <span v-else>{{this.tax_plate_select}} &nbsp;&nbsp;&nbsp;&nbsp;<el-button size="small" @click="tax_plate_select = ''">修改</el-button></span>
            
          </el-form-item>


  
          
         
          <el-form-item label="客户抬头">
            <TaxbuyerSelect
            
                :code ="tax_select"
                width="80"
                :clearable="true"
                @datachange="taxbuyercodecg"
            />
                
          </el-form-item>
          <el-form-item label="客户名称"><el-input
            v-model="tax_name"
            style="width: 280px"
            autocomplete="off"
            @blur="cleaninvodata(1)"
            /></el-form-item>
          <el-form-item label="客户税号"><el-input
            v-model="tax_num"
            style="width: 200px"
            autocomplete="off"
            @blur="cleaninvodata(2)"
            /></el-form-item>
          <el-form-item label="电子邮件"><el-input
            v-model="tax_email"
            style="width: 200px"
            autocomplete="off"
            
            /></el-form-item>
          
  
         
        </el-form>
        </div>
        <div v-if="this.stepsa==2" style="height: 450px;margin-top:30px;" >
          物料名：
            <el-input
            v-model="makeinvtpdata.goodsttedit.goodsname"
            style="width: 200px"
            autocomplete="off"
                clearable
            
            />
            规格：
            <el-input
            v-model="makeinvtpdata.goodsttedit.goodsspec"
            style="width: 100px"
            autocomplete="off"
                clearable
            
            />
            单位：
            <el-input
            v-model="makeinvtpdata.goodsttedit.unit"
            style="width: 80px"
                clearable
            autocomplete="off"
            
            />
            
            单价：
            <el-input
            v-model="makeinvtpdata.goodsttedit.price"
            style="width: 80px"
                clearable
            autocomplete="off"
            @change="ceckprice"
            />
            发票类型:
            <el-select
                v-model="makeinvtpdata.goodsttedit.invtype"
                style="width: 110px"
                clearable
                >
                 <el-option
                  v-for="item in invotypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
              税收编码：
              <taxcode-select
                :code="makeinvtpdata.goodsttedit.taxcodeid"
                width="200"
                clearable
                @datachange="invgoodsttedtaxcodecg"
              />
              税率：
              <el-select
                v-model="makeinvtpdata.goodsttedit.taxlv"
                style="width: 100px"
                clearable
                >
                 <el-option
                  v-for="item in involvs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>&nbsp;&nbsp;
              <el-button @click="ivgoodsedit">批量修改</el-button>
              <el-button @click="ivgoodsreset">重置</el-button>
      
          
          <el-table
              :data="makeinvtpdata.goodslist"
              style="width: 100%; margin-top: 5px"
              height="430px"
              :header-cell-style="tableheadClass"
              stripe
              border
              default-expand-all
              :tree-props="{}"
              @selection-change="makinvgoodstableselechange"
              @cell-click="billsadddetabClick"              
              row-key="row_code"
              ref="makeinvgoodslist"
            >
            
            
              <el-table-column type="selection" width="40" align="center" />

              <el-table-column prop="goods_name" label="账单物料名" width="200" />
              
              <el-table-column prop="goods_spec" label="账单规格" width="80" />
              
              <el-table-column prop="inv_goods_name" label="开票物料名" width="300" >
              <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'inv_goods_name'
              ">
                {{ scope.row.inv_goods_name }}
                    
                </span><span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 280px"
                @change="((val)=>{tableeditchange(val,'inv_goods_name',scope.row)})"
              />
              </span>
              </template>
              </el-table-column>
              
              <el-table-column prop="inv_goods_spec" label="开票规格" width="130" >
                <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'inv_goods_spec'
              ">
                {{ scope.row.inv_goods_spec }}
                    
                </span><span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 120px"
                @change="((val)=>{tableeditchange(val,'inv_goods_spec',scope.row)})"
              />
              </span>
              </template>
              </el-table-column>

              <el-table-column prop="inv_unit" label="开票单位" width="80" >
                <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'inv_unit'
              ">
                {{ scope.row.inv_unit }}
                    
                </span><span v-else>
                <el-select
                v-model="editetotalfree"
                style="width: 65px"
                @change="((val)=>{tablesleditchange(val,'inv_unit',scope.row)})">
                 <el-option
                  v-for="item in scope.row.unit_list"
                  :key="item"
                  :label="item"
                  :value="item"/>
              </el-select>
              </span>
              </template>
              </el-table-column>
              
              <el-table-column prop="invtype" label="发票类型" width="140" >
                <template #default="scope">
                <span  v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'invtype'
              ">
                <span v-if="scope.row.invtype == '026'">全电普票</span>
                <span v-else-if="scope.row.invtype == '028'">全电增票</span>
                <span v-else-if="scope.row.invtype == '999'">暂不开具</span>
                <span v-else-if="scope.row.invtype == ''"></span>
                <span v-else>数据错误</span>
                    
                </span><span v-else>
                <el-select
                v-model="editetotalfree"
                style="width: 125px"
                @change="((val)=>{tablesleditchange(val,'invtype',scope.row)})">
                 <el-option
                  v-for="item in invotypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
              </span>
              </template>
              </el-table-column>

              <el-table-column prop="tax_code" label="税收编码" width="240" >
                
              <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'tax_code'
              ">
                {{ scope.row.tax_v_code }}
                    
                </span><span v-else>
              <taxcode-select
                :code="editetotalfree"
                width="200"
                @datachange="invgoodsedtaxcodecg"
              />
              </span>
              </template>
              </el-table-column>

              <el-table-column prop="tax_rait" label="税率" width="120" >
                <template #default="scope">
                <span v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'tax_rait'
              ">
                <span v-if="scope.row.tax_rait == '-1'">免税</span>
                <span v-else-if="scope.row.tax_rait == '90000'">9%</span>
                <span v-else-if="scope.row.tax_rait == '130000'">13%</span>
                <span v-else-if="scope.row.tax_rait == ''"></span>
                <span v-else>数据错误</span>
                    
                </span><span v-else>
                <el-select
                v-model="editetotalfree"
                style="width: 80px"
                @change="((val)=>{tablesleditchange(val,'tax_rait',scope.row)})">
                 <el-option
                  v-for="item in involvs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
              </span>
              </template>
              </el-table-column>

              
            </el-table>
        </div>
        <div v-if="this.stepsa==3" style="height: 450px;margin-top:30px;margin-left:400px;margin-right:350px;" >
          <h3>拆分设置</h3>
          
          <span>不同发票类型、不同税率默认拆分为不同发票，其他项目根据下列选择拆分</span>
          <br/>
          拆分发票：<el-checkbox v-model="makeinvtpdata.cfcek.odno" label="云商账单号" size="large" />
          <el-checkbox v-model="makeinvtpdata.cfcek.outno" label="客户订单号" size="large" />
          <el-checkbox v-model="makeinvtpdata.cfcek.remark" label="备注信息" size="large" />
          
          <!--拆分明细：<el-checkbox v-model="makeinvtpdata.cfcek.price" label="物料单价" size="large" /><el-checkbox v-model="makeinvtpdata.cfcek.unit" label="开票单位" size="large" />-->
          <h3>折扣方式设置</h3>
          <span>选择“平均计算”，将按照折扣比率在每条明细中计算需折扣金额，并在最后一条明细中调整计算造成的尾差；<br/>选择“最后一条”,将自动从上方明细扣除2倍应扣金额计入最后一条明细，同时将整张发票应计折扣金额算入最后一条明细，达成仅最后一条明细存在折扣，且最后一条明细折扣金额不超过明细金额50%的效果。</span>
          <br/>
          折扣方式：<el-select
                v-model="makeinvtpdata.cfcek.zhekou"
                style="width: 110px"
                >
                <el-option
                  v-for="item in zhekoutypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
          <h3>备注生成</h3>
          <span>填写备注内容，下述占位符将被替换为去重后的实际数据，占位符后第一个字符将作为多个占位符的分隔字符(注意：回车也算一个字符)；占位符及对应信息如下：
            &lt;billno&gt;:云商账单号; &lt;outno&gt;:客户订单号;&lt;remark&gt;:单据备注;<br/>例：填写内容“开票订单号为：&lt;outno&gt;，。”实际发票备注内容“开票订单号为：客户订单号1，客户订单号2。”</span>
            <br/>备注： <br/><el-input
            v-model="makeinvtpdata.cfcek.remarkrule"
            style="width: 350px"
            clearable
            autocomplete="off"
            :autosize="{ minRows: 4, maxRows: 4 }"
            type="textarea"
            />

        </div>
        <div v-if="this.stepsa==4" style="height: 450px;margin-top:30px;" >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;应开：{{(this.totalbillamount/100).toFixed(2)}}-{{(this.totalfreeamount/100).toFixed(2)}}={{((this.totalbillamount-this.totalfreeamount)/100).toFixed(2)}}；当前发票金额：{{(this.tpinvamount/100).toFixed(2)}}；
          <el-table
              :data="makeinvtpdata.tpinvdata"
              style="width: 100%; margin-top: 5px"
              height="430px"
              :header-cell-style="tableheadClass"
              stripe
              border
              default-expand-all
              :tree-props="{}"       
              row-key="row_code"
            >
            
            

              <el-table-column prop="tax_plate_name" label="开票抬头" width="200" />
              <el-table-column  label="购货客户抬头" width="300" >
              <template #default="scope">
                {{'客户名称：' +scope.row.buyer_name+'\n客户税号：'+scope.row.buyer_taxnum}}
                
              </template>
              </el-table-column>
              <el-table-column  label="发票类型" width="120" >
              <template #default="scope">
                <span v-if="scope.row.invtype == '026'">全电普票</span>
                <span v-else-if="scope.row.invtype == '028'">全电增票</span>
                <span v-else>数据错误</span>
              </template>
              </el-table-column>
              <el-table-column  label="税率" width="120" >
              <template #default="scope">
                <span v-if="scope.row.tax_rait == '-1'">免税</span>
                <span v-else-if="scope.row.tax_rait == '90000'">9%</span>
                <span v-else-if="scope.row.tax_rait == '130000'">13%</span>
                <span v-else>数据错误</span>
              </template>
              </el-table-column>
              <el-table-column prop="tax_email" label="电子邮箱" width="200" />
              <el-table-column prop="inv_remark" label="备注" width="200" />
              <el-table-column width="80" align="center">
                <template #header> </template>
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="opentpinv(scope.row)"
                    size="small"
                  >
                    详情</el-button
                  >
                </template>
              </el-table-column>
              
            </el-table>
        </div>
  
        </span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="addinvoappvisiable = false">取 消</el-button>
          <el-button v-if="this.stepsa>1" @click="invfront">上一步</el-button>
          <el-button v-if="this.stepsa<4" @click="invnext">下一步</el-button>
          <el-button v-if="this.stepsa==4" :disabled="tpinvamount>totalbillamount-totalfreeamount" type="primary" @click="saveinvoappdata">确 定</el-button>
        </span>
      </template></el-dialog
    >
    <el-dialog
      :title="tpinvviewdata.invtypename"
      v-model="tpinvview"
      width="1200px"
      :show-close="false"
      center
      ><span>
        
        <span class="invoviewtool"><b>购货客户名称：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.buyername }}</span>
  
        <span class="invoviewtool"><b>开票抬头：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.platename }}</span>
  
        <span class="invoviewtool"><b>购货客户税号：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.buyertaxnum }}</span>
        
        <span class="invoviewtool"><b>价税合计：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.totalam }}</span>

        <span class="invoviewtool"><b>电子邮件：</b></span>
        <span class="invoviewdata">
          <el-input
            v-model="tpinvviewdata.invemail"
            style="width: 250px"
            clearable
            autocomplete="off"
            /></span>
            
        <span class="invoviewtool"><b>备注：</b></span>
        <span class="invoviewdata"><el-input
            v-model="tpinvviewdata.invremark"
            style="width: 350px"
            clearable
            autocomplete="off"
            :autosize="{ minRows: 4, maxRows: 4 }"
            type="textarea"
            /></span>
        
        <el-table
          :data="tpinvviewdata.dtl"
          style="width: 100%; margin-top: 12px"
          height="450px"
          :header-cell-style="tableheadClass"
          stripe
          row-key="row_code"
          border
          default-expand-all
          :tree-props="{}"
          show-summary
          :summary-method="getSummaries"
        >
        <el-table-column prop="goodsname" label="项目名称" width="320" />
        <el-table-column prop="goodspec" label="规格型号" width="120" />
        <el-table-column prop="unit" label="单位" width="80" />
        <el-table-column prop="num" label="数量" width="80" />
        <el-table-column prop="price" label="单价" width="120" />
        <el-table-column prop="amount" label="金额" width="120" />
        <el-table-column prop="taxlv" label="税率/征收率" width="160" />
        <el-table-column prop="txamount" label="税额" width="120" />
          
  
        </el-table> 

        </span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="tpinvview = false">取 消</el-button>
          <el-button type="primary" @click="savetpinvdata">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="引入发票"
      v-model="pullinvovisable"
      width="1200px"
      :show-close="false"
      @close="clearpullinvo"
      center
    >
      <span>
        <el-form :model="pullinvosearch" :inline="true" label-width="140px">
          <el-form-item label="开票抬头：">
            <TaxplateSelect
              :code="pullinvosearch.salerid"
              width="350px"
              :clearable="true"
              @datachange="edtaxpltcg2"
              @clear="edtaxpltcg2(null)"
            />
          </el-form-item>
          <el-form-item label="购货客户信息：">
            <el-input
              v-model="pullinvosearch.buyercode"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="发票号码：">
            <el-input
              v-model="pullinvosearch.taxcode"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="备注信息：">
            <el-input
              v-model="pullinvosearch.remark"
              style="width: 350px"
              autocomplete="off"
            />
          </el-form-item>
          <el-button @click="dopullinvosearch" size="small">查 询</el-button>
        </el-form>
        <el-table
          :data="pullinvodata.invos"
          style="width: 100%; margin-top: 5px"
          height="450px"
          :header-cell-style="tableheadClass"
          stripe
          row-key="row_code"
          border
          ref='pullinvotb'
          default-expand-all
          :tree-props="{}"
          @selection-change="addpullinvotableselechange"
        >
          <el-table-column type="selection" width="40" align="center" />
  
          <el-table-column width="120" align="left">
            <!--  -->
            <template #header>开票抬头 </template>
            <template #default="scope">
              {{ scope.row.platename }}
            </template>
          </el-table-column>
          <el-table-column width="420" align="left">
            <template #default="scope">
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>购货客户名称：</b>{{ scope.row.buyername }}</span
              ><br />
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>购货客户税号：</b>{{ scope.row.buyertaxnum }}</span
              ><br />
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>地 址、电 话：</b>{{ scope.row.buyeraddress }}</span
              ><br />
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>开户行、账号：</b>{{ scope.row.buyeraccount }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="inv_type" width="200" align="left">
            <template #default="scope">
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>发票日期：</b> {{ dateformart2(scope.row.inv_data) }}</span
              ><br />
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>发票代码：</b> {{ scope.row.tax_code }}</span
              ><br />
              <span style="padding: 0px; margin: 0px; text-indent: -12em"
                ><b>发票号码：</b> {{ scope.row.tax_num }}</span
              ><br />
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-if="scope.row.inv_type == '000'"
                ><b>发票类型：</b>待定</span
              >
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-else-if="scope.row.inv_type == '007'"
                ><b>发票类型：</b>纸普</span
              >
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-else-if="scope.row.inv_type == '026'"
                ><b>发票类型：</b>电普</span
              >
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-else-if="scope.row.inv_type == '004'"
                ><b>发票类型：</b>纸专</span
              >
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-else-if="scope.row.inv_type == '028'"
                ><b>发票类型：</b>电专</span
              >
              <span style="padding: 0px; margin: 0px; text-indent: -12em" v-else
                ><b>发票类型：</b>数据错误</span
              >
              <br />
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-if="scope.row.taxlv === ''"
                ><b>发票税率：</b></span
              >
              <span
                style="padding: 0px; margin: 0px; text-indent: -12em"
                v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
                ><b>发票税率：</b>免税</span
              >
              <span style="padding: 0px; margin: 0px; text-indent: -12em" v-else
                ><b>发票税率：</b
                >{{ (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="发票金额" width="100" align="right">
            <template #default="scope">
              {{ (scope.row.total_amount / 100).toFixed(2) }}
            </template>
          </el-table-column>
  
          <el-table-column prop="remark" label="备注" width="180" />
        </el-table> </span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="pullinvovisable = false">取 消</el-button>
          <el-button type="primary" @click="savepullinvodata">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :title="tpinvviewdata.invtypename"
      v-model="viewinvovisable"
      width="1200px"
      :show-close="false"
      @close="clearpullinvo"
      center
    >
      <span>
        <span class="invoviewtool"><b>购货客户名称：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.buyername }}</span>
  
        <span class="invoviewtool"><b>开票抬头：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.platename }}</span>
  
        <span class="invoviewtool"><b>购货客户税号：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.buyertaxnum }}</span>
  
        <span class="invoviewtool"><b>发票编码：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.tax_code + tpinvviewdata.tax_num}}</span>
  
        <span class="invoviewtool"><b>电子邮箱：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.email }}</span>
  
        <span class="invoviewtool"><b>开票日期：</b></span>
        <span class="invoviewdata">{{
          dateformart2(tpinvviewdata.inv_data)
        }}</span>

        <span class="invoviewtool"><b>状态：</b></span>
        <span class="invoviewdata" v-if="tpinvviewdata.status == '-1'">待确认</span>
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '0'"
          >待开票</span
        >
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '10'"
          >出现错误</span
        >
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '20'"
          >开票中</span
        >
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '30'"
          >红字待开</span
        >
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '40'"
          >开票已完成</span
        >
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '95'"
          >已红冲</span
        >
        <span class="invoviewdata" v-else-if="tpinvviewdata.status == '99'"
          >未开删除</span
        >
        <span class="invoviewdata" v-else>数据错误</span>



        <span class="invoviewtool"><b>发票金额：</b></span>
        <span class="invoviewdata">{{
          finallnum(tpinvviewdata.total_amount )
        }}</span>
        
        <span class="invoviewtool"><b>文件下载：</b></span>
        <span class="invoviewdata">
          <span v-if="tpinvviewdata.file_pdf!=''">
          <el-button
           link
           @click="downinvfile(tpinvviewdata.file_pdf)"
           size="small"
          >
          下载PDF
          </el-button></span>
          <span v-if="tpinvviewdata.file_ofd!=''">
          <el-button
           link
           @click="downinvfile(tpinvviewdata.file_pdf)"
           size="small"
          >
          下载ODF
          </el-button></span>
          <span v-if="tpinvviewdata.file_xml!=''">
          <el-button
           link
           @click="downinvfile(tpinvviewdata.file_pdf)"
           size="small"
          >
          下载XML
          </el-button></span>
        
        </span>

        
  
        <span class="invoviewtool"><b>备注：</b></span>
        <span class="invoviewdata">{{ tpinvviewdata.remark }}</span>

        <el-table
          :data="tpinvviewdata.dtl"
          style="width: 100%; margin-top: 12px"
          height="450px"
          :header-cell-style="tableheadClass"
          stripe
          row-key="row_code"
          border
          default-expand-all
          :tree-props="{}"
          show-summary
          :summary-method="getSummaries"
        >
        <el-table-column prop="goodsname" label="项目名称" width="320" />
        <el-table-column prop="goodspec" label="规格型号" width="120" />
        <el-table-column prop="unit" label="单位" width="80" />
        <el-table-column prop="num" label="数量" width="80" />
        <el-table-column prop="price" label="单价" width="120" />
        <el-table-column prop="amount" label="金额" width="120" />
        <el-table-column prop="taxlv" label="税率/征收率" width="160" />
        <el-table-column prop="txamount" label="税额" width="120" />
          
  
        </el-table> 

        </span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="viewinvovisable = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="checkmark" width="350px" :show-close="false" center
      ><span style="line-height: 200%; text-indent: 10px"
        >{{ this.checktaxst }}<br /><br />{{
          this.ceckcode
        }}&nbsp;&nbsp;&nbsp;<el-input
          v-model="ceckcodeinput"
          style="width: 80px"
          autocomplete="off" /></span
      ><template #footer>
        <span class="dialog-footer">
          <el-button @click="checkmark = false">关 闭</el-button>
          <el-button @click="ceckmarkcech">确 认</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  <script >
  import { ElMessage } from "element-plus";
  //import GoodstypeCascaderVue from "../../../../../../controls/GoodstypeCascader.vue"
  import ContacrsSelect from "../../../../../../controls/ContacrsSelect.vue"
  import { ElMessageBox } from "element-plus";
  import axios from "axios";
  import BuyerInformationSeltct from "../../../../../../controls/BuyerInformationSeltct.vue";
  //import ContactsCascader from "../../../../../../controls/ContactsCascader.vue"
  import ContactsdiscountSelect from "../../../../../../controls/ContactsdiscountSelect.vue";
  import TaxplateSelect from "../../../../../../controls/TaxplateSelect.vue";
  //import InvobuyerSelect from "../../../../../../controls/InvobuyerSelect.vue";
  import TaxcodeSelect from "../../../../../../controls/TaxcodeSelect.vue";
  import TaxbuyerSelect from "../../../../../../controls/TaxbuyerSelect.vue"
  
  import { v4 as uuidv4 } from "uuid";

  
  export default {
    emits: ["changedata", "dialogclose"],
    components: {
      BuyerInformationSeltct,
      ContactsdiscountSelect,
      TaxplateSelect,
      TaxcodeSelect,
      ContacrsSelect,
      TaxbuyerSelect,
    },
    props: {
      dialoginfo: { required: true },
      dialogdata: { required: true },
      isvisiable: {},
    },
    data() {
      return {
        longtimeop:'',
        operar: {
          conditionstr: "",
          add: false,
          edit: false,
          del: false,
          check: false,
          uncheck: false,
        },
        userid:'',
        addadfreevisiable2:false,
        contacts_code_select:'',
        viewlist: {
          add: false,
          edit: false,
          del: false,
          check: false,
          uncheck: false,
        },
        dialogremark:'',
        tpinvviewdata:{invtypename:''},
        tpinvview:false,
        tpinvamount:0,
        tax_plate_pcode:'',
        tax_free_bj:0,
        makeinvtpdata:{goodsttedit:{goodsname:'',goodsspec:'',taxcodeid:'',unit:'',price:0,invtype:'',taxcode:'',taxcodev:'',taxcodelv:'',taxlv:''}},
        stepsa:1,
        tax_select:'',
        tax_plate_select:'',
        tax_name:'',
        tax_num:'',
        tax_email:'',
        editstring: 0,
        fileList: [],
        minamount: 0,
        maxamount: 0,
        checkmark: false,
        checkrout: 0,
        checktaxst: "",
        ceckcode: "",
        ceckcodeinput: "",
        ceckcloseat: false,
        edtaxcode: "",
        viewinvovisable: false,
        viewinvodata: [],
        pullinvodata: [],
        pullinvoselectdata: [],
        pullinvosearch: {
          salerid: "",
          salercode: "",
          buyercode: "",
          taxcode: "",
          remark: "",
        },
        editgoodsinfo: { name: "", spc: "" },
        editgoodsvisiable: false,
        invoapptotalfree: 0,
        appserchdata: { num: "", buyername: "", goods: "" },
        appserchdatax: { num: "", buyername: "", goods: "" },
        cellediterow: "",
        celleditecomm: "",
        mainremark:'',
        addadfreesdata: {
          discount: "",
          v_name: "",
          time: "",
          remark: "",
          number: 0,
          atremark: "",
        },
        invotype: "",
        invotype2: "",
        invotypes: [
          { label: "全电普票", value: "026" },
          { label: "全电专票", value: "028" },
          //{ label: "暂不开具", value: "999" },
        ],
        zhekoutypes: [
          { label: "平均计算", value: 1 },
          { label: "最后一条", value: 2 },
        ],
        involv: "",
        editetotalfree: 0,
        involvs: [
          { label: "免税", value: -1 },
          { label: "9.00%", value: 90000 },
          { label: "13.00%", value: 130000 },
        ],
        invoappseditviewdtl: [],
        editinvoinfo: {
          edtaxplt: "",
          pttname: "",
          pttcode: "",
          ptttax: "",
          burercode: "",
          burername: "",
          burertax: "",
          bureradd: "",
          bureracc: "",
          remark: "",
          number: 0,
          email: "",
        },
        editinvoinfovisiable: false,
        invoappsedit: [],
        addadfreevisiable: false,
        addinvoappvisiable: false,
        activeName: "first",
        addbillvisiable: false,
        addfreevisiable: false,
        dtlvisiable: false,
        tempareas: [
          { value: 1, label: "常温" },
          { value: 2, label: "冰鲜" },
          { value: 3, label: "冷冻" },
        ],
        dtldata: { type: "1", v_name: "", mark: "", remark: "" },
        addbillsearchdata: {
            doctimes: ["", ""],
            posttimes: ["", ""],
            docnum: "",
            contact: "",
            department: [],
            buyerinfor: [],
            contacts: [],
            warehouse: "",
            minamount: 0,
            maxamount: 0,
            filesselect: "",
        },
        addfreesearchdata: {
          doctimes: ["", ""],
          posttimes: ["", ""],
          docnum: "",
          contact: "",
          department: [],
          buyerinfor: [],
          contacts: [],
          warehouse: "",
        },
        pullinvovisable: false,
        addappsheadselect: [],
        addappsdtlsselect: [],
        addbilltype: 1,
        addfreetype: 1,
        billsdata: [],
        addbilltotalamount: 0,
        addbilltotalamountstr: "0.00",
        addfreetotalamount: 0,
        addfreetotalamountstr: "0.00",
        billssearchresults: [],
        freessearchresults: [],
        addbullsselectdata: [],
        addfreesselectdata: [],
        freesseleed: [],
        addbullsnowpageselectdata: [],
        addfreesnowpageselectdata: [],
        addbillstotalrow: 0,
        addfreestotalrow: 0,
        addbillspagenow: 1,
        tax_plate:'',
        addfreespagenow: 1,
        addbillspagesize: 50,
        addfreespagesize: 50,
        contacts_code:'',
        billsseleed: [],
        thisdata: {canchange:true,status:-1,remark:'',beforinv:[],hdatas:[],bills:[], dtl: [], frees: [] },
        visiable: false,
        yesno: [
          { label: "是", value: "1" },
          { label: "否", value: "99" },
        ],
        dtltypes: [
          { label: "通用数据", value: "1" },
          { label: "专用数据", value: "2" },
        ],

        thisrules: {
          v_name: [
            { required: true, message: "本项目不可为空！！", trigger: "blur" },
          ],
        },
        totalbillamount: 0,
        totalbeforamount: 0,
        totalfreeamount: 0,
        totalinvamount: 0,
      };
    },
    watch: {
      isvisiable: function () {
        if (this.isvisiable) {
            this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
            this.contacts_code='';
            this.tax_plate='';
            this.mainremark='';
            this.tax_select='';
            this.tax_name='';
            this.tax_num='';
            this.tax_email='';
            this.contacts_code_select='';
            //this.visiable = true;
            console.log("dialog get", this.dialogdata);
            console.log("dialog info ", this.dialoginfo);

  
     
  
            this.activeName = "first";
  
            if(this.dialoginfo.modle!="add"){
                this.getdata();

            }else{
                this.visiable = true;
            }
          
          
        }
      },

  
      // thisdata: function () {
      //     console.log(this.thisdata,this.thisdata)
      //     // this.thisdata.postdatetime=this.datetimeformart(this.thisdata.post_date)
      //     // this.thisdata.docdatetime=this.datetimeformart(this.thisdata.doc_date)
      // }
    },
    mounted() {
      this.userid=localStorage.getItem('UserId');
      let operardata = JSON.parse(localStorage.getItem("authlist"));
      let controldata = operardata['InvoApp'].operation;
      //console.log(operardata);
       this.operar.add = (controldata & 128) == 128;
       this.operar.del = (controldata & 64) == 64;
       this.operar.edit = (controldata & 32) == 32;
       this.operar.check = (controldata & 16) == 16;
       this.operar.uncheck = (controldata & 8) == 8;
      // this.operar.conditionstr = operardata[this.selfmodul["code"]].conditionstr;
      //console.log('operar',operardata);
      //operardata = JSON.parse(localStorage.getItem("viewlist"));
      //controldata = operardata[this.selfmodul["code"]].operation;
      // this.viewlist.add = (controldata & 128) == 128;
      // this.viewlist.del = (controldata & 64) == 64;
      // this.viewlist.edit = (controldata & 32) == 32;
      // this.viewlist.check = (controldata & 16) == 16;
      // this.viewlist.uncheck = (controldata & 8) == 8;
  
      //console.log(this.operar);
    },
    methods: {
        //清理程序
        downmobi(){
          axios
            .post("/" + this.dialoginfo.modlecode + "/downmobi", {
                   data:[]
                },{timeout:500})
            .then((reqs) => {
              window.open("/api_v3/interface/getfile?file=/filedownload/" + reqs+".xlsx", "_self");
             })
            .catch(() => {
                
            });
        },

      getfileopst(row){
        axios
            .post("/" + this.dialoginfo.modlecode + "/opreq", {
                    data: row,
                },{timeout:500})
            .then((req) => {
              
              if(req.resmsg=='OK'){
                clearInterval(this._getuploadstatus);
                var reqx=JSON.parse(req.data.log);

                if(reqx.rescode=="90099"){
                  
                  this.$bus.$emit("hideloading");
                  this.$bus.$emit("hideloading");
                  this.$bus.$emit("hideloading");
                  this.$bus.$emit("hideloading");
                  ElMessage.error({
                    message: "上传文件未通过检查，将自动下载错误标注文件！！！！",
                    duration: 2000,
                    dangerouslyUseHTMLString: true,
                    center: false,
                  });
                  //window.open("/api_v3/interface/getfile?file=" + reqx.data, "_self");
                  this._getuploadstatus = setTimeout(() => {
                    window.open("/api_v3/interface/getfile?file=" + reqx.data, "_self");
                  }, 5000);
                }
                if(reqx.rescode=="0"){
                  this.addbullsselectdata=reqx.data.bills;
                  this.thisdata.invos=reqx.data.invs;
                  this.saveaddbilldata();
                  this.addbullsselectdata=[];
                }
                console.log('opfilerq',reqx);
                this.$bus.$emit("hideloading");
                this.$bus.$emit("hideloading");
              }
              if(req.resmsg=='timeout'){
                clearInterval(this._getuploadstatus);
                this.$bus.$emit("hideloading");
                this.$bus.$emit("hideloading");
                ElMessage.error({
                  message: "文件处理超时，请稍后重试！！！！",
                  duration: 2000,
                  dangerouslyUseHTMLString: true,
                  center: false,
                });
                this.$bus.$emit("hideloading");
              }
              if(req.resmsg=='nodata'){
                clearInterval(this._getuploadstatus);
                this.$bus.$emit("hideloading");
                this.$bus.$emit("hideloading");
                ElMessage.error({
                  message: "未找到处理操作信息！！！！",
                  duration: 2000,
                  dangerouslyUseHTMLString: true,
                  center: false,
                });
                this.$bus.$emit("hideloading");
              }
              
               
             })
            .catch(() => {
            });
      },
      
      uploadbefore(){
        
        if(this.contacts_code=='' || this.contacts_code==null){
            ElMessage.error({
                message: "请先选择合同归属，之后方可上传文件！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            this.fileList = [];
            return false;
        }
        if(this.thisdata.bills==undefined){
          this.thisdata.bills=[];
        }
        if(this.thisdata.invos==undefined){
          this.thisdata.invos=[];
        }
        if(this.thisdata.bills.length>0 || this.thisdata.invos.length>0){
          ElMessage.error({
                message: "上传文件对账开票时，账单明细及开票清单不应存在数据，请清空后重试！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            this.fileList = [];
            return false;
        }
       
        this.$bus.$emit("showloading");
        
        

      },
        
      uploadsuccess(req, file, files) {
        
      
        console.log("upload success", { req: req, file: file, files: files });
        this.fileList = [];
        //userid:this.userid ,contacts_code:this.contacts_code,
        req.data.userid=this.userid;
        req.data.contacts_code=this.contacts_code;
        axios
            .post("/" + this.dialoginfo.modlecode + "/opfiles", {
                    data: req.data,
                },{timeout:500})
            .then(() => {
               
             })
            .catch(() => {
                
            });


         this._getuploadstatus = setInterval(() => {
          this.getfileopst(req.data);
        }, 10000);
      },

      uploaderror(req, file, files) {
        console.log("upload error", { req: req, file: file, files: files });
        this.fileList = [];
        this.$bus.$emit("hideloading");
        ElMessage.error({
          message: "文件上传失败，请稍后重试！！！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
      },
        

      getdata() {
            this.$bus.$emit("showloading");
            this.thisdata.postdatetime = this.datetimeformart(
                this.thisdata.post_date
            );
            this.thisdata.docdatetime = this.datetimeformart(this.thisdata.doc_date);
            axios
                .post("/" + this.dialoginfo.modlecode + "/getdetails", {
                    data: this.thisdata,
                })
            .then((response) => {
                this.thisdata = response;
                this.contacts_code_select=response.contactname;
                this.contacts_code=response.contacts_code;
                if(this.thisdata.canchange){
                  this.dialogremark='';
                }else{
                  this.dialogremark='-[权限不足]';
                }
                this.visiable = true;
                this.mathtotal();
                this.$bus.$emit("hideloading");
             })
            .catch(() => {
                this.$emit("dialogclose", {});
                this.$bus.$emit("hideloading");
            });
      },
      
      addbillsflashdata() {
        this.$bus.$emit("showloading");
        this.addbullsnowpageselectdata = [];
        this.$refs.addbillstables.clearSelection();
        if (this.addbilltype == 1) {
          this.addbillsearchdata.contact=this.contacts_code;
          axios
            .post("/" + this.dialoginfo.modlecode + "/getaddbillsdata", {
              data: {
                searchdata: this.addbillsearchdata,
                pageSize: this.addbillspagesize,
                pagenow: this.addbillspagenow,
                havbills: this.thisdata.bills,
              },
            })
            .then((required) => {

              
              this.billssearchresults.rows = required.rows;
              this.addbillstotalrow = required.total;
              for (var i = 0; i < this.billssearchresults.rows.length; i++) {
                for (var j = 0; j < this.addbullsselectdata.length; j++) {
                  if (
                    this.addbullsselectdata[j].row_code ==
                    this.billssearchresults.rows[i].row_code
                  ) {
                    this.$refs.addbillstables.toggleRowSelection(
                      this.billssearchresults.rows[i],
                      true
                    );
                    this.billssearchresults.rows[i].nowcc_num =
                      this.addbullsselectdata[j].nowcc_num;
                    this.billssearchresults.rows[i].nowcc_amount =
                      this.addbullsselectdata[j].nowcc_amount;
                    
                    this.addbullsnowpageselectdata.push(
                      this.billssearchresults.rows[i]
                    );
                    break;
                  }
                }
              }
            })
            .catch(() => {});
        } else {
          var tplist = Array();
          var past = 0;
          for (var i = 0; i < this.addbillspagesize; i++) {
            past = (this.addbillspagenow - 1) * this.addbillspagesize + i;
            if (past >= this.addbullsselectdata.length) {
              break;
            }
            tplist.push(this.addbullsselectdata[past]);
          }
          this.billssearchresults.rows = tplist;
          this.addbillstotalrow = this.addbullsselectdata.length;
          for (i = 0; i < this.billssearchresults.rows.length; i++) {
            for (var j = 0; j < this.addbullsselectdata.length; j++) {
              if (
                this.addbullsselectdata[j].row_code ==
                this.billssearchresults.rows[i].row_code
              ) {
                this.$refs.addbillstables.toggleRowSelection(
                  this.billssearchresults.rows[i],
                  true
                );
                this.addbullsnowpageselectdata.push(
                  this.billssearchresults.rows[i]
                );
                break;
              }
            }
          }
        }
        this.$bus.$emit("hideloading");
      },

      addfreesflashdata() {
        this.$bus.$emit("showloading");
        this.addfreesnowpageselectdata = [];
        this.$refs.addfreestables.clearSelection();
        if (this.addfreetype == 1) {
          this.addfreesearchdata.contact=this.contacts_code;
          axios
            .post("/" + this.dialoginfo.modlecode + "/getaddfreesdata", {
              data: {
                searchdata: this.addfreesearchdata,
                pageSize: this.addfreespagesize,
                pagenow: this.addfreespagenow,
                havfrees: this.thisdata.frees,
                havh:this.thisdata.hdatas,
              },
            })
            .then((required) => {
              this.freessearchresults = required.rows;
              this.addfreestotalrow = required.total;
              for (var i = 0; i < this.freessearchresults.length; i++) {
                for (var j = 0; j < this.addfreesselectdata.length; j++) {
                  if (
                    this.addfreesselectdata[j].row_code==
                    this.freessearchresults[i].row_code
                  ) {
                    this.$refs.addfreestables.toggleRowSelection(
                      this.freessearchresults[i],
                      true
                    );
                    this.addfreesnowpageselectdata.push(
                      this.freessearchresults[i]
                    );
                    break;
                  }
                }
              }
            })
            .catch(() => {});
        } else {
          var tplist = Array();
          var past = 0;
          for (var i = 0; i < this.addfreespagesize; i++) {
            past = (this.addfreespagenow - 1) * this.addfreespagesize + i;
            if (past >= this.addfreesselectdata.length) {
              break;
            }
            tplist.push(this.addfreesselectdata[past]);
          }
          this.freessearchresults = tplist;
          this.addfreestotalrow = this.addfreesselectdata.length;
          for (i = 0; i < this.freessearchresults.length; i++) {
            for (var j = 0; j < this.addfreesselectdata.length; j++) {
              if (
                this.addfreesselectdata[j].row_code==
                this.freessearchresults[i].row_code
              ) {
                this.$refs.addfreestables.toggleRowSelection(
                  this.freessearchresults[i],
                  true
                );
                this.addfreesnowpageselectdata.push(this.freessearchresults[i]);
                break;
              }
            }
          }
        }
        this.$bus.$emit("hideloading");
      },

      openmakeinv(){

        if(this.totalbillamount-this.totalbeforamount-this.totalfreeamount<0 || this.totalfreeamount<0 ||this.totalfreeamount/(this.totalbillamount-this.totalbeforamount)>0.5)
        {
          ElMessage.error({
            message: "应开发票金额为负、票面折扣为负或折扣比例大于50%;无法生成发票,请先检查数据！！！！！",
            duration: 2000,
          });
          return;
        }
        this.addinvoappvisiable=true
      },
      
      addfreesseacrchstart() {
        this.addfreetype = 1;
        this.addfreesflashdata();
      },
      
      addfreesviewsele() {
        this.addfreetype = 2;
        this.addfreesflashdata();
      },

      datetimeformart(Value) {
        //console.log("datetimeformart", Value);
        if (Value == 0) {
          return "";
        }
        const date = new Date(parseInt(Value));
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        const [Y, M, D, h, m, s] = [
          date.getFullYear(),
          dateNumFun(date.getMonth() + 1),
          dateNumFun(date.getDate()),
          dateNumFun(date.getHours()),
          dateNumFun(date.getMinutes()),
          dateNumFun(date.getSeconds()),
        ];
        var rtdate = `${Y}-${M}-${D} ${h}:${m}:${s}`;
        return rtdate;
      },

      clearaddbilldata() {
        this.addbillsearchdata = {
            doctimes: ["", ""],
            posttimes: ["", ""],
            docnum: "",
            contact: "",
            department: [],
            buyerinfor: [],
            contacts: [],
            warehouse: "",
            minamount: 0,
            maxamount: 0,
            filesselect: "",
        };
        this.minamount = 0;
        this.maxamount = 0;
        this.addbilltotalamount = 0;
        this.addbilltotalamountstr = "0";
        this.addbullsselectdata = [];
        this.billssearchresults = [];
        this.addbillstotalrow = 0;
        this.addbillspagenow = 1;
        this.addbillspagesize = 50;
      },

      clearaddfreedata() {
        this.addfreesearchdata = {
          doctimes: ["", ""],
          posttimes: ["", ""],
          docnum: "",
          contact: "",
          department: [],
          buyerinfor: [],
          contacts: [],
          warehouse: "",
        };
        this.addfreetotalamount = 0;
        this.addfreetotalamountstr = "0.00";
        this.addfreesselectdata = [];
        this.freessearchresults = [];
        this.addfreestotalrow = 0;
        this.addfreespagenow = 1;
        this.addfreespagesize = 50;
  
        console.log("clear addfreedata", this.addfreesselectdata);
      },

      clearmakeinvdata(){
        this.tax_free_bj=0;
        this.makeinvtpdata={goodsttedit:{goodsname:'',goodsspec:'',taxcodeid:'',unit:'',price:0,invtype:'',taxcode:'',taxcodev:'',taxcodelv:'',taxlv:''}};
        this.stepsa=1;
        this.tax_select='';
        this.tax_plate_select='';
        this.tax_name='';
        this.tax_num='';
        this.tax_email='';
      },

      addbillsseacrchstart() {
        this.addbilltype = 1;
        this.addbillsflashdata();
      },

      addbillshandleCurrentChange(val) {
        this.addbillspagenow = val;
        this.addbillsflashdata();
      },

      addbillshandleSizeChange(val) {
        let pgs = Math.ceil(this.addbillstotalrow / val);
        this.addbillspagesize = val;
        if (pgs >= this.addbillspagenow) {
          this.addbillsflashdata();
        }
      },

      billsadddetabClick(row, column) {
        this.cellediterow = row.row_code;
        this.celleditecomm = column.property;
        if(column.property=='inv_goods_name'){
          this.editetotalfree = row.inv_goods_name;
        }else if(column.property=='inv_goods_spec'){
          this.editetotalfree = row.inv_goods_spec;
        }
        else if(column.property=='inv_unit'){
          this.editetotalfree = row.inv_unit;          
        }
        else if(column.property=='tax_code'){
          this.editetotalfree = '';
        }
        else if(column.property=='tax_rait'){
          this.editetotalfree = row.tax_rait;
        }
        else if(column.property=='invtype'){
          this.editetotalfree = row.invtype;
        }
        else{
            this.editetotalfree = (row.nowcc_amount / 100).toFixed(2);
        }
        
      },

      ceckprice(val){
        var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;        
        var numRe = new RegExp(numReg);
        if (!numRe.test(val)){
          this.makeinvtpdata.goodsttedit.price=0;
        }
      },

      tableeditchange(val,act,row){
        console.log('acc',val,act,row);
        var numReg = /^[-]?[0-9]+[.]{0,1}[0-9]{0,2}$/;        
        var numRe = new RegExp(numReg);
        var totalfreetp = 0;
        var err=false;
        if(act=='billsaddre'){
            err=false;
            
            if (numRe.test(val)) {
                this.billssearchresults.rows.map((item) => {
                    if (item.row_code == row.row_code) {
                        if( Math.abs(val*100) > Math.abs(item.sale_remain_inv_amount)){

                            err=true;
                            ElMessage.error({
                                message: "核销金额不可超过待核销金额！！！",
                                duration: 2000,
                            });
                        }else{
                            
                            item.nowcc_amount = val*100;
                            item.nowcc_num = Math.round(item.main_remain_inv_num*val*100/item.sale_remain_inv_amount);
                            this.addbullsnowpageselectdata.map((val2) => {
                            if (val2.row_code == item.row_code) {
                                val2.nowcc_amount = val*100;
                                val2.nowcc_num = Math.round(item.main_remain_inv_num*val*100/item.sale_remain_inv_amount);
                                }
                            
                            });
                            this.addbullsselectdata.map((val2) => {
                            if (val2.row_code == item.row_code) {
                                val2.nowcc_amount = val*100;
                                val2.nowcc_num = Math.round(item.main_remain_inv_num*val*100/item.sale_remain_inv_amount);
                                }
                            
                            });
                        }
                    }
                    
                    
                });
                if(!err){
                    this.addbullsselectdata.map((item) => {
                        totalfreetp += item.nowcc_amount;
                    });
                    this.addbilltotalamount=totalfreetp/100;
                    this.addbilltotalamountstr = (totalfreetp / 100).toFixed(2);
                }
            }
            this.cellediterow = "";
            this.celleditecomm = "";
        }
        if(act=='freesaddre'){
          if (numRe.test(val)) {
                err=false;
                this.freessearchresults.map((item) => {
                    if (item.row_code == row.row_code) {
                      console.log('freesaddre2',val,item.remain_amount);
                        if( Math.abs(val*100) > Math.abs(item.remain_amount)){

                            err=true;
                            ElMessage.error({
                                message: "核销金额不可超过待核销金额！！！",
                                duration: 2000,
                            });
                        }else{
                            
                            item.nowcc_amount = val*100;
                            
                            this.addfreesnowpageselectdata.map((val2) => {
                            if (val2.row_code == item.row_code) {
                                val2.nowcc_amount = val*100;
                               }
                            
                            });
                            this.addfreesselectdata.map((val2) => {
                            if (val2.row_code == item.row_code) {
                                val2.nowcc_amount = val*100;
                               }
                            
                            });
                        }
                    }
                    
                    
                });
                if(!err){
                    this.addfreesselectdata.map((item) => {
                        totalfreetp += item.nowcc_amount;
                    });
                    this.addfreetotalamount=totalfreetp/100;
                    this.addfreetotalamountstr = (totalfreetp / 100).toFixed(2);
                }
            }
            this.cellediterow = "";
            this.celleditecomm = "";

        }
        if(act=='inv_goods_name'){
          this.makeinvtpdata.goodslist.map((item) => {
            if (item.row_code == row.row_code) {
              item.inv_goods_name = val;
            }
          });

        }
        if(act=='inv_goods_spec'){
          this.makeinvtpdata.goodslist.map((item) => {
            if (item.row_code == row.row_code) {
              item.inv_goods_spec = val;
            }
          });

        }
        this.cellediterow = "";
        this.celleditecomm = "";

      },

      addbilltableselechange(val) {
        if (!this.addbillvisiable) {
          return;
        }
        var check = true;
        for (var i = 0; i < this.addbullsnowpageselectdata.length; i++) {
          check = false;
          for (var j = 0; j < val.length; j++) {
            if (
              this.addbullsnowpageselectdata[i].row_code ==
              val[j].row_code
            ) {
              check = true;
              break;
            }
          }
          if (!check) {
            console.log("check false");
            for (j = 0; j < this.addbullsselectdata.length; j++) {
              if (
                this.addbullsnowpageselectdata[i].row_code ==
                this.addbullsselectdata[j].row_code
              ) {
                this.addbilltotalamount -=
                  parseInt(this.addbullsselectdata[j].nowcc_amount) / 100;
                //console.log('remove data', this.addbullsselectdata[j])
                this.addbullsselectdata.splice(j, 1);
                break;
              }
            }
          }
        }
  
        this.addbullsnowpageselectdata = JSON.parse(JSON.stringify(val));
        for (i = 0; i < this.addbullsnowpageselectdata.length; i++) {
          check = false;
          for (j = 0; j < this.addbullsselectdata.length; j++) {
            if (
              this.addbullsnowpageselectdata[i].row_code ==
              this.addbullsselectdata[j].row_code
            ) {
              check = true;
              break;
            }
          }
          if (!check) {
            this.addbilltotalamount +=
              parseInt(this.addbullsnowpageselectdata[i].nowcc_amount) / 100;
            //console.log('add data', this.addbullsnowpageselectdata[j])
            this.addbullsselectdata.push(this.addbullsnowpageselectdata[i]);
          }
        }
        this.addbilltotalamountstr = this.addbilltotalamount.toFixed(2);
      },

      makinvgoodstableselechange(val){
        this.makeinvtpdata.selectdata=val;
        console.log('makinvgoodstableselechange', this.makeinvtpdata);

       
      },

      saveaddbilldata() {
        //console.log("saveaddbilldata", this.addbullsselectdata,this.thisdata.bills);
        var h_intcode='';
        if(this.thisdata.bills==undefined){
            this.thisdata.bills=[];
        }
        
        var hrowcode='';
        this.addbullsselectdata.map((item) => {
            hrowcode='';
            this.thisdata.hdatas.map((val) => {
                if (val.datafield_code == item.mecharcode) {
                  hrowcode=val.row_code;
                }
            });
            
            
            
            if(hrowcode==''){
              var mysid='';
              mysid=uuidv4();
              this.thisdata.hdatas.push({sid:0,row_code:mysid,contacts_code:this.contacts_code,doc_date:0,remark:'',status:-1,post_date:0,ver:1,taildiff:0,created_by_code:'',created_by_name:'',created_time:0,updated_by_code:'',updated_by_name:'',updated_time:0,datafield_code:item.mecharcode,tax_name:'',tax_code:'',h_intcode:'',tax_plate:''});
              hrowcode=mysid;
            }
            


            this.thisdata.bills.push({sid:0,row_code:'',h_row_code:hrowcode,bill_code:item.h_row_code,bill_dtl_code:item.row_code,goods_name:item.goods_name,sec_unit:item.sec_unit,sec_num:item.sec_num,remain_sec_num:item.main_remain_inv_num,reconciliation_num:item.nowcc_num,mecharcode:item.mecharcode,main_unit:item.main_unit,main_num:item.main_num,sale_amount:item.sale_amount,remain_amount:item.sale_remain_inv_amount,reconciliation_amount:item.nowcc_amount,tax_rait:'',buyer_order_num:item.customer_order_no,buyer_code:item.buyer_code,bill_ver:item.ver,buyer_name:item.buyer_name,remark:item.remark,bill_no:item.bill_no,bill_time:item.bill_time,goods_spec:item.goods_spec,h_intcode:h_intcode,posted_time:item.posted_time});

        });
        //this.thisdata.bills.push({});
        this.addbillvisiable = false;
        console.log("saveaddbilldata", this.addbullsselectdata,this.thisdata);

        this.mathtotal();

      },

      saveaddfreedata() {
        
        if(this.thisdata.frees==undefined){
            this.thisdata.frees=[];
        }
        
        var hrowcode='';
        this.addfreesselectdata.map((item) => {
            hrowcode='';
            this.thisdata.hdatas.map((val) => {
                if (val.datafield_code == item.mecharcode) {
                  hrowcode=val.row_code;
                }
            });

            for(var i=0;i<this.thisdata.bills.length;i++){
                  if(this.thisdata.bills[i].bill_dtl_code==item.dtl_row_code){
                    
                    
                    if(this.thisdata.frees==undefined){
                      this.thisdata.frees=[];
                    }
                    hrowcode=this.thisdata.bills[i].h_row_code;
                    break;
                  }
                }
            
            
            
            if(hrowcode==''){
              var mysid='';
              mysid=uuidv4();
              this.thisdata.hdatas.push({sid:0,row_code:mysid,contacts_code:this.contacts_code,doc_date:0,remark:'',status:-1,post_date:0,ver:1,taildiff:0,created_by_code:'',created_by_name:'',created_time:0,updated_by_code:'',updated_by_name:'',updated_time:0,datafield_code:item.mecharcode,tax_name:'',tax_code:'',h_intcode:'',tax_plate:''});
              hrowcode=mysid;
            }
            

            this.thisdata.frees.push({sid:0,row_code:uuidv4(),h_row_code:hrowcode,bill_code:item.h_row_code,bill_dtl_code:item.dtl_row_code,bill_free_code:item.row_code,discount_code:item.freeop_code,remark:item.freeremark,free_amount:item.free_amount,remain_free_amount:item.remain_amount,reconciliation_amount:item.nowcc_amount,frees_ver: item.ver , buyer_name:item.buyer_name ,customer_order_no:item.customer_order_no   ,free_type:item.free_type     ,bill_no:item.bill_no,free_name:item.v_name                  });
                    
            //this.thisdata.frees.push({sid:0,row_code:'',h_row_code:hrowcode,bill_code:item.h_row_code,bill_dtl_code:item.row_code,goods_name:item.goods_name,sec_unit:item.sec_unit,sec_num:item.sec_num,remain_sec_num:item.main_remain_inv_num,reconciliation_num:item.nowcc_num,mecharcode:item.mecharcode,main_unit:item.main_unit,main_num:item.main_num,sale_amount:item.sale_amount,remain_amount:item.sale_remain_inv_amount,reconciliation_amount:item.nowcc_amount,tax_rait:'',buyer_order_num:item.customer_order_no,buyer_code:item.buyer_code,bill_ver:item.ver,buyer_name:item.buyer_name,remark:item.remark,bill_no:item.bill_no,bill_time:item.bill_time,goods_spec:item.goods_spec,h_intcode:h_intcode,posted_time:item.posted_time});

        });
        //this.thisdata.bills.push({});
        this.addfreevisiable = false;
        console.log("saveaddfreedata", this.addfreesselectdata,this.thisdata);
        this.mathtotal();

      },
      
      contacrscg(val){
        console.log("contacrscg", val);
        this.contacts_code=val.row_code;
        this.addbillsearchdata.contact=val.row_code;
        this.addfreesearchdata.contact=val.row_code;
      },

      taxplatecg(val){
        console.log("taxplatecg", val);
        this.tax_plate=val.row_code;
        this.tax_plate_select=val.platename;
        this.tax_free_bj=val.invoicetype_v;
        this.tax_plate_pcode=val.row_code;
        
      },
      
      openaddbill() {
        
        if(this.contacts_code=='' || this.contacts_code==null){
            ElMessage.error({
                message: "请先选择合同归属，之后方可添加明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        this.addbillvisiable = true;
      },

      openaddfree() {
        if(this.contacts_code=='' || this.contacts_code==null){
            ElMessage.error({
                message: "请先选择合同归属，之后方可选择费用明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        if(this.thisdata.hdatas.length<=0){
            ElMessage.error({
                message: "请先添加账单明细或费用明细，之后方可添加费用明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        this.addfreevisiable = true
      },

      openaddadfree(){
        if(this.contacts_code=='' || this.contacts_code==null){
            ElMessage.error({
                message: "请先选择合同归属，之后方可添加费用明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        if(this.thisdata.hdatas.length<=0){
            ElMessage.error({
                message: "请先添加账单明细，之后方可添加费用明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        this.addadfreevisiable = true
      },

      openaddadfree2(){
        if(this.contacts_code=='' || this.contacts_code==null){
            ElMessage.error({
                message: "请先选择合同归属，之后方可添加费用明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        if(this.thisdata.hdatas.length<=0){
            ElMessage.error({
                message: "请先添加账单明细，之后方可添加费用明细！！！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
            });
            return;
        }
        this.addadfreevisiable2 = true
      },

      dellbill(val) {
        var i=0;
        var j=0;
        var check=false;
        console.log("befordellbill",this.thisdata);
        if(this.thisdata.delbilldatas==undefined){
          this.thisdata.delbilldatas=[];
        }
        if(this.thisdata.delhdatas==undefined){
          this.thisdata.delhdatas=[];
        }
        if(this.thisdata.delfreesdatas==undefined){
          this.thisdata.delfreesdatas=[];
        }
        if(this.thisdata.frees==undefined){
          this.thisdata.frees=[];
        }
        for( i=this.thisdata.bills.length-1;i>=0;i--){
          if(this.thisdata.bills[i].bill_dtl_code==val.bill_dtl_code){
            if(this.thisdata.bills[i].sid!=0){
              this.thisdata.delbilldatas.push(this.thisdata.bills[i]);
            }
            this.thisdata.bills.splice(i,1);
            //删除对应的票折
            for(j=this.thisdata.frees.length-1;j>=0;j--){
              if(this.thisdata.frees[j].bill_dtl_code==val.bill_dtl_code){
                if(this.thisdata.frees[j].sid!=0){
                  this.thisdata.delfreesdatas.push(this.thisdata.frees[j]);
                }
                this.thisdata.frees.splice(j,1);
              }
            }
          }
        }
        //检查hdata是否需要删除
        for(i=this.thisdata.hdatas.length-1;i>=0;i--){
          check=false;
          for(j=0;j<this.thisdata.bills.length;j++){
            if(this.thisdata.bills[j].h_row_code==this.thisdata.hdatas[i].row_code ){
              check=true;
              break;
            }
          }
          if(!check){
            //需要删除
            if(this.thisdata.hdatas[i].sid!=0){
              this.thisdata.delhdatas.push(this.thisdata.hdatas[i]);
            }
            //去除对应手工添加的票折
            for(j=this.thisdata.frees.length-1;j>=0;j--){
              if(this.thisdata.frees[j].h_row_code==this.thisdata.hdatas[i].row_code ){
                this.thisdata.frees.splice(j,1);
              }
            }

            //完整剔除本张单据，则明细无需做删除操作
            for(j=this.thisdata.delbilldatas.length-1;j>=0;j--){
              if(this.thisdata.delbilldatas[j].h_row_code==this.thisdata.hdatas[i].row_code){
                this.thisdata.delbilldatas.splice(j,1);
              }
              
            }
            for(j=this.thisdata.delfreesdatas.length-1;j>=0;j--){
              if(this.thisdata.delfreesdatas[j].h_row_code==this.thisdata.hdatas[i].row_code ){
                this.thisdata.delfreesdatas.splice(j,1);
              }
              
            }


            this.thisdata.hdatas.splice(i,1);
          }
        }
        console.log("dellbill",this.thisdata);

        this.mathtotal();
        // if( val.h_intcode!=''){
        //   ElMessageBox.confirm("该明细属于被合并单据，如删除本条明细将同时删除该被合并单据的其他明细，删除操作不可撤销，是否确定？？", "警告！！")
        //   .then(() => {
        //     if(this.thisdata.delbilldatas==undefined){
        //       this.thisdata.delbilldatas=[];
        //     }
        //     if(this.thisdata.delhdatas==undefined){
        //       this.thisdata.delhdatas=[];
        //     }
        //     if(this.thisdata.delfreesdatas==undefined){
        //       this.thisdata.delfreesdatas=[];
        //     }
        //     for( i=this.thisdata.bills.length-1;i>=0;i--){
        //       if(this.thisdata.bills[i].h_row_code==val.h_row_code){
                
        //         if(this.thisdata.bills[i].row_code!=''){
        //           this.thisdata.delbilldatas.push(this.thisdata.bills[i]);
        //         }
        //         for(j=this.thisdata.frees.length-1;j>=0;j--){

        //         }
                
        //         this.thisdata.bills.splice(i,1);
        //       }
        //     }
        //     for( i=this.thisdata.hdatas.length-1;i>=0;i--){
        //       if(this.thisdata.hdatas[i].row_code==val.h_row_code){
        //         if(this.thisdata.hdatas[i].row_code!=''){
        //           this.thisdata.delhdatas.push(this.thisdata.hdatas[i]);
        //         }
        //         this.thisdata.hdatas.splice(i,1);
        //       }
        //     }
           

        //   })
        //   .catch(() => {});
        // }else{
        //     for( i=this.thisdata.bills.length-1;i>=0;i--){
        //       if(this.thisdata.bills[i].bill_dtl_code==val.bill_dtl_code){
        //         if(this.thisdata.bills[i].row_code!=''){
        //           this.thisdata.delbilldatas.push(this.thisdata.bills[i]);
        //         }
        //         this.thisdata.bills.splice(i,1);
        //       }
        //     }
        // }
      },

      cleanbillfree(){
        this.thisdata.frees.map((item) => {
          if(item.sid!=0){
            if(this.thisdata.delfreesdatas==undefined){
              this.thisdata.delfreesdatas=[];
            }
            this.thisdata.delfreesdatas.push(item);
          }
        });
        this.thisdata.frees=[];
        this.mathtotal();
      },

      addbillfree() {
        ElMessageBox.confirm(
          "即将引入账单明细对应的票折明细，确认执行本操作吗？？？"
        )
          .then(() => {
            this.$bus.$emit("showloading");
           
            axios
            .post("/" + this.dialoginfo.modlecode + "/getfreesdata", {
              data: {
                havbills: this.thisdata.bills,
                havfrees: this.thisdata.frees,
              },
            })
            .then((required) => {
              
              var nowreamet=0;
              required.map((item) => {
                for(var i=0;i<this.thisdata.bills.length;i++){
                  if(this.thisdata.bills[i].bill_dtl_code==item.dtl_row_code){
                    
                    if(this.thisdata.bills[i].reconciliation_amount==this.thisdata.bills[i].remain_amount){
                      nowreamet=item.remain_amount;
                    }else{
                      nowreamet=(item.free_amount*this.thisdata.bills[i].reconciliation_amount/this.thisdata.bills[i].sale_amount).toFixed(0);
                    }
                    
                    if(this.thisdata.frees==undefined){
                      this.thisdata.frees=[];
                    }
                    
                    this.thisdata.frees.push({sid:0,row_code:uuidv4(),h_row_code:this.thisdata.bills[i].h_row_code,bill_code:item.h_row_code,bill_dtl_code:item.dtl_row_code,bill_free_code:item.row_code,discount_code:item.freeop_code,remark:item.freeremark,free_amount:item.free_amount,remain_free_amount:item.remain_amount,reconciliation_amount:nowreamet,frees_ver: item.ver , buyer_name:item.buyer_name ,customer_order_no:item.customer_order_no   ,free_type:item.free_type     ,bill_no:item.bill_no,free_name:item.v_name                  });
                    i=this.thisdata.bills.length+1;
                  }
                }
              });

              console.log("getfreesdata", required,this.thisdata);

              this.$bus.$emit("hideloading");
              this.mathtotal();
            })
            .catch(() => {this.$bus.$emit("hideloading");});
  
              
  
           
          })
          .catch(() => {
            console.log("cancel");
          });
      },

      dellbefor(val){
        var i=0;
        for( i=this.thisdata.beforinv.length-1;i>=0;i--){
          if(this.thisdata.beforinv[i].row_code==val.row_code){
            if(this.thisdata.beforinv[i].sid!=0){
              if(this.thisdata.delbeforinvdatas==undefined){
                this.thisdata.delbeforinvdatas=[];
              }
              this.thisdata.delbeforinvdatas.push(this.thisdata.beforinv[i]);
            }
            this.thisdata.beforinv.splice(i,1);
          }
        }
        this.mathtotal();
      },
      
      dellfree(val) {
        var i=0;
        for( i=this.thisdata.frees.length-1;i>=0;i--){
          if(this.thisdata.frees[i].row_code==val.row_code){
            if(this.thisdata.frees[i].sid!=0){
              if(this.thisdata.delfreesdatas==undefined){
                this.thisdata.delfreesdatas=[];
              }
              this.thisdata.delfreesdatas.push(this.thisdata.frees[i]);
            }
            this.thisdata.frees.splice(i,1);
          }
        }
        this.mathtotal();
      },
      
      addfreesbuyerinforcg(val) {
        this.addfreesearchdata.buyerinfor = [];
        for (var i = 0; i < val.length; i++) {
          this.addfreesearchdata.buyerinfor.push(val[i].row_code);
        }
      },

      invgoodsttedtaxcodecg(val){
        console.log('invgoodsttedtaxcodecg',val)
        this.makeinvtpdata.goodsttedit.taxcodeid=val.row_code;
        this.makeinvtpdata.goodsttedit.taxcode=val.v_num;
        this.makeinvtpdata.goodsttedit.taxcodelv=val.v_lvs;
        this.makeinvtpdata.goodsttedit.taxcodev=val.v_name+'[*'+val.t_name+'*]';

      },
      
      addbillsbuyerinforcg(val) {
        this.addbillsearchdata.buyerinfor = [];
        for (var i = 0; i < val.length; i++) {
          this.addbillsearchdata.buyerinfor.push(val[i].row_code);
        }
      },
      
      addfreetableselechange(val) {
        if (!this.addfreevisiable) {
          return;
        }
        var check = true;
        for (var i = 0; i < this.addfreesnowpageselectdata.length; i++) {
          check = false;
          for (var j = 0; j < val.length; j++) {
            if (
              this.addfreesnowpageselectdata[i].row_code ==
              val[j].row_code
            ) {
              check = true;
              break;
            }
          }
          if (!check) {
            for (j = 0; j < this.addfreesselectdata.length; j++) {
              if (
                this.addfreesnowpageselectdata[i].row_code ==
                this.addfreesselectdata[j].row_code
              ) {
                this.addfreetotalamount -=
                  parseInt(this.addfreesselectdata[j].nowcc_amount) /
                  100;
                //console.log('remove data', this.addbullsselectdata[j])
                this.addfreesselectdata.splice(j, 1);
                break;
              }
            }
          }
        }
        this.addfreesnowpageselectdata = JSON.parse(JSON.stringify(val));
  
        for (i = 0; i < this.addfreesnowpageselectdata.length; i++) {
          check = false;
          for (j = 0; j < this.addfreesselectdata.length; j++) {
            if (
              this.addfreesnowpageselectdata[i].row_code ==
              this.addfreesselectdata[j].row_code
            ) {
              check = true;
              break;
            }
          }
          if (!check) {
            this.addfreetotalamount +=
              parseInt(this.addfreesnowpageselectdata[i].nowcc_amount) /
              100;
            console.log("add data", this.addfreetotalamount);
            this.addfreesselectdata.push(this.addfreesnowpageselectdata[i]);
          }
        }
        this.addfreetotalamountstr = this.addfreetotalamount.toFixed(2);
      },

      saveaddadfreedata() {
        

        var numReg = /^[-]?[0-9]+[.]{0,1}[0-9]{0,2}$/;        
        var numRe = new RegExp(numReg);
        if (!numRe.test(this.addadfreesdata.number)){
          ElMessage.error({
            message: "折扣金额格式不符合要求，小数位数最多两位，请检查！！！！！",
            duration: 2000,
          });
          this.addadfreesdata.number=0;
          return;
        }
        if(this.addadfreesdata.number==0 || this.addadfreesdata.discount=='' || this.addadfreesdata.discount==null){
          ElMessage.error({
            message: "需要选择折扣项目，并填写折扣金额，请检查后重试！！！！！",
            duration: 2000,
          });
          this.addadfreesdata.number=0;
          return;
        }
        console.log("saveaddadfreedata", this.addadfreesdata);
        this.thisdata.frees.push({sid:0,row_code:uuidv4(),h_row_code:'',bill_code:'',bill_dtl_code:'',bill_free_code:'',discount_code:this.addadfreesdata.discount,remark:this.addadfreesdata.atremark,free_amount:this.addadfreesdata.number*100,remain_free_amount:0,reconciliation_amount:this.addadfreesdata.number*100,frees_ver: 1 , buyer_name:'' ,customer_order_no:''   ,free_type:this.addadfreesdata.type     ,bill_no:'',free_name:this.addadfreesdata.v_name   ,is_befor:0               });
        this.addadfreevisiable = false;
        this.mathtotal();
      },

      saveaddadfreedata2() {
        
        if(this.thisdata.beforinv==undefined){
          this.thisdata.beforinv=[];
        }
         
        

        var numReg = /^[-]?[0-9]+[.]{0,1}[0-9]{0,2}$/;        
        var numRe = new RegExp(numReg);
        if (!numRe.test(this.addadfreesdata.number)){
          ElMessage.error({
            message: "折扣金额格式不符合要求，小数位数最多两位，请检查！！！！！",
            duration: 2000,
          });
          this.addadfreesdata.number=0;
          return;
        }
        if(this.addadfreesdata.number==0 || this.addadfreesdata.discount=='' || this.addadfreesdata.discount==null){
          ElMessage.error({
            message: "需要选择折扣项目，并填写折扣金额，请检查后重试！！！！！",
            duration: 2000,
          });
          this.addadfreesdata.number=0;
          return;
        }
        console.log("saveaddadfreedata2", this.addadfreesdata);
        this.thisdata.beforinv.push({sid:0,row_code:uuidv4(),h_row_code:'',bill_code:'',bill_dtl_code:'',bill_free_code:'',discount_code:this.addadfreesdata.discount,remark:this.addadfreesdata.atremark,free_amount:this.addadfreesdata.number*100,remain_free_amount:0,reconciliation_amount:this.addadfreesdata.number*100,frees_ver: 1 , buyer_name:'' ,customer_order_no:''   ,free_type:this.addadfreesdata.type     ,bill_no:'',free_name:this.addadfreesdata.v_name   ,is_befor:1               });
        this.addadfreevisiable2 = false;
        this.mathtotal();
      },

      addadfreesdatadiscountcg(val) {
        //console.log("addadfreesdatadiscount",val);
        // {discount:'',v_name:'',time:'',remark:''},
        if (val == null) {
          this.addadfreesdata.discount = "";
          this.addadfreesdata.v_name = "";
          this.addadfreesdata.remark = "";
          this.addadfreesdata.time = "";
          return;
        }
        this.addadfreesdata.discount = val.row_code;
        this.addadfreesdata.v_name = val.v_name;
        this.addadfreesdata.remark = val.remark;
        this.addadfreesdata.type = val.type;
        this.addadfreesdata.time =
          this.datetimeformart(val.begintime) +
          " 至 " +
          this.datetimeformart(val.endtime);
      },

      mathtotal(){
        console.log('mathtotal',this.thisdata.bills,this.thisdata.frees,this.thisdata.invos);
        this.totalinvamount = 0;
        this.totalbillamount = 0;
        this.totalfreeamount = 0;
        this.totalbeforamount=0;
        if(this.thisdata.bills==undefined){
          this.thisdata.bills=[];
        }
        if(this.thisdata.frees==undefined){
          this.thisdata.frees=[];
        }
        if(this.thisdata.invos==undefined){
          this.thisdata.invos=[];
        }
        if(this.thisdata.beforinv==undefined){
          this.thisdata.beforinv=[];
        }

        this.thisdata.beforinv.map((item)=>{
          this.totalbeforamount+=parseInt(item.reconciliation_amount);
        });

        this.thisdata.bills.map((item) => {
          this.totalbillamount += parseInt(item.reconciliation_amount);
        });
        this.thisdata.frees.map((item) => {
          this.totalfreeamount += parseInt(item.reconciliation_amount);
        });
        this.thisdata.invos.map((item) => {
          this.totalinvamount += parseInt(item.total_amount);
        });
        
      },



      taxbuyercodecg(val){
        console.log("taxbuyercodecg", val);
        this.tax_name=val.v_name;
        this.tax_num=val.taxnum;
      },

      tablesleditchange(val,type,rowdata){
        if(type=='inv_unit'){
          this.makeinvtpdata.goodslist.map((item) => {
            if (item.row_code == rowdata.row_code) {
              item.inv_unit = val;
              for(var i=0;i<rowdata.unit_list.length;i++){
                if(rowdata.unit_list[i]==val){
                  item.inv_unit_lv=rowdata.unit_lv_list[i];
                  break;
                }
              }
            }
          });
        }
        if(type=='invtype'){
          this.makeinvtpdata.goodslist.map((item) => {
            if (item.row_code == rowdata.row_code) {
              item.invtype = val;
            }
          });
        }
        if(type=='tax_rait'){
          this.makeinvtpdata.goodslist.map((item) => {
            if (item.row_code == rowdata.row_code) {
              item.tax_rait = val;
            }
          });
        }
        console.log("tablesleditchange",val,this.makeinvtpdata);

        this.cellediterow = "";
        this.celleditecomm = "";
      },

      invfront(){

        this.stepsa--;
      },

      getSummaries(data) {
      const sums = [];
      data.columns.forEach((element, index) => {
        var value = "";
        if (element.label == "项目名称") {
          sums[index] = "合计";
          return;
        }
        if (element.label == "金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.amount);
          });
          value = (value ).toFixed(2);
        }
        if (element.label == "税额") {
          value = Number(0);
          data.data.forEach((row) => {
            if(row.txamount=='***'){
              value='***';
            }else{
              value += Number(row.txamount);
            }
            
          });
          if(value!='***'){
            value = (value ).toFixed(2);
          }
          
        }
        
        sums[index] = value;
      });

      return sums;
      },

      invnext(){
        if(this.stepsa==1){
          if(this.tax_name=='' || this.tax_name==null || this.tax_plate=='' || this.tax_plate==null){
            ElMessage.error({
              message: "请先填写开票抬头！！！！！",
              duration: 2000,
            });
            return;
          }
          var acctp='|';
          if(this.makeinvtpdata.goodslist==undefined){
            this.makeinvtpdata.goodslist=[];
          }
          if(this.makeinvtpdata.goodslist.length<=0){
            this.thisdata.bills.map((item) => {
              if(acctp.indexOf('|'+item.goods_name+'|')<0){
                acctp+=item.goods_name+'|';
                if(item.goods_spec==undefined || item.goods_spec==null){
                  item.goods_spec='';
                }
                if(item.main_unit==item.sec_unit){
                  this.makeinvtpdata.goodslist.push({row_code:uuidv4(),ttprice:0,tax_code_lvs:'',goods_name:item.goods_name,inv_goods_name:item.goods_name,inv_goods_spec:item.goods_spec,goods_spec:item.goods_spec,inv_unit:item.sec_unit,inv_unit_lv:item.sec_num/item.main_num,unit_list:[item.main_unit],unit_st:'|'+item.main_unit+'|',unit_lv_list:[1],tax_rait:'',invtype:'',tax_code:'',tax_v_code:'',unit_type:0});
                }else{
                  this.makeinvtpdata.goodslist.push({row_code:uuidv4(),ttprice:0,tax_code_lvs:'',goods_name:item.goods_name,inv_goods_name:item.goods_name,inv_goods_spec:item.goods_spec,goods_spec:item.goods_spec,inv_unit:item.sec_unit,inv_unit_lv:item.sec_num/item.main_num,unit_list:[item.main_unit,item.sec_unit],unit_st:'|'+item.main_unit+'|'+item.sec_unit+"|",unit_lv_list:[1,item.sec_num/item.main_num],tax_rait:'',invtype:'',tax_code:'',tax_v_code:'',unit_type:0});
                }
                }else{
                this.makeinvtpdata.goodslist.map((val) => {
                  if(val.goods_name==item.goods_name){
                    if(val.unit_st.indexOf('|'+item.sec_unit+'|')<0){
                      val.unit_st+=item.sec_unit+'|';
                      val.unit_list.push(item.sec_unit);
                      val.unit_lv_list.push(item.sec_num/item.main_num);
                    }
                  }
                });
              }
            });
          }
         
          this.makeinvtpdata.goodsttedit={goodsname:'',goodsspec:'',taxcodeid:'',unit:'',price:0,invtype:'',taxcode:'',taxcodev:'',taxcodelv:'',taxlv:''},
          
          this.makeinvtpdata.cfcek={odno:false,outno:false,price:false,unit:false,remark:false,zhekou:1,remarkrule:''};
          
          if(this.$refs.makeinvgoodslist!=undefined){
            this.$refs.makeinvgoodslist.clearSelection();
          }
        
        }

        if(this.stepsa==2){
          var step2checkerrstring='';
          this.makeinvtpdata.goodslist.map((item)=>{
            if(item.inv_goods_name==''){
              if(!(step2checkerrstring.indexOf('存在未指定开票物料名称的明细')>=0)){
                step2checkerrstring+='存在未指定开票物料名称的明细<br/>';
              }
            }
            if(item.invtype==''){
              if(!(step2checkerrstring.indexOf('存在未指定发票类型的明细')>=0)){
                step2checkerrstring+='存在未指定发票类型的明细<br/>';
              }
            }
            if(item.tax_code==''){
              if(!(step2checkerrstring.indexOf('存在未指定税收编码的明细')>=0)){
                step2checkerrstring+='存在未指定税收编码的明细<br/>';
              }
            }
            if(item.tax_rait==''){
              if(!(step2checkerrstring.indexOf('存在未指定税率的明细')>=0)){
                step2checkerrstring+='存在未指定税率的明细<br/>';
              }
            }
            if(item.tax_rait=='-1' && item.invtype=='028'){
              if(!(step2checkerrstring.indexOf('存在免税商品设置为专用发票的明细')>=0)){
                step2checkerrstring+='存在免税商品设置为专用发票的明细<br/>';
              }
            }
            
            if(item.tax_rait=='-1' && this.tax_free_bj==0){
              if(!(step2checkerrstring.indexOf('当前企业未申请税收优惠，但存在设置为免税的商品明细')>=0)){
                step2checkerrstring+='当前企业未申请税收优惠，但存在设置为免税的商品明细<br/>';
              }
            }

            if(item.tax_rait!='-1' && this.tax_free_bj==1 && item.tax_code_lvs.indexOf('|-1|')>=0){
              if(!(step2checkerrstring.indexOf('当前企业享受税收优惠，但存在设置为非免税的免税商品明细')>=0)){
                step2checkerrstring+='当前企业享受税收优惠，但存在设置为非免税的免税商品明细<br/>';
              }
            }

          })
          if(step2checkerrstring!=''){
            ElMessage.error({
            message: '经检查，物料设置存在如下错误，请检查后重试：<br/>'+step2checkerrstring,
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
            });
            return;
          }
          //ref="makeinvgoodslist"

          this.makeinvtpdata.goodsttedit={goodsname:'',goodsspec:'',taxcodeid:'',unit:'',price:0,invtype:'',taxcode:'',taxcodev:'',taxcodelv:'',taxlv:''},
          
          this.makeinvtpdata.cfcek={odno:false,outno:false,price:false,unit:false,remark:false,zhekou:1,remarkrule:''};
          if(this.$refs.makeinvgoodslist!=undefined){
            this.$refs.makeinvgoodslist.clearSelection();
          }
        }

        if(this.stepsa==3){

          if(this.makeinvtpdata.cfcek.remarkrule!=''){
            var tpp=this.makeinvtpdata.cfcek.remarkrule;
            var ckpass=true;
            if(tpp.indexOf('<billno>')>=0){
              if(tpp.indexOf('<billno>')+8>=tpp.length){
                ckpass=false;
              }
            }
            if(tpp.indexOf('<outno>')>=0){
              if(tpp.indexOf('<outno>')+7>=tpp.length){
                ckpass=false;
              }
            }
            if(tpp.indexOf('<remark>')>=0){
              if(tpp.indexOf('<remark>')+8>=tpp.length){
                ckpass=false;
              }
            }
            if(!ckpass){
              ElMessage.error({
                message: '末尾占位符后未添加分割字符，请添加后重试',
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
              });
              return;
            }


           
          }


          //开始生成发票
          this.makeinvtpdata.tpinvdata=[];

          //生成发票主信息
          this.thisdata.bills.map((item)=>{
            var thistpnum=0;
            var i=0;            
            var j=0;
            //获取物料开票信息
            var tpinvgoodsinfo={};
            for(i=0;i<this.makeinvtpdata.goodslist.length;i++){
              if(this.makeinvtpdata.goodslist[i].goods_name==item.goods_name){
                tpinvgoodsinfo=this.makeinvtpdata.goodslist[i];
                break;
              }
            }

          
            var ckpass=false;
            for(i=0;i<this.makeinvtpdata.tpinvdata.length;i++){
              //检验是否需要拆分发票
              ckpass=true;
              if(this.makeinvtpdata.cfcek.odno && item.bill_no!=this.makeinvtpdata.tpinvdata[i].bill_no){
                ckpass=false;
              }
              if(this.makeinvtpdata.cfcek.outno && item.buyer_order_num!=this.makeinvtpdata.tpinvdata[i].buyer_order_num){
                ckpass=false;
              }     
              if(this.makeinvtpdata.cfcek.remark && item.remark!=this.makeinvtpdata.tpinvdata[i].remark){
                ckpass=false;
              }
              if(tpinvgoodsinfo.invtype!=this.makeinvtpdata.tpinvdata[i].invtype){
                ckpass=false;
              }
              if(tpinvgoodsinfo.tax_rait!=this.makeinvtpdata.tpinvdata[i].tax_rait){
                ckpass=false;
              }

              if(ckpass){
                //无需拆分，插入当前发票明细

                //首先合并订单号、客户订单号、和摘要
                var hasccdata=false;
                for(j=0;j<this.makeinvtpdata.tpinvdata[i].bill_no_list.length;j++){
                  if(item.bill_no==this.makeinvtpdata.tpinvdata[i].bill_no_list[j]){
                    hasccdata=true;
                    break;
                  }
                }
                if(!hasccdata){
                  this.makeinvtpdata.tpinvdata[i].bill_no_list.push(item.bill_no);
                }
                hasccdata=false;
                for(j=0;j<this.makeinvtpdata.tpinvdata[i].buyer_order_num_list.length;j++){
                  if(item.buyer_order_num==this.makeinvtpdata.tpinvdata[i].buyer_order_num_list[j]){
                    hasccdata=true;
                    break;
                  }
                }
                if(!hasccdata){
                  this.makeinvtpdata.tpinvdata[i].buyer_order_num_list.push(item.buyer_order_num);
                }
                hasccdata=false;
                for(j=0;j<this.makeinvtpdata.tpinvdata[i].remark_list.length;j++){
                  if(item.remark==this.makeinvtpdata.tpinvdata[i].remark_list[j]){
                    hasccdata=true;
                    break;
                  }
                }
                if(!hasccdata){
                  this.makeinvtpdata.tpinvdata[i].remark_list.push(item.buyer_order_num);
                }
                //合并结束

                //检查明细
                var dtlckpass=false;
                for(j=0;j<this.makeinvtpdata.tpinvdata[i].dtl.length;j++){
                  if(this.makeinvtpdata.tpinvdata[i].dtl[j].inv_goods_name==tpinvgoodsinfo.inv_goods_name){
                    this.makeinvtpdata.tpinvdata[i].dtl[i].amount+=parseInt(item.reconciliation_amount);
                    if(tpinvgoodsinfo.inv_unit_lv==-1){
                      this.makeinvtpdata.tpinvdata[i].dtl[i].num+=Math.ceil(item.reconciliation_amount/tpinvgoodsinfo.ttprice/100)*10000;
                    }else{
                      this.makeinvtpdata.tpinvdata[i].dtl[i].num+=Math.round(item.reconciliation_num*tpinvgoodsinfo.inv_unit_lv);
                    }
                    
                    dtlckpass=true;
                    break;
                  }
                }
                if(!dtlckpass){
                  //增加明细
                  thistpnum=0;
                  if(tpinvgoodsinfo.inv_unit_lv==-1){
                    thistpnum=Math.ceil(item.reconciliation_amount/tpinvgoodsinfo.ttprice/100)*10000;
                  }else{
                    thistpnum=Math.round(item.reconciliation_num*tpinvgoodsinfo.inv_unit_lv);
                  }
                  console.log('mathdd',item.reconciliation_num,tpinvgoodsinfo.inv_unit_lv,thistpnum);

                  this.makeinvtpdata.tpinvdata[i].dtl.push({row_code:uuidv4(),inv_goods_name:tpinvgoodsinfo.inv_goods_name,inv_goods_spec:tpinvgoodsinfo.inv_goods_spec,inv_unit:tpinvgoodsinfo.inv_unit,inv_unit_lv:tpinvgoodsinfo.inv_unit_lv,tax_code:tpinvgoodsinfo.tax_code,tax_rait:tpinvgoodsinfo.tax_rait,amount:item.reconciliation_amount,num:thistpnum,ttprice:tpinvgoodsinfo.ttprice,distamount:0,taxcode_v:tpinvgoodsinfo.tax_v_code});

                }
                this.makeinvtpdata.tpinvdata[i].total_amount+=parseInt(item.reconciliation_amount);
                break;
              }


            }

            if(!ckpass && tpinvgoodsinfo.invtype!='999'){
              thistpnum=0;
              if(tpinvgoodsinfo.inv_unit_lv==-1){
                thistpnum=Math.ceil(item.reconciliation_amount/tpinvgoodsinfo.ttprice/100)*10000;
              }else{
                thistpnum=Math.round(item.reconciliation_num*tpinvgoodsinfo.inv_unit_lv);
              }
              this.makeinvtpdata.tpinvdata.push({
                row_code:uuidv4(),
                tax_plate_name:this.tax_plate_select,
                tax_plate:this.tax_plate,
                buyer_name:this.tax_name,
                buyer_taxnum:this.tax_num,
                tax_email:this.tax_email,
                invtype:tpinvgoodsinfo.invtype,
                tax_rait:tpinvgoodsinfo.tax_rait,
                bill_no:item.bill_no,
                bill_no_list:[item.bill_no],
                buyer_order_num:item.buyer_order_num,
                buyer_order_num_list:[item.buyer_order_num],
                remark:item.remark,
                totalfrees:0,
                remark_list:[item.remark],
                inv_remark:'',
                total_amount:parseInt(item.reconciliation_amount),
                dtl:[{inv_goods_name:tpinvgoodsinfo.inv_goods_name,inv_goods_spec:tpinvgoodsinfo.inv_goods_spec,inv_unit:tpinvgoodsinfo.inv_unit,inv_unit_lv:tpinvgoodsinfo.inv_unit_lv,tax_code:tpinvgoodsinfo.tax_code,tax_rait:tpinvgoodsinfo.tax_rait,amount:item.reconciliation_amount,num:thistpnum,ttprice:tpinvgoodsinfo.ttprice,distamount:0,taxcode_v:tpinvgoodsinfo.tax_v_code}]
              });

            }


          });

          //生成票前费用

          if(this.totalbeforamount!=0){
            var zkbl=this.totalbeforamount/this.totalbillamount;
            var totalbefor=0;
            var invcgam=0;
            var tpbefor=0;
            var i=0;
            this.makeinvtpdata.tpinvdata.map((headdata)=>{
              headdata.dtl.map((dtldata)=>{
                tpbefor=Math.round(dtldata.amount*zkbl);
                totalbefor+=tpbefor;
                dtldata.amount-=tpbefor;
                headdata.total_amount-=tpbefor;
              });
            });
            //调整尾差
            var diffv=this.totalbeforamount-totalbefor;
            if(diffv!=0){
              var maxif=0;
              var maxhi=0;
              var maxdi=0;
              for(i=0;i<this.makeinvtpdata.tpinvdata.length;i++){
                if(this.makeinvtpdata.tpinvdata[i].total_amount>maxif){
                  maxhi=i;
                  maxif=this.makeinvtpdata.tpinvdata[i].total_amount;
                }
              }
              maxif=0;
              for(i=0;i<this.makeinvtpdata.tpinvdata[maxhi].dtl.length;i++){
                if(this.makeinvtpdata.tpinvdata[maxhi].dtl[i].amount>maxif){
                  maxdi=i;
                  maxif=this.makeinvtpdata.tpinvdata[maxhi].dtl[i].amount;
                }
              }
              this.makeinvtpdata.tpinvdata[maxhi].dtl[maxdi].amount-=diffv;
              this.makeinvtpdata.tpinvdata[maxhi].total_amount-=diffv;
            }




          }



          //生成折扣信息

          if(this.totalfreeamount>0){
            zkbl=this.totalfreeamount/(this.totalbillamount-this.totalbeforamount);
            var totalfree=0;
            invcgam=0;
            var tpfree=0;
            i=0;
            if(this.makeinvtpdata.cfcek.zhekou==1){
              this.makeinvtpdata.tpinvdata.map((headdata)=>{
                headdata.dtl.map((dtldata)=>{
                  tpfree=Math.round(dtldata.amount*zkbl);
                  totalfree+=tpfree;
                  dtldata.distamount=tpfree;
                  headdata.total_amount-=tpfree;
                  headdata.totalfrees+=tpfree;
                });
              })
            }else{
              this.makeinvtpdata.tpinvdata.map((headdata)=>{
                invcgam=0;
                for(i=0;i<headdata.dtl.length-1;i++){
                  tpfree=Math.round(headdata.dtl[i].amount*zkbl*2)
                  headdata.dtl[i].amount-=tpfree;
                  invcgam+=tpfree;
                }
                headdata.dtl[headdata.dtl.length-1].amount+=invcgam;
                tpfree=Math.round(headdata.total_amount*zkbl);
                headdata.dtl[headdata.dtl.length-1].distamount=tpfree;
                headdata.total_amount-=tpfree;
                headdata.totalfrees=tpfree;
                totalfree+=tpfree;
              })
            }
            //调整尾差
            diffv=this.totalfreeamount-totalfree;
            if(diffv!=0){
               maxif=0;
               maxhi=0;
               maxdi=0;
              for(i=0;i<this.makeinvtpdata.tpinvdata.length;i++){
                if(this.makeinvtpdata.tpinvdata[i].totalfrees>maxif){
                  maxhi=i;
                  maxif=this.makeinvtpdata.tpinvdata[i].totalfrees;
                }
              }
              maxif=0;
              for(i=0;i<this.makeinvtpdata.tpinvdata[maxhi].dtl.length;i++){
                if(this.makeinvtpdata.tpinvdata[maxhi].dtl[i].distamount>maxif){
                  maxdi=i;
                  maxif=this.makeinvtpdata.tpinvdata[maxhi].dtl[i].distamount;
                }
              }
              this.makeinvtpdata.tpinvdata[maxhi].dtl[maxdi].distamount+=diffv;
              this.makeinvtpdata.tpinvdata[maxhi].totalfree+=diffv;
              this.makeinvtpdata.tpinvdata[maxhi].total_amount-=diffv;
            }
           




          }

          //生成摘要信息

          if(this.makeinvtpdata.cfcek.remarkrule!=''){
            var needbill=false;
            var needoutb=false;
            var needremark=false;
            var billgg='';
            var outbgg='';
            var remarkgg='';
            var rmrule=this.makeinvtpdata.cfcek.remarkrule;
            var tpst='';
            var tpsts='';

            if(rmrule.indexOf('<billno>')>=0){
              needbill=true;
              billgg=rmrule.substring(rmrule.indexOf('<billno>')+8,rmrule.indexOf('<billno>')+9);
            }
            if(rmrule.indexOf('<outno>')>=0){
              needoutb=true;
              outbgg=rmrule.substring(rmrule.indexOf('<outno>')+7,rmrule.indexOf('<outno>')+8);
            }
            if(rmrule.indexOf('<remark>')>=0){
              needremark=true;
              remarkgg=rmrule.substring(rmrule.indexOf('<remark>')+8,rmrule.indexOf('<remark>')+9);
            }
          

            this.makeinvtpdata.tpinvdata.map((hdata)=>{
              tpst=rmrule;
              if(needbill){
                tpst=tpst.substring(0,tpst.indexOf('<billno>'))+hdata.bill_no_list.join(billgg)+tpst.substring(tpst.indexOf('<billno>')+9);
              }
              if(needoutb){
                tpsts='';
                hdata.buyer_order_num_list.map((bnls)=>{
                  if(bnls!=''){
                    if(tpsts!=''){
                      tpsts+=outbgg;
                    }
                    tpsts+=bnls;
                  }
                });
                tpst=tpst.substring(0,tpst.indexOf('<outno>'))+tpsts+tpst.substring(tpst.indexOf('<outno>')+8);
             
              }
              if(needremark){
                tpsts='';
                hdata.remark_list.map((bnls)=>{
                  if(bnls!=''){
                    if(tpsts!=''){
                      tpsts+=remarkgg;
                    }
                    tpsts+=bnls;
                  }
                });
                tpst=tpst.substring(0,tpst.indexOf('<remark>'))+tpsts+tpst.substring(tpst.indexOf('<remark>')+9);
             
              }
              if(tpst.length>230){
                tpst=tpst.substring(0,229);
              }

              hdata.inv_remark=tpst;

            });

          }

          //计算发票总金额,检查明细和表头金额是否一致
          
          var tpdtlam=0;
          var hdam=0;
          this.makeinvtpdata.tpinvdata.map((hdata)=>{
            hdam+=parseInt(hdata.total_amount);
            hdata.dtl.map((ddata)=>{
              tpdtlam+=parseInt(ddata.amount);
              tpdtlam-=parseInt(ddata.distamount);
            })
          })
          if(tpdtlam!=hdam){
            console.log('tpinvcke',tpdtlam,hdam);
            ElMessage.error({
                message: '明细及发票汇总检查未通过',
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
              });
              return;
          }
          this.tpinvamount=hdam;



          
          


        }

        console.log("invnext",this.makeinvtpdata);
        this.stepsa++;
      },

      invgoodsedtaxcodecg(val){
        console.log("invgoodsedtaxcodecg", val);
        this.makeinvtpdata.goodslist.map((item) => {
          if (item.row_code == this.cellediterow) {
            item.tax_code = val.v_num;
            item.tax_v_code = val.v_name+'[*'+val.t_name+'*]';
            item.tax_code_lvs = val.v_lvs;
          }
        });
        this.cellediterow = "";
        this.celleditecomm = "";
      },

      ivgoodsedit(){
        var checkfa=false;
        if(this.makeinvtpdata.goodsttedit.unit!=''){
          this.makeinvtpdata.selectdata.map((item)=>{
            if(!(item.unit_st.indexOf('|'+this.makeinvtpdata.goodsttedit.unit+'|')>=0)){
              if(this.makeinvtpdata.goodsttedit.price=='' || this.makeinvtpdata.goodsttedit.price==0 ||this.makeinvtpdata.goodsttedit.price==null){
                ElMessage.error({
                  message: "选择的物料明细中存在单位不匹配的明细，需要填写价格用于计算开票数量！！！！！",
                  duration: 2000,
                  center: false,
                });
                checkfa=true;
                return;
              }
            }
          })
        }
        if(checkfa){
          return;
        }
       


        ElMessageBox.confirm(
          "即将修改所选明细的开票信息，是否确认修改？？？"
        )
          .then(() => {
            this.makeinvtpdata.goodslist.map((item)=>{
              for(var i=0;i<this.makeinvtpdata.selectdata.length;i++){
                if(item.row_code==this.makeinvtpdata.selectdata[i].row_code){
                  if(this.makeinvtpdata.goodsttedit.goodsname!=''){
                    item.inv_goods_name=this.makeinvtpdata.goodsttedit.goodsname;
                  }
                  if(this.makeinvtpdata.goodsttedit.goodsspec!=''){
                    item.inv_goods_spec=this.makeinvtpdata.goodsttedit.goodsspec;
                  }
                  if(this.makeinvtpdata.goodsttedit.invtype!=''){
                    item.invtype=this.makeinvtpdata.goodsttedit.invtype;
                  }
                  if(this.makeinvtpdata.goodsttedit.price!='' && this.makeinvtpdata.goodsttedit.price!=0){
                    item.ttprice=this.makeinvtpdata.goodsttedit.price;
                  }
                  if(this.makeinvtpdata.goodsttedit.taxcode!=''){
                    item.tax_code=this.makeinvtpdata.goodsttedit.taxcode;
                    item.tax_v_code=this.makeinvtpdata.goodsttedit.taxcodev;
                    item.tax_code_lvs=this.makeinvtpdata.goodsttedit.taxcodelv;
                  }
                  if(this.makeinvtpdata.goodsttedit.taxlv!=''){
                    item.tax_rait=this.makeinvtpdata.goodsttedit.taxlv;
                  }
                  if(this.makeinvtpdata.goodsttedit.unit!=''){
                    item.inv_unit=this.makeinvtpdata.goodsttedit.unit;
                    item.inv_unit_lv=-1;
                    for(var j=0;j<item.unit_list.length;j++){
                      if(this.makeinvtpdata.goodsttedit.unit==item.unit_list[j]){
                        item.inv_unit_lv=item.unit_lv_list[j];
                        break;
                      }

                    }
                  }

                  break;
                }
              }
            })
  
              
  
           
          })
          .catch(() => {
            console.log("cancel");
          });
        console.log('ivgoodsedit',this.makeinvtpdata);
      },

      ivgoodsreset(){
        ElMessageBox.confirm(
          "即将重置表单，所作更改将全部恢复，是否确认重置？？？"
        )
          .then(() => {
            this.makeinvtpdata.goodslist.map((item)=>{
            item.ttprice=0;
            item.inv_goods_name=item.goods_name;
            item.inv_goods_spec=item.goods_spec;
            if(item.unit_list.length==1){
              item.inv_unit=item.unit_list[0];
              item.inv_unit_lv=item.unit_lv_list[0];
            }else{
              item.inv_unit=item.unit_list[1];
              item.inv_unit_lv=item.unit_lv_list[0];
            }
            item.tax_rait='';
            item.invtype='';
            item.tax_code='';
            item.tax_v_code='';
            item.unit_type=0;

          
            });
  
              
  
           
          })
          .catch(() => {
            console.log("cancel");
          });
        //this.makeinvtpdata.goodsttedit={goodsname:'',goodsspec:'',taxcodeid:'',unit:'',price:0,invtype:'',taxcode:'',taxcodev:'',taxcodelv:'',taxlv:''};
       
        console.log('ivgoodsreset',this.makeinvtpdata);
      },

      savetpinvdata(){
        console.log('savetpincremark');
        this.makeinvtpdata.tpinvdata.map((item)=>{
          if(item.row_code==this.tpinvviewdata.row_code){
            var stt=this.tpinvviewdata.invremark;
            if(stt.length>230){
              stt=stt.substring(0,229);
            }
            item.inv_remark=stt;
            item.tax_email=this.tpinvviewdata.invemail;
          }
        })
        this.tpinvview=false;
      },

      opentpinv(val){
        //this.tpinvviewdata.
        
        this.tpinvviewdata={dtl:[]};
        console.log('sadasd',val);
        if(val.invtype=='026'){
          this.tpinvviewdata.invtypename='电子发票（普通发票）';
        }else if(val.invtype=='028'){
          this.tpinvviewdata.invtypename='电子发票（专用发票）';
        }else{
          this.tpinvviewdata.invtypename='错误的发票类型';
        }
        this.tpinvviewdata.invemail=val.tax_email;
        this.tpinvviewdata.totalam=(val.total_amount/100).toFixed(2);
        this.tpinvviewdata.row_code=val.row_code;
        this.tpinvviewdata.buyername=val.buyer_name;
        this.tpinvviewdata.platename=val.tax_plate_name;
        this.tpinvviewdata.buyertaxnum=val.buyer_taxnum;
        this.tpinvviewdata.invremark=val.inv_remark;
        this.tpinvviewdata.dtl=[];
        var tpgoodsname='';
        var amount=0;
        var ntamout=0;
        var txamount=0;
        var txlv='';
        val.dtl.map((dtldt)=>{
          tpgoodsname=dtldt.taxcode_v.substring(dtldt.taxcode_v.indexOf('[*')+1);
          tpgoodsname=tpgoodsname.substring(0,tpgoodsname.indexOf('*]')+1);
          tpgoodsname=tpgoodsname+dtldt.inv_goods_name;
          amount=dtldt.amount/100;
          if(dtldt.tax_rait==-1 ||dtldt.tax_rait=='-1'){
            txlv='免税';
            ntamout=amount.toFixed(2);
            this.tpinvviewdata.dtl.push({
              goodsname:tpgoodsname,
              goodspec:dtldt.inv_goods_spec,
              unit:dtldt.inv_unit,
              num:(dtldt.num/10000).toFixed(2),
              price:Math.round(dtldt.amount/dtldt.num*100*1000000000000)/1000000000000,
              amount:amount.toFixed(2),
              taxlv:txlv,
              txamount:'***'
            })

            if(dtldt.distamount>0){
              this.tpinvviewdata.dtl.push({
                goodsname:tpgoodsname,
                goodspec:dtldt.inv_goods_spec,
                unit:'',
                num:'',
                price:'',
                amount:-(dtldt.distamount/100).toFixed(2),
                taxlv:txlv,
                txamount:'***'
              })
            }


          }else{
            txlv=(dtldt.tax_rait/10000)+'%';
            ntamout=amount/(1+dtldt.tax_rait/1000000);
            txamount=(ntamout*dtldt.tax_rait/1000000).toFixed(2);
            ntamout=(amount-txamount).toFixed(2);
            this.tpinvviewdata.dtl.push({
              goodsname:tpgoodsname,
              goodspec:dtldt.inv_goods_spec,
              unit:dtldt.inv_unit,
              num:(dtldt.num/10000).toFixed(2),
              price:Math.round(ntamout/(dtldt.num/10000)*1000000000000)/1000000000000,
              amount:ntamout,
              taxlv:txlv,
              txamount:txamount
            })

            if(dtldt.distamount>0){
              amount=dtldt.distamount/100;
              ntamout=amount/(1+dtldt.tax_rait/1000000);
              txamount=(ntamout*dtldt.tax_rait/1000000).toFixed(2);
              ntamout=(amount-txamount).toFixed(2);

              this.tpinvviewdata.dtl.push({
                goodsname:tpgoodsname,
                goodspec:dtldt.inv_goods_spec,
                unit:'',
                num:'',
                price:'',
                amount:-ntamout,
                taxlv:txlv,
                txamount:-txamount
              })
            }


          }



        })


        this.tpinvview=true;
      },


      saveinvoappdata() {
        this.$bus.$emit("showloading");
        var tpdtl=[];
        if(this.thisdata.invos==undefined){
          this.thisdata.invos=[];
        }
        
        this.makeinvtpdata.tpinvdata.map((hdata)=>{
          tpdtl=[];
          hdata.dtl.map((dtldata)=>{
            var frontcode='';

            frontcode=dtldata.taxcode_v.substring(dtldata.taxcode_v.indexOf('[*')+2);
            frontcode=frontcode.substring(0,frontcode.indexOf('*]'));
            tpdtl.push({
              sid:0,
              row_code:'',
              h_row_code:'',
              goodsname:dtldata.inv_goods_name,
              unit:dtldata.inv_unit,
              num:dtldata.num,
              taxlv:dtldata.tax_rait,
              notaxamount:0,
              tax:0,
              tax_code:dtldata.tax_code,
              goodspec:dtldata.inv_goods_spec,
              taxamount:dtldata.amount,
              taxdiscont:dtldata.distamount,
              frontcode:frontcode,
            })
          })
          this.thisdata.invos.push({
            sid :0,
            row_code :uuidv4(),
            h_row_code :'',
            platename:hdata.tax_plate_name,
            interface_code :'',
            buyername :hdata.buyer_name,
            buyertaxnum :hdata.buyer_taxnum,
            buyeraccount :'',
            buyeraddress :'',
            doc_date :0,
            inv_data :0,
            status :-1,
            spid :hdata.tax_plate,
            request :'',
            tax_code :'',
            tax_num :'',
            tax_info :'',
            email :hdata.tax_email,
            url :'',
            old_codenum :'',
            red_infotable :'',
            red_radcode :'',
            total_amount :hdata.total_amount,
            inv_type :hdata.invtype,
            inv_direction :1,
            old_inv_code :'',
            reconciliation_code :'',
            reconciliation_invo_code :'',
            remark :hdata.inv_remark,
            source :2,
            authdepart :'',
            file_pdf :'',
            file_ofd :'',
            file_xml :'',
            invoce_jycode :'',
            isperson :'',
            isadd :'',
            dtl:tpdtl,
          })

        });

        this.mathtotal();
        this.addinvoappvisiable=false;
        console.log('saveinvoappdata',this.thisdata);
        this.$bus.$emit("hideloading");
      },

      
      savepullinvodata() {
        this.$bus.$emit("showloading");
  
        if (this.pullinvoselectdata.length <= 0) {
          this.pullinvovisable = false;
          this.$bus.$emit("hideloading");
          return;
        }
        if(this.thisdata.invos==undefined){
          this.thisdata.invos=[];
        }
        this.pullinvoselectdata.map((item)=>{

          this.thisdata.invos.push(item);

          if(this.thisdata.dellinvs!=undefined){
            for(var i=this.thisdata.dellinvs.length-1;i>=0;i--){
              if(this.thisdata.dellinvs['row_code']==item.row_code){
                this.thisdata.dellinvs.splice(i,1);
                break;
              }

            }
          }

        })
        
        this.mathtotal();
        console.log('savepullinvodata',this.thisdata);
        this.pullinvovisable = false;
        this.$bus.$emit("hideloading");
      },

      downinvfile(val){
        window.open('/api_v3/interface/getfile?file=/upload/' + val, '_self');
      },

      viewinvos(val) {
        console.log(val);
        this.tpinvviewdata={dtl:[]};
        if(val.inv_type=='026'){
          this.tpinvviewdata.invtypename='电子发票（普通发票）';
        }else if(val.inv_type=='028'){
          this.tpinvviewdata.invtypename='电子发票（专用发票）';
        }else{
          this.tpinvviewdata.invtypename='错误的发票类型';
        }
        this.tpinvviewdata.platename =val.platename ;
        this.tpinvviewdata.sid =val.sid ;
        this.tpinvviewdata.row_code =val.row_code ;
        this.tpinvviewdata.h_row_code =val.h_row_code ;
        this.tpinvviewdata.interface_code =val.interface_code ;
        this.tpinvviewdata.buyername =val.buyername ;
        this.tpinvviewdata.buyertaxnum =val.buyertaxnum ;
        this.tpinvviewdata.doc_date =val.doc_date ;
        this.tpinvviewdata.inv_data =val.inv_data ;
        this.tpinvviewdata.status =val.status ;
        this.tpinvviewdata.spid =val.spid ;
        this.tpinvviewdata.request =val.request ;
        this.tpinvviewdata.tax_code =val.tax_code ;
        this.tpinvviewdata.tax_num =val.tax_num ;
        this.tpinvviewdata.tax_info =val.tax_info ;
        this.tpinvviewdata.email =val.email ;
        this.tpinvviewdata.old_codenum =val.old_codenum ;
        this.tpinvviewdata.red_infotable =val.red_infotable ;
        this.tpinvviewdata.red_radcode =val.red_radcode ;
        this.tpinvviewdata.total_amount =(val.total_amount/100) ;
        this.tpinvviewdata.inv_type =val.inv_type ;
        this.tpinvviewdata.inv_direction =val.inv_direction ;
        this.tpinvviewdata.old_inv_code =val.old_inv_code ;
        this.tpinvviewdata.remark =val.remark ;
        this.tpinvviewdata.source =val.source ;
        this.tpinvviewdata.file_pdf =val.file_pdf ;
        this.tpinvviewdata.file_ofd =val.file_ofd ;
        this.tpinvviewdata.file_xml =val.file_xml ;



        var tpgoodsname='';
        var amount=0;
        var ntamout=0;
        var txamount=0;
        var txlv='';
        val.dtl.map((dtldt)=>{
          tpgoodsname='*'+dtldt.frontcode+'*'+dtldt.goodsname;
          amount=(dtldt.taxamount/100);
          if(dtldt.taxlv==-1 ||dtldt.taxlv=='-1'){
            txlv='免税';
            ntamout=amount;
            this.tpinvviewdata.dtl.push({
              goodsname:tpgoodsname,
              goodspec:dtldt.goodspec,
              unit:dtldt.unit,
              num:(dtldt.num/10000),
              price:Math.round(dtldt.taxamount/dtldt.num*100*1000000000000)/1000000000000,
              amount:(amount),
              taxlv:txlv,
              txamount:'***'
            })

            if(dtldt.taxdiscont>0){
              this.tpinvviewdata.dtl.push({
                goodsname:tpgoodsname,
                goodspec:dtldt.goodspec,
                unit:'',
                num:'',
                price:'',
                amount:-(dtldt.taxdiscont/100),
                taxlv:txlv,
                txamount:'***'
              })
            }


          }else{
            txlv=(dtldt.taxlv/10000)+'%';
            ntamout=amount/(1+dtldt.taxlv/1000000);
            txamount=Math.round((ntamout*dtldt.taxlv/1000000)*100)/100;
            ntamout=Math.round((amount-txamount)*100)/100;
            this.tpinvviewdata.dtl.push({
              goodsname:tpgoodsname,
              goodspec:dtldt.goodspec,
              unit:dtldt.unit,
              num:(dtldt.num/10000),
              price:Math.round(ntamout/(dtldt.num/10000)*1000000000000)/1000000000000,
              amount:(ntamout),
              taxlv:txlv,
              txamount:(txamount)
            })

            if(dtldt.taxdiscont>0){
              amount=dtldt.taxdiscont/100;
              ntamout=amount/(1+dtldt.taxlv/1000000);
              txamount=Math.round((ntamout*dtldt.taxlv/1000000)*100)/100;
              ntamout=Math.round((amount-txamount)*100)/100;

              this.tpinvviewdata.dtl.push({
                goodsname:tpgoodsname,
                goodspec:dtldt.goodspec,
                unit:'',
                num:'',
                price:'',
                amount:(-ntamout),
                taxlv:txlv,
                txamount:(-txamount)
              })
            }


          }



        })

        this.viewinvodata = val;
        this.viewinvovisable = true;

        
      },


      dopullinvosearch() {
        this.$refs.pullinvotb.clearSelection();
        axios
          .post("/" + this.dialoginfo.modlecode + "/getinvocelist", {
            data: {
              data: this.pullinvosearch,
              havselect: this.thisdata.invos,
            },
          })
          .then((request) => {
            this.pullinvodata = request;
          })
          .catch(() => {});
      },


      dellinvo(val) {
        this.$bus.$emit("showloading");
        if(this.thisdata.dellinvs==undefined){
          this.thisdata.dellinvs=[];
        }
        if(val.sid!=0){
          this.thisdata.dellinvs.push(val);
        }
        for(var i=this.thisdata.invos.length-1;i>=0;i--){
          if(this.thisdata.invos[i].row_code==val.row_code){
            this.thisdata.invos.splice(i,1);
            break;
          }
        }

        this.mathtotal();
        this.$bus.$emit("hideloading");  
      },

      savedata() {
        if(this.thisdata.hdatas.length==1){
          this.thisdata.hdatas[0].remark=this.thisdata.remark;
        }else{
          this.thisdata.hdatas.map((itme)=>{
            if(itme.sid==0){
              itme.remark=this.thisdata.remark;
            }
          })
        }
        this.thisdata.beforinv.map((item)=>{
          if(item.bill_dtl_code==''){
            item.h_row_code=this.thisdata.hdatas[0]['row_code'];
          }
        })
        
        this.thisdata.frees.map((item)=>{
          if(item.bill_dtl_code==''){
            item.h_row_code=this.thisdata.hdatas[0]['row_code'];
          }
        })
        console.log("savedata", this.thisdata);
        this.$bus.$emit("showloading");
        axios
          .post(
            "/" + this.dialoginfo.modlecode + "/savedata",
            {
              data: this.thisdata,
            },
          )
          .then((req) => {
            console.log("savedata_req",req)
                this.$emit("changedata", {});
                this.visiable = false;
                this.$bus.$emit("hideloading");
          })
          .catch(() => {
                 this.$bus.$emit("hideloading");
           });

      },

      dellgroup(){
        ElMessageBox.confirm(
          "即将解除本申请单内所有子单以及发票的绑定关系，未保存的操作也将放弃；本操作不可撤销，是否确定！！！！！",
          "警告",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          axios
            .post("/" + this.dialoginfo.modlecode + "/deletgroup", { data: this.thisdata })
            .then(() => {
              this.$emit("changedata", {});
              this.visiable = false;

              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        });
      },


      checkdata() {
        ElMessageBox.confirm(
          "确认后，本单据将不可修改，同时发票将提交开票；本操作不可撤销，是否确定！！！！！",
          "警告",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          axios
          .post(
            "/" + this.dialoginfo.modlecode + "/savedata",
            {
              data: this.thisdata,
            },
          )
          .then((req) => {
              console.log("savedata_req",req)
              if(req.status=='ok'){
                axios
          .post(
            "/" + this.dialoginfo.modlecode + "/checkdata",
            {
              data: req,
            },
          )
          .then(() => {
           
                this.$emit("changedata", {});
                this.visiable = false;
                this.$bus.$emit("hideloading");
          })
          .catch(() => {
                 this.$bus.$emit("hideloading");
           });
              }
                
          })
          .catch(() => {
                 this.$bus.$emit("hideloading");
           });
        });
      },










        //清理程序




      
  

  
      uploadfile(file, files) {
        console.log("uploadfile", { file: file, files: files });
      },
      taildiffcg(val) {
        console.log(val);
        clearTimeout(this._uptaildi);
        this._uptaildi = setTimeout(() => {
          axios
            .post("/" + this.dialoginfo.modlecode + "/uptaildiff", {
              data: { row_code: this.thisdata.row_code, taildiff: val },
            })
            .then(() => {
              this.remathdata();
            });
        }, 600);
      },
      editwcdd(val) {
        var numReg = /^[0-9]{0,1}$/;
        var numRe = new RegExp(numReg);
        if (!numRe.test(val)) {
          ElMessage.error({
            message: "尾差调整范围为-0.99至0.99，请勿超出范围！！！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          this.editstring = 0;
        }
      },
      cleaninvosdata() {
        ElMessageBox.confirm("是否确认清空发票数据？", "警告！！")
          .then(() => {
            // this.thisdata.invos = [];
            // this.thisdata.totalinvoamountnum = 0;
            // this.thisdata.totalinvoamount = this.finallnum(
            //   this.thisdata.totalinvoamountnum / 100
            // );
            this.$bus.$emit("showloading");
            this.$bus.$emit("showloading");
            this.$bus.$emit("showloading");
  
            axios
              .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
                data: { type: "get" },
              })
              .then((response) => {
                console.log("response", response);
                axios
                  .post(
                    "/" + this.dialoginfo.modlecode + "/deletinvos",
                    {
                      data: {
                        invos: this.thisdata.invos,
                        row_code: this.thisdata.row_code,
                        runopuu: response,
                      },
                    },
                    { timeout: 15000 }
                  )
                  .then(() => {})
                  .catch(() => {
                    // this.$bus.$emit("hideloading");
                  });
  
                this.$bus.$emit("showloading");
                this._getopstatus = setTimeout(() => {
                  this.checkopstatus(response, "invosave");
                }, 2000);
              })
              .catch(() => {
                this.$bus.$emit("hideloading");
              });
          })
          .catch(() => {});
      },
      addpullinvotableselechange(val) {
        console.log("addpullinvotableselechange", val);
        this.pullinvoselectdata = val;
      },
      clearpullinvo() {
        this.pullinvodata = [];
        this.pullinvosearch = {
          salerid: "",
          salercode: "",
          buyercode: "",
          taxcode: "",
          remark: "",
        };
      },
      saveeditgoodsdata() {
        this.$bus.$emit("showloading");
        var stt = "|";
        this.addappsdtlsselect.map((val) => {
          stt += val.row_code + "|";
        });
        this.invoappsedit.dtls.map((val, index) => {
          if (stt.indexOf(val.row_code) > 0) {
            if (this.editgoodsinfo.name != "") {
              this.invoappsedit.dtls[index].ivocegoodsname =
                this.editgoodsinfo.name;
            }
            this.invoappsedit.dtls[index].ivocev_spec = this.editgoodsinfo.spc;
          }
        });
        this.editgoodsvisiable = false;
        this.$bus.$emit("hideloading");
      },
      cleareditgoodsdata() {
        this.editgoodsinfo = { name: "", spc: "" };
      },
      onetotwo() {
        this.$bus.$emit("showloading");
        console.log("start hebing");
        var uuidstr = "";
        var taxlist = [];
        var uuidlist = [];
        var adddata = [];
        var nowtaxnum = 0;
        var outamount = 0;
        var outfree = 0;
        var delthead = [];
        var needdelete = true;
        this.invoappsedit.head.map((val, xii) => {
          nowtaxnum = -1;
          outamount = 0;
          outfree = 0;
          this.addappsdtlsselect.map((value) => {
            if (value.h_row_code == val.row_code) {
              taxlist.map((value2, index) => {
                if (value2 == value.tax_rait) {
                  nowtaxnum = index;
                }
              });
  
              if (nowtaxnum < 0) {
                console.log("add data");
                uuidstr = uuidv4().replace(/-/g, "");
                taxlist.push(value.tax_rait);
                uuidlist.push(uuidstr);
                nowtaxnum = taxlist.length - 1;
                adddata.push({
                  sid: "",
                  row_code: uuidstr,
                  remark: "",
                  totalamount: 0,
                  totalfree: 0,
                  taxlv: value.tax_rait,
                  invotype: "000",
                  email: "",
                  ptcode: "",
                  pt_inv_name: "",
                  pt_inv_taxnum: "",
                  pt_inv_address: "",
                  pt_inv_account: "",
                  buyer_inv_name: "",
                  buyer_inv_taxnum: "",
                  buyer_inv_address: "",
                  buyer_inv_account: "",
                });
              }
  
              this.invoappsedit.dtls.map((dtl_value, index) => {
                if (dtl_value.row_code == value.row_code) {
                  this.invoappsedit.dtls[index].h_row_code = uuidlist[nowtaxnum];
                  return;
                }
              });
  
              outamount += parseInt(value.reconciliation_amount);
              if (outamount >= val.totalamount) {
                delthead.push(val.row_code);
              }
              adddata[nowtaxnum].totalamount += parseInt(
                value.reconciliation_amount
              );
              if (
                val.totalfree - outfree >
                Math.round(
                  (value.reconciliation_amount * val.totalfree) / val.totalamount
                )
              ) {
                adddata[nowtaxnum].totalfree += Math.round(
                  (value.reconciliation_amount * val.totalfree) / val.totalamount
                );
                outfree += Math.round(
                  (value.reconciliation_amount * val.totalfree) / val.totalamount
                );
              } else {
                adddata[nowtaxnum].totalfree += val.totalfree - outfree;
                outfree += val.totalfree - outfree;
              }
            }
          });
          this.invoappsedit.head[xii].totalfree -= outfree;
          this.invoappsedit.head[xii].totalamount -= outamount;
        });
        for (var i = this.invoappsedit.head.length; i > 0; i--) {
          needdelete = true;
          this.invoappsedit.dtls.map((val) => {
            if (val.h_row_code == this.invoappsedit.head[i - 1].row_code) {
              needdelete = false;
              return;
            }
          });
          if (needdelete) {
            this.invoappsedit.head.splice(i - 1, 1);
          }
        }
        adddata.map((val) => {
          this.invoappsedit.head.push(val);
        });
        this.flushinvoappdt();
        console.log("hb ende", {
          adddata: adddata,
          delthead: delthead,
          dtl: this.invoappsedit.dtls,
        });
        this.$bus.$emit("hideloading");
      },
      resetattcode() {
        this.appserchdatax = { num: "", buyername: "", goods: "" };
        this.appserchdata = this.appserchdatax;
        this.flushinvoappdt();
      },
      attcodeserch() {
        this.appserchdatax = this.appserchdata;
        this.flushinvoappdt();
      },
      minamcc(val) {
        var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
        var numRe = new RegExp(numReg);
        if (numRe.test(val)) {
          this.addbillsearchdata.minamount = val;
        } else {
          this.minamount = this.addbillsearchdata.minamount;
        }
      },
      maxamcc(val) {
        var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
        var numRe = new RegExp(numReg);
        if (numRe.test(val)) {
          this.addbillsearchdata.maxamount = val;
        } else {
          this.maxamount = this.addbillsearchdata.maxamount;
        }
      },
      numbercgxx(val) {
        var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
        var numRe = new RegExp(numReg);
        var totalfreetp = 0;
        if (numRe.test(val)) {
          console.log("ckpass", val);
          this.invoappsedit.head.map((value, index) => {
            if (value.row_code == this.cellediterow) {
              this.invoappsedit.head[index].totalfree = val * 100;
            }
            totalfreetp += this.invoappsedit.head[index].totalfree;
          });
          this.invoapptotalfree = totalfreetp;
          this.cellediterow = "";
          this.celleditecomm = "";
        } else {
          this.invoappsedit.head.map((value, index) => {
            if (value.row_code == this.cellediterow) {
              this.editetotalfree = this.invoappsedit.head[index].totalfree / 100;
            }
          });
        }
  
        console.log(val);
      },
      invoapptabClick(row, column) {
        this.cellediterow = row.row_code;
        this.celleditecomm = column.property;
        this.editetotalfree = row.totalfree / 100;
      },
      addinvoappdtltableselechange(val) {
        this.addappsdtlsselect = val;
      },
      involvtypecg1(val) {
        if (val !== "") {
          this.invoappsedit.head.map((value, index) => {
            if (value.row_code == this.cellediterow) {
              this.invoappsedit.head[index].taxlv = val;
            }
          });
          this.invoappsedit.dtls.map((value, index) => {
            if (value.h_row_code == this.cellediterow) {
              this.invoappsedit.dtls[index].tax_rait = val;
            }
          });
        }
        this.involv = "";
        this.cellediterow = "";
        this.celleditecomm = "";
      },
      edtaxcodecg2(val) {
        console.log(val);
        this.invoappsedit.head.map((value, index) => {
          if (value.row_code == this.cellediterow) {
            this.invoappsedit.head[index].taxcode = val.v_num;
            this.invoappsedit.head[index].taxcodetext = val.v_name;
          }
        });
      },
      invotypecg2(val) {
        this.invoappsedit.head.map((value, index) => {
          if (value.row_code == this.cellediterow) {
            this.invoappsedit.head[index].invotype = val;
          }
        });
        this.invotype2 = "";
        this.cellediterow = "";
        this.celleditecomm = "";
      },
  
      invotypecg1(val) {
        if (val.value != "") {
          this.invoappsedit.head.map((value, index) => {
            this.addappsheadselect.map((value2) => {
              if (value.row_code == value2.row_code) {
                this.invoappsedit.head[index].invotype = val;
              }
            });
          });
        }
        this.invotype = "";
      },
      saveeditinvoinfodata() {
        this.$bus.$emit("showloading");
        axios
          .post("/" + this.dialoginfo.modlecode + "/updateinvobuyer", {
            data: {
              data: this.editinvoinfo,
            },
          })
          .then(() => {
            this.invoappsedit.head.map((value, index) => {
              this.addappsheadselect.map((value2) => {
                if (value.row_code == value2.row_code) {
                  this.invoappsedit.head[index].ptcode =
                    this.editinvoinfo.pttcode;
                  this.invoappsedit.head[index].pt_inv_name =
                    this.editinvoinfo.pttname;
                  this.invoappsedit.head[index].pt_inv_taxnum =
                    this.editinvoinfo.ptttax;
                  this.invoappsedit.head[index].buyer_inv_name =
                    this.editinvoinfo.burername;
                  this.invoappsedit.head[index].buyer_inv_taxnum =
                    this.editinvoinfo.burertax;
                  this.invoappsedit.head[index].buyer_inv_address =
                    this.editinvoinfo.bureradd;
                  this.invoappsedit.head[index].buyer_inv_account =
                    this.editinvoinfo.bureracc;
                  this.invoappsedit.head[index].remark = this.editinvoinfo.remark;
                  this.invoappsedit.head[index].email = this.editinvoinfo.email;
                }
              });
            });
            this.editinvoinfovisiable = false;
          })
          .catch(() => {});
  
        this.$bus.$emit("hideloading");
      },
      cleareditinvoinfodata() {
        this.editinvoinfo = {
          edtaxplt: "",
          pttname: "",
          pttcode: "",
          ptttax: "",
          burercode: "",
          burername: "",
          burertax: "",
          bureradd: "",
          bureracc: "",
          remark: "",
          number: 0,
          email: "",
        };
      },
      contantcg(val) {
        console.log("cntantcg", val);
        //this.editinvoinfo.email = val;
      },
      cleaninvodata(val){
        console.log("cleaninvodata", val);
        if(this.tax_select!=''){
            if(val==1){
                this.tax_select='';
                this.tax_num='';
            }
            if(val==2){
                this.tax_select='';
                this.tax_name='';
            }
        }
      },
      burercodecg(val) {
        console.log("sdsdsd", val);
        // editinvoinfo: { edtaxplt: '', pttname: '', pttcode: '', ptttax: '', burercode: '', burername: '', burertax: '', bureradd: '', bureracc: '', remark: '', number: 0, atremark: "" },
        //this.editinvoinfo.burercode = val.row_code;
        //this.editinvoinfo.burername = val.v_name;
        //this.editinvoinfo.burertax = val.taxnum;
        //this.editinvoinfo.bureradd = val.address;
        //this.editinvoinfo.bureracc = val.account;
      },
      edtaxpltcg(val) {
        console.log("aaaa", { val: val, endata: this.editinvoinfo });
        // editinvoinfo:{edtaxplt:'',pttname:'',pttcode:'',ptttax:'',
        this.editinvoinfo.pttname = val.platename;
        this.editinvoinfo.pttcode = val.row_code;
        this.editinvoinfo.edtaxplt = val.row_code;
        this.editinvoinfo.ptttax = val.platetaxnum;
      },
      edtaxpltcg2(val) {
        console.log("aaaa", val);
        if (val == null) {
          this.pullinvosearch.salercode = "";
          this.pullinvosearch.salerid = "";
        } else {
          this.pullinvosearch.salercode = val.plateid;
          this.pullinvosearch.salerid = val.row_code;
        }
      },
      addinvoapptableselechange(val) {
        this.addappsheadselect = val;
        this.flushinvoappdt();
      },
      flushinvoappdt() {
        var rowstring = "||";
        this.addappsheadselect.map((value) => {
          rowstring += value.row_code + "|";
        });
        this.invoappseditviewdtl = [];
        this.invoappsedit.dtls.map((value) => {
          // this.appserchdatax = { num: "", buyername: "", goods: "" };
          if (
            rowstring.indexOf(value.h_row_code) > 0 &&
            (value.single_num.indexOf(this.appserchdatax.num) >= 0 ||
              value.buyer_order_num.indexOf(this.appserchdatax.num) >= 0) &&
            value.buyername.indexOf(this.appserchdatax.buyername) >= 0 &&
            value.goodsname.indexOf(this.appserchdatax.goods) >= 0
          ) {
            this.invoappseditviewdtl.push(value);
          }
        });
      },
      upremark() {
        axios
          .post(
            "/" + this.dialoginfo.modlecode + "/upremark",
            {
              data: {
                row_code: this.thisdata.row_code,
                remark: this.thisdata.remark,
              },
            },
            { timeout: 15000 }
          )
          .then(() => {})
          .catch(() => {
            // this.$bus.$emit("hideloading");
          });
      },
      ceckmarkcech() {
        if (this.ceckcodeinput == this.ceckcode) {
          if (this.checkrout == 1) {
            axios
              .post(
                "/" + this.dialoginfo.modlecode + "/ceckdata",
                {
                  data: {
                    row_code: this.thisdata.row_code,
                  },
                },
                { timeout: 15000 }
              )
              .then(() => {
                this.checkmark = false;
                this.visiable = false;
                this.$emit("changedata", {});
              })
              .catch(() => {
                // this.$bus.$emit("hideloading");
              });
          }
        } else {
          var ceckcode = "100" + Math.round(Math.random() * 10000000);
          console.log(ceckcode);
          ceckcode = ceckcode.substring(5, 9);
  
          this.ceckcode = ceckcode;
          this.ceckcodeinput = "";
        }
      },
  
      addinvoappopen() {
        this.$bus.$emit("showloading");
  
        this.cellediterow = "";
        this.celleditecomm = "";
        this.invoappsedit.dtls = JSON.parse(JSON.stringify(this.thisdata.bills));
        console.log("开始处理", this.invoappsedit.dtls);
        var taildiff = Number(this.thisdata.taildiff) * 100;
        for (var i = 0; i < this.invoappsedit.dtls.length; i++) {
          if (taildiff > 0) {
            this.invoappsedit.dtls[i].reconciliation_amount =
              Number(this.invoappsedit.dtls[i].reconciliation_amount) + taildiff;
            this.invoappsedit.dtls[i].sale_amount =
              Number(this.invoappsedit.dtls[i].sale_amount) + taildiff;
            taildiff = 0;
          } else {
            if (Number(this.invoappsedit.dtls[i].sale_amount) + taildiff > 0) {
              this.invoappsedit.dtls[i].reconciliation_amount =
                Number(this.invoappsedit.dtls[i].reconciliation_amount) +
                taildiff;
              this.invoappsedit.dtls[i].sale_amount =
                Number(this.invoappsedit.dtls[i].sale_amount) + taildiff;
              taildiff = 0;
            } else {
              this.invoappsedit.dtls[i].sale_amount = 0;
              this.invoappsedit.dtls[i].reconciliation_amount = 0;
              taildiff += Number(this.invoappsedit.dtls[i].sale_amount);
            }
          }
          if (taildiff == 0) {
            break;
          }
        }
        this.invoappsedit.head = [];
        var freelv = 0;
        if (this.thisdata.totalfreenun != 0) {
          freelv =
            parseInt(this.thisdata.totalfreenun) /
            parseInt(this.thisdata.totalbillnum);
        }
        var taxlvls = "|";
        var taxuuids = Array();
        var billamount = Array();
        var uuidstr = "";
  
        this.invoappsedit.dtls.map((val, index) => {
          this.invoappsedit.dtls[index].ivocegoodsname =
            this.invoappsedit.dtls[index].goodsname;
          this.invoappsedit.dtls[index].ivocev_spec =
            this.invoappsedit.dtls[index].v_spec;
          if (val.reconciliation_amount == 0) {
            return;
          }
          if (taxlvls.indexOf("|" + val.tax_rait + "|") >= 0) {
            //税率已存在
            this.invoappsedit.dtls[index].h_row_code =
              taxuuids["|" + val.tax_rait + "|"];
            billamount["|" + val.tax_rait + "|"] += parseInt(
              val.reconciliation_amount
            );
          } else {
            //税率未存在
            taxlvls += val.tax_rait + "|";
            uuidstr = uuidv4().replace(/-/g, "");
            taxuuids["|" + val.tax_rait + "|"] = uuidstr;
            billamount["|" + val.tax_rait + "|"] = parseInt(
              val.reconciliation_amount
            );
            this.invoappsedit.head.push({
              sid: "",
              row_code: uuidstr,
              remark: "",
              totalamount: 0,
              totalfree: 0,
              taxlv: val.tax_rait,
              invotype: "000",
              email: "",
              ptcode: "",
              pt_inv_name: "",
              pt_inv_taxnum: "",
              pt_inv_address: "",
              pt_inv_account: "",
              buyer_inv_name: "",
              buyer_inv_taxnum: "",
              buyer_inv_address: "",
              buyer_inv_account: "",
            });
            this.invoappsedit.dtls[index].h_row_code = uuidstr;
          }
        });
        var totalfreetp = 0;
        console.log("end cl", { a: this.invoappsedit, b: freelv });
        this.invoappsedit.head.map((val, index) => {
          this.invoappsedit.head[index].totalamount =
            billamount["|" + val.taxlv + "|"];
          this.invoappsedit.head[index].totalfree = Math.round(
            parseInt(billamount["|" + val.taxlv + "|"]) * freelv
          );
          totalfreetp += this.invoappsedit.head[index].totalfree;
        });
  
        if (totalfreetp != this.thisdata.totalfreenun) {
          var cdd = 0;
          var opn = 0;
          if (totalfreetp < this.thisdata.totalfreenun) {
            cdd = this.thisdata.totalfreenun - totalfreetp;
            opn = 1;
          } else {
            cdd = totalfreetp - this.thisdata.totalfreenun;
            opn = -1;
          }
          this.invoappsedit.head.map((value, index) => {
            if (cdd > 0) {
              this.invoappsedit.head[index].totalfree += opn;
              cdd--;
            }
          });
        }
        this.invoapptotalfree = totalfreetp;
  
        console.log("editapps", this.invoappsedit);
  
        // this.invoappsedit.head = [{
        //   row_code: this.thisdata.row_code,
        //   remark: '待处理数据',
        //   totalamount: this.thisdata.totalbillnum,
        //   totalfree: this.thisdata.totalfreenun,
        //   taxlv: '',
        //   invotype: '000',
        //   email: '',
        //   ptcode: '',
        //   pt_inv_name: "",
        //   pt_inv_taxnum: "",
        //   pt_inv_address: "",
        //   pt_inv_account: "",
        //   buyer_inv_name: "",
        //   buyer_inv_taxnum: "",
        //   buyer_inv_address: "",
        //   buyer_inv_account: "",
  
        // }]
  
        this.addinvoappvisiable = true;
  
        this.$bus.$emit("hideloading");
      },
      clearaddadfreedata() {
        this.addadfreesdata = {
          discount: "",
          v_name: "",
          time: "",
          remark: "",
          number: 0,
          atremark: "",
        };
      },

      billsselechange(val) {
        this.billsseleed = val;
      },
      freesselechange(val) {
        this.freesseleed = val;
      },

      addbillscontactschange(val){
        
        this.addbillsearchdata.contact = val;
      },
      addfreeswarehousecg(val) {
        this.addfreesearchdata.warehouse = [];
        for (var i = 0; i < val.length; i++) {
          this.addfreesearchdata.warehouse.push(val[i].row_code);
        }
      },
      addbillswarehousecg(val) {
        this.addbillsearchdata.warehouse = [];
        for (var i = 0; i < val.length; i++) {
          this.addbillsearchdata.warehouse.push(val[i].row_code);
        }
      },
      addfreesdepartmentcg(val) {
        this.addfreesearchdata.department = [];
        for (var i = 0; i < val.length; i++) {
          this.addfreesearchdata.department.push(val[i].value);
        }
      },
      addbillsdepartmentcg(val) {
        this.addbillsearchdata.department = [];
        for (var i = 0; i < val.length; i++) {
          this.addbillsearchdata.department.push(val[i].value);
        }
        //console.log(this.addbillsearchdata.department);
      },
      addfreesdataareachange_doctimes(val) {
        if (val != null) {
          this.addfreesearchdata.doctimes[0] = val[0].getTime();
          this.addfreesearchdata.doctimes[1] = val[1].getTime() + 86399999;
        } else {
          this.addfreesearchdata.doctimes = ["", ""];
        }
      },
      addbillsdataareachange_doctimes(val) {
        if (val != null) {
          this.addbillsearchdata.doctimes[0] = val[0].getTime();
          this.addbillsearchdata.doctimes[1] = val[1].getTime() + 86399999;
        } else {
          this.addbillsearchdata.doctimes = ["", ""];
        }
      },
      addfreesdataareachange_posttimes(val) {
        if (val != null) {
          this.addfreesearchdata.posttimes[0] = val[0].getTime();
          this.addfreesearchdata.posttimes[1] = val[1].getTime() + 86399999;
        } else {
          this.addfreesearchdata.posttimes = ["", ""];
        }
      },
      addbullsdataareachange_posttimes(val) {
        if (val != null) {
          this.addbillsearchdata.posttimes[0] = val[0].getTime();
          this.addbillsearchdata.posttimes[1] = val[1].getTime() + 86399999;
        } else {
          this.addbillsearchdata.posttimes = ["", ""];
        }
      },
      addbillsviewsele() {
        this.addbilltype = 2;
        this.addbillsflashdata();
      },

      resetfreesearch() {
        this.addfreetype = 1;
        this.addfreesearchdata = {
          doctimes: ["", ""],
          posttimes: ["", ""],
          docnum: "",
          department: [],
          buyerinfor: [],
          contacts: [],
          warehouse: [],
        };
  
        this.addfreesflashdata();
      },
      
      addfreeshandleSizeChange(val) {
        let pgs = Math.ceil(this.addfreestotalrow / val);
        this.addfreespagesize = val;
        if (pgs >= this.addfreespagenow) {
          this.addfreesflashdata();
        }
      },
      addfreeshandleCurrentChange(val) {
        this.addfreespagenow = val;
        this.addfreesflashdata();
      },


      remathdata() {
        //重算合计数
        this.thisdata.totalbill = this.finallnum(
          (this.thisdata.totalbillnum + this.thisdata.taildiff * 100) / 100
        );
        this.thisdata.totalfree = this.finallnum(
          this.thisdata.totalfreenun / 100
        );
        this.thisdata.totalinvo = this.finallnum(
          (this.thisdata.totalbillnum -
            this.thisdata.totalfreenun +
            this.thisdata.taildiff * 100) /
            100
        );
        if (this.thisdata.totalbillnum != 0) {
          this.thisdata.totalinvolv =
            this.finallnum(
              (this.thisdata.totalfreenun /
                (this.thisdata.totalbillnum + this.thisdata.taildiff * 100)) *
                100
            ) + "%";
        } else {
          this.thisdata.totalinvolv = "0.00%";
        }
  
        this.thisdata.totalinvoapp = this.finallnum(
          this.thisdata.totalinvoappnum / 100
        );
        this.thisdata.totalinvoamount = this.finallnum(
          this.thisdata.totalinvoamountnum / 100
        );
      },
      finallnum(val) {
        if (val == "" || val == null || val == 0) {
          return "0.00";
        }
        var num = val;
        num = num.toFixed(2);
        var fh = "";
        if (num.substring(0, 1) == "-") {
          fh = "-";
          num = num.substring(1);
        }
        var cutnum = 0;
        while (num.length > 6) {
          if (num.length % 3 != 0) {
            cutnum = num.length % 3;
            fh += num.substring(0, cutnum);
            fh += ",";
            num = num.substring(cutnum);
          } else {
            fh += num.substring(0, 3);
            fh += ",";
            num = num.substring(3);
          }
        }
        fh += num;
        return fh;
      },




      invoappview(val) {
        console.log("test", val);
      },
      dellbills() {
        this.$bus.$emit("showloading");
        this.$bus.$emit("showloading");
        this.$bus.$emit("showloading");
  
        if (this.billsseleed.length <= 0) {
          this.$bus.$emit("hideloading");
          this.$bus.$emit("hideloading");
          this.$bus.$emit("hideloading");
          this.$bus.$emit("hideloading");
          return;
        }
  
        axios
          .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
            data: { type: "get" },
          })
          .then((response) => {
            console.log("response", response);
            axios
              .post(
                "/" + this.dialoginfo.modlecode + "/deletbills",
                {
                  data: {
                    bills: this.billsseleed,
                    row_code: this.thisdata.row_code,
                    runopuu: response,
                  },
                },
                { timeout: 15000 }
              )
              .then(() => {})
              .catch(() => {
                // this.$bus.$emit("hideloading");
              });
  
            this.$bus.$emit("showloading");
            this._getopstatus = setTimeout(() => {
              this.checkopstatus(response, "billsave");
            }, 2000);
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },
      dellfrees() {
        this.$bus.$emit("showloading");
        this.$bus.$emit("showloading");
        this.$bus.$emit("showloading");
  
        if (this.freesseleed.length <= 0) {
          this.$bus.$emit("hideloading");
          this.$bus.$emit("hideloading");
          this.$bus.$emit("hideloading");
          this.$bus.$emit("hideloading");
          return;
        }
  
        axios
          .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
            data: { type: "get" },
          })
          .then((response) => {
            console.log("response", response);
            axios
              .post(
                "/" + this.dialoginfo.modlecode + "/deletfrees",
                {
                  data: {
                    frees: this.freesseleed,
                    row_code: this.thisdata.row_code,
                    runopuu: response,
                  },
                },
                { timeout: 15000 }
              )
              .then(() => {})
              .catch(() => {
                // this.$bus.$emit("hideloading");
              });
  
            this.$bus.$emit("showloading");
            this._getopstatus = setTimeout(() => {
              this.checkopstatus(response, "freesave");
            }, 2000);
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },
      test() {
        console.log("test");
      },

  

      freetypeformatter(row, column, cellvalue) {
        if (cellvalue == 1) {
          return "票面折扣";
        }
        if (cellvalue == 2) {
          return "票外折扣";
        }
        return "数据错误";
      },
  
      getmounth(Value) {
        var data = new Date();
        if (Value != "") {
          data = new Date(parseInt(Value));
        }
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        console.log("getmounth", dateNumFun(data.getMonth() + 1));
        return dateNumFun(data.getMonth() + 1);
      },
  
      dateformart2(Value) {
        //console.log("datetimeformart", Value);
        if (Value == 0) {
          return "";
        }
        const date = new Date(parseInt(Value));
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        const [Y, M, D] = [
          date.getFullYear(),
          dateNumFun(date.getMonth() + 1),
          dateNumFun(date.getDate()),
        ];
        var rtdate = `${Y}-${M}-${D}`;
        return rtdate;
      },
  
      dateformart(a, b, Value) {
        //if(a==null && b==null){console.log('')}
        if (Value == 0) {
          return "";
        }
        const date = new Date(parseInt(Value));
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        const [Y, M, D] = [
          date.getFullYear(),
          dateNumFun(date.getMonth() + 1),
          dateNumFun(date.getDate()),
        ];
        
        var rtdate = `${Y}-${M}-${D}`;
        return rtdate;
      },
      // deldata(val) {
      //     // if (val.sid > 0) {
      //     //     this.thisdata.deletdtl.push(val);
      //     // }
      //     // for (var i = 0; i < this.thisdata.dtl.length; i++) {
      //     //     if (this.thisdata.dtl[i].row_code == val.row_code) {
      //     //         this.thisdata.dtl.splice(i, 1)
      //     //         return
      //     //     }
      //     // }
      // },
  
      dtlsave() {
        this.$bus.$emit("showloading");
  
        this.$bus.$emit("hideloading");
      },
  
      tableheadClass() {
        return "text-align:center";
      },
      
      cleardata() {
        //this.thisdata = {};
        this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
          "v_name",
        ]);
        console.log("closedialog");
        this.$emit("dialogclose", {});
      },
      checkopstatus(val, val2) {
        axios
          .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
            data: { type: "search", row_code: val },
          })
          .then((response) => {
            console.log("opstatus", response);
            if (response.status == "1") {
              this._getopstatus = setTimeout(() => {
                this.checkopstatus(val, val2);
              }, 2000);
            }
            if (response.status == "3") {
              console.log("asdkjwn", val2);
              var dtt;
              if (val2 == "billsave") {
                dtt = JSON.parse(response.log);
                console.log("cackt optt", dtt);
                this.thisdata.totalbillnum = dtt.totalamount;
                this.thisdata.bills = dtt.rows;
                this.remathdata();
                this.addbillvisiable = false;
              }
              if (val2 == "freesave") {
                dtt = JSON.parse(response.log);
                console.log("cackt optt", dtt);
                this.thisdata.totalfreenun = dtt.totalamount;
                this.thisdata.frees = dtt.rows;
                this.remathdata();
                this.addfreevisiable = false;
                this.addadfreevisiable = false;
              }
  
              if (val2 == "invoappsave") {
                dtt = JSON.parse(response.log);
                this.thisdata.totalinvoappnum = dtt.apptotalamount;
                this.thisdata.invoapps = dtt.approws;
                this.thisdata.invos = dtt.rows;
                this.thisdata.totalinvoamountnum = dtt.totalamount;
                this.remathdata();
                this.addinvoappvisiable = false;
              }
  
              if (val2 == "invosave") {
                dtt = JSON.parse(response.log);
                this.thisdata.invos = dtt.rows;
                this.thisdata.totalinvoamountnum = dtt.totalamount;
                this.remathdata();
                this.pullinvovisable = false;
              }
              this.$bus.$emit("hideloading");
              this.$bus.$emit("hideloading");
              this.$bus.$emit("hideloading");
              this.$bus.$emit("hideloading");
              this.$bus.$emit("hideloading");
              this.$bus.$emit("hideloading");
            }
            if (response.status == "2") {
              ElMessage.error({
                message: response.log,
                duration: 2000,
              });
              this.$bus.$emit("hideloading");
            }
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },

    },
  };
  </script>
  <style scoped>
  :deep(.el-transfer-panel) {
    width: 300px;
  }
  
  :deep(.el-table .cell) {
    white-space: pre-line;
  }
  
  :deep(.invoviewtool) {
    width: 180px;
    display: inline-block;
    padding: 0px;
    margin-bottom: 8px;
    text-align: right;
  }
  
  :deep(.invoviewdata) {
    width: 340px;
    display: inline-block;
    padding: 0px;
    margin-bottom: 8px;
  }
  
  :deep(.myadditem .el-form-item__label) {
    font-weight: 700;
    padding: 0px;
    margin: 0px;
  }
  </style>
    