<template>
  <el-dialog
    :ref="dialoginfo.modlecode + 'dialog'"
    :title="dialoginfo.name +'【'+thisdata.out_no+'】'"
    v-model="visiable"
    width="1300px"
    :show-close="false"
    @close="cleardata"
    center
  >
    <span>
      <el-form
        :ref="dialoginfo.modlecode + 'dialogform'"
        inline
        :model="thisdata"
        :rules="thisrules"
        label-width="100px"
      > 
        <el-form-item label="订单号" prop="order_no">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.order_no"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="客户订单号" prop="customer_order_no">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.customer_order_no"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="购货客户" prop="buyer_name">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.buyer_name"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="合同归属" prop="v_name">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.v_name"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="部门" prop="dept_name">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.dept_name"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="出入库仓库" prop="warehousename">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.warehousename"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
       
        <el-form-item label="单据时间" prop="docdatetime">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.docdatetime"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="审核时间" prop="postdatetime">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.postdatetime"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="摘要" prop="remark">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.remark"
            style="width: 260px"
            autocomplete="off"
          /> </el-form-item
        ><br />
      </el-form>

      <el-table
        :data="thisdata.dtl"
        style="width: 100%; margin-top: 5px"
        height="350px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column prop="goods_name" label="商品名称" width="240" />
        <el-table-column prop="goods_spec" label="规格" width="120" align="center" />

        <el-table-column
          prop="main_unit"
          label="主单位"
          width="80"
          align="center"
        />
        <el-table-column label="主单位数量" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.main_num / 10000).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sec_unit"
          label="辅单位"
          width="80"
          align="center"
        />
        <el-table-column label="辅单位数量" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.sec_num / 10000).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="成本单价" width="80" align="right">
          <template #default="scope">
            {{
              (
                (scope.row.cost_amount / scope.row.sec_num) *
                100
              ).toFixed(2)
            }}
          </template>
        </el-table-column>
        <el-table-column label="成本金额" width="120" align="right">
          <template #default="scope">
            {{ (scope.row.cost_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="销售单价" width="80" align="right">
          <template #default="scope">
            {{
              (
                (scope.row.sale_amount / scope.row.sec_num) *
                100
              ).toFixed(2)
            }}
          </template>
        </el-table-column>
        <el-table-column label="销售金额" width="120" align="right">
          <template #default="scope">
            {{ (scope.row.sale_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
       

       

      </el-table>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button
          type="primary"
          v-show="dialoginfo.modle != 'view'"
          @click="savedata"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script >
//import { ElMessage } from "element-plus";
//import GoodstypeCascaderVue from "../../../../../../controls/GoodstypeCascader.vue"

//import { ElMessage } from "element-plus"
import axios from "axios";

export default {
  emits: ["changedata", "dialogclose"],
  components: {},
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {
    return {
      activeName: "first",

      dtlvisiable: false,
      tempareas: [
        { value: 1, label: "常温" },
        { value: 2, label: "冰鲜" },
        { value: 3, label: "冷冻" },
      ],
      dtldata: { type: "1", v_name: "", mark: "", remark: "" },

      thisdata: { dtl: [], free: [] },
      visiable: false,
      yesno: [
        { label: "是", value: "1" },
        { label: "否", value: "99" },
      ],
      dtltypes: [
        { label: "通用数据", value: "1" },
        { label: "专用数据", value: "2" },
      ],
      thisrules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
        this.cleardtldata();
        this.visiable = true;
        console.log("dialog get", this.dialogdata);
        console.log("dialog info ", this.dialoginfo);
        this.getdata();
      }
    },
    // thisdata: function () {
    //     console.log(this.thisdata,this.thisdata)
    //     // this.thisdata.postdatetime=this.datetimeformart(this.thisdata.post_date)
    //     // this.thisdata.docdatetime=this.datetimeformart(this.thisdata.doc_date)
    // }
  },
  mounted() {
    this.activeName = "first";
  },
  methods: {
    getSummaries(data) {
      const sums = [];
      data.columns.forEach((element, index) => {
        var value = "";
        if (element.label == "商品名称") {
          sums[index] = "合计";
          return;
        }
        if (element.label == "主单位数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.main_num);
          });
          value = (value / 10000).toFixed(2);
        }
        if (element.label == "辅单位数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.sec_num);
          });
          value = (value / 10000).toFixed(2);
        }
        if (element.label == "成本金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.cost_amount);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "销售金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.sale_amount);
          });
          value = (value / 100).toFixed(2);
        }
        sums[index] = value;
      });

      return sums;
    },
    freetypeformatter(row, column, cellvalue) {
      if (cellvalue == 1) {
        return "票面折扣";
      }
      if (cellvalue == 2) {
        return "票外折扣";
      }
      return "数据错误";
    },

    datetimeformart(Value) {
      console.log("datetimeformart", Value);
      const date = new Date(parseInt(Value));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D, h, m, s] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
        dateNumFun(date.getHours()),
        dateNumFun(date.getMinutes()),
        dateNumFun(date.getSeconds()),
      ];
      var rtdate = `${Y}-${M}-${D} ${h}:${m}:${s}`;
      return rtdate;
    },
    // deldata(val) {
    //     // if (val.sid > 0) {
    //     //     this.thisdata.deletdtl.push(val);
    //     // }
    //     // for (var i = 0; i < this.thisdata.dtl.length; i++) {
    //     //     if (this.thisdata.dtl[i].row_code == val.row_code) {
    //     //         this.thisdata.dtl.splice(i, 1)
    //     //         return
    //     //     }
    //     // }
    // },

    dtlsave() {
      this.$bus.$emit("showloading");

      this.$bus.$emit("hideloading");
    },
    cleardtldata() {
      console.log("closedtladd", this.thiseddata);
    },

    tableheadClass() {
      return "text-align:center";
    },
    getdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.thisdata.dtl = response.dtls;

          this.thisdata.free = response.frees;
          this.thisdata.postdatetime = this.datetimeformart(
            this.thisdata.posted_time
          );
          this.thisdata.docdatetime = this.datetimeformart(
            this.thisdata.out_time
          );

          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    cleardata() {
      //this.thisdata = {};
      this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
        "v_name",
      ]);
      console.log("closedialog");
      this.$emit("dialogclose", {});
    },
    savedata() {
      console.log("savedata", this.thisdata);

      // this.$bus.$emit("showloading");
      // this.$refs[this.dialoginfo.modlecode + "dialogform"].validate((val) => {
      //     if (val) {
      //         axios
      //             .post("/" + this.dialoginfo.modlecode + "/save", {
      //                 data: this.thisdata,
      //             })
      //             .then(() => {
      //                 this.$emit("changedata", {});
      //                 this.visiable = false;
      //                 this.$bus.$emit("hideloading");
      //             })
      //             .catch(() => {
      //                 this.$bus.$emit("hideloading");
      //             });
      //     } else {
      //         this.$bus.$emit("hideloading");
      //     }
      // });
      //this.$bus.$emit("hideloading");
    },
  },
};
</script>
<style scoped>
:deep(.el-transfer-panel) {
  width: 300px;
}
</style>
