<template>
  <el-dialog :ref="dialoginfo.modlecode + 'dialog'" :title="dialoginfo.name" v-model="visiable" width="1200px"
    :show-close="false" @close="cleardata" center>
    <span>
      <el-form :ref="dialoginfo.modlecode + 'dialogform'" inline :model="thisdata" :rules="thisrules"
        label-width="100px">
        <el-form-item label="数据域名称" prop="v_name">
          <el-input :disabled="true" v-model="thisdata.v_name" style="width: 360px"
            autocomplete="off" />
        </el-form-item>

        <el-form-item label="是否同步" prop="issyndata">
          <el-select v-model="thisdata.issyndata" :filterable="true" style="width: 360px"
            :disabled="dialoginfo.modle == 'view'" class="localselect">
            <el-option v-for="item in yesno" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="默认往来" prop="">
          <ContactsCascaderVue :thisdata="thisdata.default_concatecode" @change="Concatecg"/>

        </el-form-item> 

       

      </el-form>


      <el-dialog title="添加同步项目" v-model="dtlvisiable" width="530px" :show-close="false" @close="cleardtldata" center>
        <span>
          <el-form :ref="dialoginfo.modlecode + 'dialogdtlform'" inline :model="dtldata" :rules="dtldatarules"
            label-width="100px">

            <el-form-item label="选择项目" prop="">
              <el-select v-model="ssvalue" filterable reserve-keyword placeholder="选择添加项目" @change="xmchangzhi">
                <el-option v-for="item in ssdatas" :key="item.row_code" :label="item.v_name" :value="item.row_code" />
              </el-select>
            </el-form-item>
            <el-form-item label="项目名称" prop="v_name">
              <el-input v-model="dtldata.v_name" style="width: 360px" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="项目标识" prop="mark">
              <el-input v-model="dtldata.mark" style="width: 360px" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="项目类型" prop="type">
              <el-select v-model="dtldata.type" :filterable="true" style="width: 360px" class="localselect" disabled>
                <el-option v-for="item in dtltypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="摘要" prop="remark">
              <el-input v-model="dtldata.remark" style="width: 360px" rows="4" type="textarea" autocomplete="off"
                disabled />
            </el-form-item>
          </el-form>
        </span>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dtlvisiable = false">取 消</el-button>
            <el-button type="primary" @click="dtlsavedata">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button type="primary" v-show="dialoginfo.modle != 'view'" @click="savedata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
import { ElMessage } from "element-plus";
import ContactsCascaderVue from "@/controls/ContactsCascader.vue";

import axios from "axios";

export default {
  emits: ["changedata", "dialogclose"],
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  components: {ContactsCascaderVue},
  data() {
    const validateword = (rule, value, callback) => {
      if (value.toString().match("^[^a-z A-Z]?[^a-z A-Z 0-9]+")) {
        callback(new Error("只可输入字母"));
      } else if (value == "") {
        callback(new Error("标识不可为空"));
      } else {
        callback();
      }
    };
    return {
      ssvalue: {},
      ssdatas: [],
      dtlvisiable: false,
      dtldata: { type: "1", v_name: "", mark: "", remark: "" },
      dtldatarules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
        mark: [{ required: true, validator: validateword, trigger: "blur" }],
      },
      thisdata: {},
      visiable: false,
      yesno: [
        { label: "是", value: '1' },
        { label: "否", value: '99' },
      ],
      dtltypes: [
        { label: "通用数据", value: "1" },
        { label: "专用数据", value: "2" },
      ],
      thisrules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
    };
  },
  watch: {

    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
        this.visiable = true;
        console.log("dialog get", this.dialogdata);
        console.log("dialog info ", this.dialoginfo);
        if (this.dialoginfo.modle != "add") {
          this.getdata();
        } else {
          this.thisdata.dtl = Array();
          this.thisdata.deldtl = Array();
          this.thisdata.v_name = "";
          this.thisdata.issyn = "99";
        }
      }
    },
  },
  mounted() {
    console.log(new Date() + "");
  },
  methods: {

    xmchangzhi(data) {
      console.log(data);
      this.ssdatas.map((val)=>
      { 
        if(val.row_code==data){
          this.dtldata=val;
          return;
        }
      })

    },
    dataru(data) {
      if (data.order == 1) {
        ElMessage.error({
          message: "项目已经位于最前，无法继续上移",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }
      let tpdtl = Array();
      this.thisdata.dtl.map((val, i) => {
        if (val.row_code == data.row_code) {
          let frontdata = JSON.parse(JSON.stringify(this.thisdata.dtl[i - 1]));
          let atdata = JSON.parse(JSON.stringify(val));
          frontdata.order = i + 1;
          atdata.order = i;
          tpdtl.splice(i - 1, 1);
          tpdtl.push(atdata);
          tpdtl.push(frontdata);
        } else {
          tpdtl.push(val);
        }
      });
      this.thisdata.dtl = tpdtl;
    },
    datard(data) {
      if (data.order == this.thisdata.dtl.length) {
        ElMessage.error({
          message: "项目已经位于最后，无法继续下移",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }
      console.log(data, this.thisdata);
      let tpdtl = Array();
      let is = 0;
      this.thisdata.dtl.map((val, i) => {
        if (val.row_code == data.row_code) {
          console.log(val);
          let atdata = JSON.parse(JSON.stringify(val));
          let nextdata = JSON.parse(JSON.stringify(this.thisdata.dtl[i + 1]));
          atdata.order = i + 2;
          nextdata.order = i + 1;
          tpdtl.push(nextdata);
          tpdtl.push(atdata);
          is = 1;
        } else {
          if (is == 1) {
            is = 0;
          } else {
            tpdtl.push(val);
          }
        }

      });
      this.thisdata.dtl = tpdtl
    },
    timechange(val) {
      if (val == null) {
        this.thisdata.starttime = 0;
      } else {
        this.thisdata.starttime = val.getTime();
      }
    },
    Concatecg(val){
      console.log(val);
      this.thisdata.default_concatecode=val[val.length-1];

    },
    deldata(data) {
      this.thisdata.dtl.map((val, i) => {
        if (val.row_code == data.row_code) {
          this.thisdata.dtl.splice(i, 1);
          if (val.sid > 0) {
            this.thisdata.deldtl.push(val);
          }
          return;
        }
      });
      this.thisdata.dtl.map((val, i) => {
        val.order = i + 1;
      });
      let dtt=Array();
      this.thisdata.inerlist.map((val) =>{
        let datahas=false
        this.thisdata.dtl.map((val2) =>{
          if(val.row_code==val2.row_code){
            datahas = true
            return
          }

        })
        if(datahas==false){
          dtt.push(val)
        }
      })
      if(dtt.length==0){
        
      this.ssvalue=''
      this.ssdatas=[]
      this.dtldata = { type: "1", v_name: "", mark: "", remark: "" }
      }else{
        
      this.ssvalue=dtt[0].row_code
      this.ssdatas=dtt
      this.dtldata=dtt[0]
      }
    },
    dtlsavedata() {
      this.$bus.$emit("showloading");

      this.$refs[this.dialoginfo.modlecode + "dialogdtlform"].validate(
        (val) => {
          if (val) {
            let havdata = false;
            this.thisdata.dtl.map((val) => {
              if (
                val.v_name == this.dtldata.v_name ||
                val.mark == this.dtldata.mark
              ) {
                ElMessage.error({
                  message: "项目名称或标识已存在，请修改后重试",
                  duration: 2000,
                  dangerouslyUseHTMLString: true,
                  center: false,
                });
                havdata = true;
                return;
              }
            });
            if (!havdata) {
              
              this.thisdata.dtl.push({
                sid: -1,
                row_code:this.dtldata.row_code,
                v_name: this.dtldata.v_name,
                mark: this.dtldata.mark,
                type: this.dtldata.type,
                type_v: this.dtldata.type_v,
                remark: this.dtldata.remark,
                order: this.dtldata.order,
              });
              this.dtlvisiable = false;
            }
          }
        }
      );

      this.$bus.$emit("hideloading");
    },
    cleardtldata() {
      this.$refs[this.dialoginfo.modlecode + "dialogdtlform"].clearValidate([
        "v_name",
        "mark",
      ]);
      let dtt=Array();
      this.thisdata.inerlist.map((val) =>{
        let datahas=false
        this.thisdata.dtl.map((val2) =>{
          if(val.row_code==val2.row_code){
            datahas = true
            return
          }

        })
        if(datahas==false){
          dtt.push(val)
        }
      })
      if(dtt.length==0){
        
      this.ssvalue=''
      this.ssdatas=[]
      this.dtldata = { type: "1", v_name: "", mark: "", remark: "" }
      }else{
        
      this.ssvalue=dtt[0].row_code
      this.ssdatas=dtt
      this.dtldata=dtt[0]
      }

    },
    tableheadClass() {
      return "text-align:center";
    },
    getdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.thisdata = response;
          let tp = Array();
          response.inerlist.map((val) => {
            let hasdata = false;
            response.dtl.map((val2) => {
              if (val.row_code == val2.row_code) {
                hasdata = true;
                return;
              }
            })
            if (hasdata == false) {
              tp.push(val);
            }
          })
          this.ssdatas = tp;
          this.ssvalue=tp[0].row_code;
          this.dtldata=tp[0];
          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    cleardata() {
      //this.thisdata = {};
      this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
        "v_name",
      ]);
      console.log("closedialog");
      this.$emit("dialogclose", {});
    },
    savedata() {
      this.$bus.$emit("showloading");
      this.$refs[this.dialoginfo.modlecode + "dialogform"].validate((val) => {
        if (val) {
          axios
            .post("/" + this.dialoginfo.modlecode + "/save", {
              data: this.thisdata,
            })
            .then(() => {
              this.$emit("changedata", {});
              this.visiable = false;
              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        }
      });
      //this.$bus.$emit("hideloading");
    },
    opendtladd() {
      this.dtlvisiable = true;
    },
  },
};
</script>
<style scoped>
</style>