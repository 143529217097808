<template>
  <div class="searchmodulpaceheight" :style="'height:' + divheight + 'px;'">
    <el-form
      :model="thisdata"
      ref="searchform"
      :inline="true"
      label-width="130px"
    >
      <el-form-item
        v-show="searchlist.doctime"
        label="单据日期："
        prop="doctimes"
      >
        <el-date-picker
          v-model="thisdata.doctimes"
          type="daterange"
          range-separator="至"
          start-placeholder="起始日期"
          end-placeholder="结束日期"
          @change="dataareachange_doctimes"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.posttime"
        label="审核日期："
        prop="posttimes"
      >
        <el-date-picker
          v-model="thisdata.posttimes"
          type="daterange"
          range-separator="至"
          start-placeholder="起始日期"
          end-placeholder="结束日期"
          @change="dataareachange_posttime"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.codename"
        label="编码/名称："
        prop="codename"
      >
        <el-input
          v-model="thisdata.codename"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.ordercode"
        label="订单号："
        prop="ordercode"
      >
        <el-input
          v-model="thisdata.ordercode"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.salecode"
        label="销售出入库单号："
        prop="salecode"
      >
        <el-input
          v-model="thisdata.salecode"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.billcode"
        label="账单号："
        prop="billcode"
      >
        <el-input
          v-model="thisdata.billcode"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.docnum"
        label="单据编号 ："
        prop="docnum"
      >
        <el-input
          v-model="thisdata.docnum"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
     
      <el-form-item
        v-show="searchlist.mecharname"
        label="商户名称："
        prop="mecharname"
      >
        <el-input
          v-model="thisdata.mecharname"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.warehousename"
        label="仓库名称："
        prop="warehousename"
      >
        <el-input
          v-model="thisdata.warehousename"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.departname"
        label="部门名称 ："
        prop="departname"
      >
        <el-input
          v-model="thisdata.departname"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.buyername"
        label="客户名称 ："
        prop="buyername"
      >
        <el-input
          v-model="thisdata.buyername"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.invobuyer"
        label="购货方信息 ："
        prop="invobuyer"
      >
        <el-input
          v-model="thisdata.invobuyer"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.invoinfo"
        label="发票号码/备注 ："
        prop="invoinfo"
      >
        <el-input
          v-model="thisdata.invoinfo"
          clearable
          class="searchinput"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.sentype"
        label="节点属性："
        prop="sentype"
      >
        <el-select v-model="thisdata.sentype" clearable class="searchinput">
          <el-option
            v-for="item in sentype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="searchlist.isview"
        label="显示属性："
        prop="sentype"
      >
        <el-select v-model="thisdata.isview" clearable class="searchinput">
          <el-option
            v-for="item in yesorno"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-show="searchlist.issyn" label="是否同步：" prop="sentype">
        <el-select v-model="thisdata.issyn" clearable class="searchinput">
          <el-option
            v-for="item in yesorno"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="searchlist.accountunit"
        label="会计单位:"
        prop="accountunit"
      >
        <AccountingunitSelectVue
          :rowcode="thisdata.accountunit"
          :multiple="true"
          @datachange="accountunitcg"
          :clearable="true"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.department"
        label="部门:"
        prop="department"
      >
        <DepartmentCascader
          :thisdata="thisdata.department"
          :clearable="true"
          @datachange="departmentcg"
          :multiple="true"
          :isview="searchlist.department"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.buyerinfor"
        label="购货客户:"
        prop="buyerinfor"
      >
        <BuyerInformationSeltct
          :code="thisdata.buyerinfor"
          :ismultiple="true"
          @datachange="buyerinforcg"
          :iscleanable="true"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.contacts"
        label="所属合同:"
        prop="contacts"
      >
        <ContactsCascader
          :thisdata="thisdata.contacts"
          :clearable="true"
          @datachange="contactscg"
          :isview="searchlist.contacts"
          :multiple="true"
        />
      </el-form-item>
      <el-form-item
        v-show="searchlist.warehouse"
        label="仓库名称:"
        prop="warehouse"
      >
        <WarehouseSelect
          :rowcode="thisdata.warehouse"
          :multiple="true"
          @datachange="warehousecg"
          :clearable="true"
        />
      </el-form-item>

      <el-form-item
        v-show="searchlist.goodstype1"
        label="物料分类1:"
        prop="goodstype1"
      >
        <GoodstypeCascaderVue
          :thisdata="thisdata.goodstype1"
          :typenumber="1"
          :multiple="true"
          @datachange="goodstype1cg"
          :showalllevels="false"
          :clearable="true"
          :isview="searchlist.goodstype1"
        >
        </GoodstypeCascaderVue>
      </el-form-item>

      <el-form-item
        v-show="searchlist.goodstype2"
        label="物料分类2:"
        prop="goodstype2"
      >
        <GoodstypeCascaderVue
          :thisdata="thisdata.goodstype2"
          :typenumber="2"
          :multiple="true"
          @datachange="goodstype2cg"
          :showalllevels="false"
          :clearable="true"
          :isview="searchlist.goodstype2"
        >
        </GoodstypeCascaderVue>
      </el-form-item>
      <el-form-item
        v-show="searchlist.goodstype3"
        label="物料分类3:"
        prop="goodstype3"
      >
        <GoodstypeCascaderVue
          :thisdata="thisdata.goodstype3"
          :typenumber="3"
          :multiple="true"
          @datachange="goodstype3cg"
          :showalllevels="false"
          :clearable="true"
          :isview="searchlist.goodstype3"
        >
        </GoodstypeCascaderVue>
      </el-form-item>
      <el-form-item
        v-show="searchlist.goodstype4"
        label="物料分类4:"
        prop="goodstype4"
      >
        <GoodstypeCascaderVue
          :thisdata="thisdata.goodstype4"
          :typenumber="4"
          :multiple="true"
          @datachange="goodstype4cg"
          :showalllevels="false"
          :clearable="true"
          :isview="searchlist.goodstype4"
        >
        </GoodstypeCascaderVue>
      </el-form-item>
      <el-form-item
        v-show="searchlist.goodstype5"
        label="物料分类5:"
        prop="goodstype5"
      >
        <GoodstypeCascaderVue
          :thisdata="thisdata.goodstype5"
          :typenumber="5"
          :multiple="true"
          @datachange="goodstype5cg"
          :showalllevels="false"
          :clearable="true"
          :isview="searchlist.goodstype5"
        >
        </GoodstypeCascaderVue>
      </el-form-item>
      <el-form-item
        v-show="searchlist.goodstype6"
        label="物料分类6:"
        prop="goodstype6"
      >
        <GoodstypeCascaderVue
          :thisdata="thisdata.goodstype6"
          :typenumber="6"
          :multiple="true"
          @datachange="goodstype6cg"
          :showalllevels="false"
          :clearable="true"
          :isview="searchlist.goodstype6"
        >
        </GoodstypeCascaderVue>
      </el-form-item>
      <el-form-item
        v-show="searchlist.billtype"
        label="账单类型:"
        prop="billtype"
      >
        <el-select v-model="thisdata.billtype" clearable>
          <el-option
            v-for="item in searchtypedatas.billtype"
            :key="item.value"
            :label="item.lable"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="searchlist.receipttype"
        label="暂估类型:"
        prop="receipttype"
      >
        <el-select v-model="thisdata.receipttype" clearable>
          <el-option
            v-for="item in receipttype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-show="searchlist.status" label="状态：" prop="status">
        <el-select v-model="thisdata.status" clearable class="searchinput">
          <el-option
            v-for="item in searchtypedatas.status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
  <div class="searchmodulpacefoot">
    <el-button @click="cleandatas">清除条件</el-button>
    <el-button type="primary" @click="searchstart">开始查询</el-button>
  </div>
</template>
<script>
import GoodstypeCascaderVue from "./GoodstypeCascader.vue";
import AccountingunitSelectVue from "./AccountingunitSelect.vue";
import ContactsCascader from "./ContactsCascader.vue";
import WarehouseSelect from "./WarehouseSelect.vue";
import BuyerInformationSeltct from "./BuyerInformationSeltct.vue";
import DepartmentCascader from "./DepartmentCascader.vue";

export default {
  emits: ["updsearch"],
  components: {
    GoodstypeCascaderVue,
    AccountingunitSelectVue,
    ContactsCascader,
    WarehouseSelect,
    BuyerInformationSeltct,
    DepartmentCascader,
  },
  props: {
    searchdata: { required: true },
    areaheight: { required: true },
    searchtypedatas: {},
  },
  data() {
    return {
      thisdata: {
        codename: "",
        sentype: "",
        status: "",
        goodstype1: "",
        accountunit: [],
        doctimes: ["", ""],
        posttime: ["", ""],
        contacts: [],
        warehouse: [],
        buyerinfor: [],
        department: [],
        billtype: "",
        receipttype: "",
        docnum: "",
        invoinfo: "",
        invobuyer: "",
        mecharname: "",
        warehousename: "",
        departname: "",
        buyername: "",
        ordercode:"",
        salecode:"",
        billcode:"",
      },
      divheight: 10,

      sentype: [
        { value: 0, label: "汇总节点" },
        { value: 1, label: "明细节点" },
      ],
      receipttype: [
        { value: 0, label: "无暂估" },
        { value: 1, label: "暂估单" },
        { value: 2, label: "暂估确认" },
      ],
      yesorno: [
        { value: 1, label: "是" },
        { value: 99, label: "否" },
      ],
      searchlist: {
        codename: false,
        sentype: false,
        status: false,
        goodstype1: false,
        goodstype2: false,
        goodstype3: false,
        goodstype4: false,
        goodstype5: false,
        goodstype6: false,
        accountunit: false,
        isview: false,
        doctime: false,
        contacts: false,
        warehouse: false,
        buyerinfor: false,
        department: false,
        billtype: false,
        receipttype: false,
        docnum: false,
        invoinfo: false,
        invobuyer: false,
        mecharname: false,
        warehousename: false,
        departname: false,
        buyername: false,
        ordercode:false,
        salecode:false,
        billcode:false,
      },
    };
  },
  mounted() {
    console.log("searchmodul get value", this.searchdata);
    for (let key in this.searchdata) {
      this.thisdata[key] = this.searchdata[key];
      this.searchlist[key] = true;
    }
    console.log("searchpage", this.$refs);
  },
  methods: {
    dataareachange_posttime(val) {
      if (val != null) {
        this.thisdata.posttime[0] = val[0].getTime();
        this.thisdata.posttime[1] = val[1].getTime() + 86399999;
      } else {
        this.thisdata.posttime = ["", ""];
      }
    },
    dataareachange_doctimes(val) {
      if (val != null) {
        this.thisdata.doctime[0] = val[0].getTime();
        this.thisdata.doctime[1] = val[1].getTime() + 86399999;
      } else {
        this.thisdata.doctime = ["", ""];
      }
    },

    buyerinforcg(val) {
      console.log("buyerserchecode", val);
      this.thisdata.buyerinfor = [];
      for (var i = 0; i < val.length; i++) {
        this.thisdata.buyerinfor.push(val[i].row_code);
      }
    },

    warehousecg(val) {
      console.log("warehousserchecode", val);
      this.thisdata.warehouse = [];
      for (var i = 0; i < val.length; i++) {
        this.thisdata.warehouse.push(val[i].row_code);
      }
    },
    departmentcg(val) {
      this.thisdata.department = [];
      for (var i = 0; i < val.length; i++) {
        this.thisdata.department.push(val[i].value);
      }
    },

    contactscg(val) {
      this.thisdata.contacts = [];
      for (var i = 0; i < val.length; i++) {
        this.thisdata.contacts.push(val[i].value);
      }
    },
    accountunitcg(val) {
      console.log("account", val);

      this.thisdata.accountunit = [];
      for (var i = 0; i < val.length; i++) {
        this.thisdata.accountunit.push(val[i].row_code);
      }
    },
    goodstype1cg(val) {
      let ccdata = Array();
      val.map((val) => {
        ccdata.push(val.value);
      });
      this.thisdata.goodstype1 = ccdata;
      console.log("goodstype1cg", ccdata);
    },
    goodstype2cg(val) {
      let ccdata = Array();
      val.map((val) => {
        ccdata.push(val.value);
      });
      this.thisdata.goodstype2 = ccdata;
      console.log("goodstype2cg", ccdata);
    },
    goodstype3cg(val) {
      let ccdata = Array();
      val.map((val) => {
        ccdata.push(val.value);
      });
      this.thisdata.goodstype3 = ccdata;
      console.log("goodstype3cg", ccdata);
    },
    goodstype4cg(val) {
      let ccdata = Array();
      val.map((val) => {
        ccdata.push(val.value);
      });
      this.thisdata.goodstype4 = ccdata;
      console.log("goodstype4cg", ccdata);
    },
    goodstype5cg(val) {
      let ccdata = Array();
      val.map((val) => {
        ccdata.push(val.value);
      });
      this.thisdata.goodstype5 = ccdata;
      console.log("goodstype5cg", ccdata);
    },
    goodstype6cg(val) {
      let ccdata = Array();
      val.map((val) => {
        ccdata.push(val.value);
      });
      this.thisdata.goodstype6 = ccdata;
      console.log("goodstype6cg", ccdata);
    },
    cleandatas() {
      //
      for (let key in this.thisdata) {
        if (key == "serachmap") {
          continue;
        }
        if (key.indexOf("time") >= 0) {
          if (typeof this.thisdata[key] == "object") {
            this.thisdata[key] = ["", ""];
          } else {
            this.thisdata[key] = "";
          }
          continue;
        }
        if (typeof this.thisdata[key] == "object") {
          this.thisdata[key] = [];
        } else {
          this.thisdata[key] = "";
        }
      }
    },
    searchstart() {
      console.log("searchdata start", this.thisdata);
      this.$emit("updsearch", this.thisdata);
    },
  },
  watch: {
    areaheight: function () {
      this.$nextTick(() => {
        this.divheight = this.areaheight - 288;
      });
    },
  },
};
</script>
<style scoped>
.searchmodulpacefoot {
  text-align: center;
}

.searchinput {
  width: 230px;
}

.el-select-dropdown__item {
  text-align: left;
}
</style> 
