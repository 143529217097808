<template>
    <el-table
      :data="tabledata"
      style="width: 100%; margin-top: 5px"
      :height="tableheight"
      :header-cell-style="tableheadClass"
      stripe
      row-key="row_code"
      border
      default-expand-all
      ref='hdatatable'
      :tree-props="{}"
      @selection-change="invoceSelectionChange"
    >

      <el-table-column type="selection" width="40" />
      <el-table-column prop="group_num" label="组编号" width="80" align="center"/>
      <el-table-column
        prop="doc_date"
        label="单据日期"
        width="110"
        :formatter="dateformart"
        align="center"
      />
      <el-table-column prop="datafieldname" label="所属商户" width="200" align="left"/>
      
      <el-table-column prop="contactname" label="所属合同" width="280" align="left"/>
  

      <el-table-column label="申请状态" width="95" align="right">
        <template #default="scope">
          <span v-if="scope.row.status == '-1'">待确认</span>
          <span v-else-if="scope.row.status == '0'">已确认</span>
          <span v-else-if="scope.row.status == '99'">作废</span>
          <span v-else>数据错误</span>
        </template>
      </el-table-column>

      <el-table-column prop="remark" label="备注" width="220" align="left"/>

      <el-table-column width="150" align="center">
        <template #header
          ><el-button
            @click="openadd()"
            :disabled="!operar.add"
            size="small"
            >新建</el-button
          ><el-button
            @click="openedit(invselectrows)"
            :disabled="!operar.check"
            size="small"
            >合并</el-button
          ></template
        >
        <template #default="scope">
          <span v-if="true">
          <el-button type="text" @click="openview([scope.row])" size="small">
            查&nbsp;&nbsp;看</el-button
          ><br/>
          </span>
          <span v-if="scope.row.status < 0">
          <el-button type="text" @click="openedit([scope.row])" size="small">
            编&nbsp;&nbsp;辑</el-button
          ><br/>
          </span>
          <span v-if="scope.row.status < 0 ">
            <el-button
              type="text"
              @click="delinvos(scope.row, 99)"
              :disabled="!operar.del || scope.row.status>=0
              "
              size="small"
              >删&nbsp;&nbsp;除</el-button
            ><br/>
          </span>
  
  
         
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenow"
      :page-sizes="[50, 100, 300, 500, 1000]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalrow"
    >
    </el-pagination>
    <MainDialog
      :dialoginfo="dialoginfo"
      :dialogdata="dialogdata"
      :isvisiable="dialogvis"
      @changedata="flashdata"
      @dialogclose="editclose"
    ></MainDialog>
  





  </template>
  <script >
  import MainDialog from "./MainDialog.vue";
  //import ContactsCascader from "../../../../../../controls/ContactsCascader.vue";
  
  import { ElMessage, ElMessageBox } from "element-plus";
  import axios from "axios";
  import { postback } from "@/publiccomm";
  
  export default {
    components: { MainDialog },
    emits: [],
    props: {
      searchdata: { required: true },
      selfmodul: { required: true },
      areaheight: { required: true },
    },
    data() {
      return {
        dlsvsiable:false,
        checkmark: false,
        checkrout: 0,
        checktaxst: "",
        ceckcode: "",
        ceckcodeinput: "",
        ceckrowdata: [],
        viewinvovisable: false,
        viewinvodata: [],
        dialogvis: false,
        cdvisiable: false,
        tableheight: 60,
        checked1:false,
        checked2:false,
        checked3:false,
        contactinfo: "",
        invselectrows:[],
        editsinvodata:[],
        editsinvovisable:false,
        totalrow: 0,
        pagenow: 1,
        pagesize: 100,
        dataflites: [],
        dataflite: "",
        dialoginfo: { modlecode: this.selfmodul["code"] },
        dialogdata: {},
        tabledata: [],
        mysearch: {},
        operar: {
          conditionstr: "",
          add: false,
          edit: false,
          del: false,
          check: false,
          uncheck: false,
        },
  
        viewlist: {
          add: false,
          edit: false,
          del: false,
          check: false,
          uncheck: false,
        },
      };
    },
    mounted() {
      let operardata = JSON.parse(localStorage.getItem("authlist"));
      let controldata = operardata[this.selfmodul["code"]].operation;
      console.log(controldata);
      this.operar.add = (controldata & 128) == 128;
      this.operar.del = (controldata & 64) == 64;
      this.operar.edit = (controldata & 32) == 32;
      this.operar.check = (controldata & 16) == 16;
      this.operar.uncheck = (controldata & 8) == 8;
      this.operar.conditionstr = operardata[this.selfmodul["code"]].conditionstr;
  
      operardata = JSON.parse(localStorage.getItem("viewlist"));
      controldata = operardata[this.selfmodul["code"]].operation;
      this.viewlist.add = (controldata & 128) == 128;
      this.viewlist.del = (controldata & 64) == 64;
      this.viewlist.edit = (controldata & 32) == 32;
      this.viewlist.check = (controldata & 16) == 16;
      this.viewlist.uncheck = (controldata & 8) == 8;
  
      console.log(this.operar);
      this.flashdata();
      this.dialoginfo.operar = this.operar;
      this.dialoginfo.viewlist = this.viewlist;
  
      console.log("areaheigh", this.areaheight);
    },
    methods: {
      downloadinvopcallback(request){
        if (request != null) {
          request = JSON.parse(request);
          if(request.rescode==0){
            window.open("/api_v3/interface/getfile?file=" + request.data, "_self");
            this.dlsvsiable=false
          }else{
            ElMessage.error({
            message: request.resmsg,
            duration: 2000,
          });
          }
        }
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
      },
      downloadinvop(){
        if(!this.checked1 && !this.checked2 && !this.checked3){
          ElMessage.error({
            message: "请至少选择一种文件类型下载！！！",
            duration: 2000,
          });
          return;
        }
        var filelist=[];
        this.invselectrows.map((val)=>{
          if(val.file_pdf!='' && this.checked1){
            filelist.push('/upload/'+val.file_pdf)
          }
          if(val.file_ofd!=''  && this.checked2){
            filelist.push('/upload/'+val.file_ofd)
          }
          if(val.file_xml!=''  && this.checked3){
            filelist.push('/upload/'+val.file_xml)
          }
        })
        if(filelist.length==0){
          ElMessage.error({
            message: "选择的文件类型没有需要下载的数据，请检查后重试！！！",
            duration: 2000,
          });
          return;
        }
        postback(
          "/interface/getfilemakezip",
          {filelist:filelist},
          this.downloadinvopcallback
        );
  
  
  
      },
      opendltg(){
        var num=0;
        this,this.invselectrows.map((val)=>{
          if(val.file_pdf!=''){
            num++;
          }
          if(val.file_ofd!=''){
            num++;
          }
          if(val.file_xml!=''){
            num++;
          }
        })
        if(num==0){
          ElMessage.error({
            message: "未选择明细或选择的明细无可下载文件，请检查后再试",
            duration: 2000,
          });
          return;
        }
        this.dlsvsiable=true;
      },
      invoceSelectionChange(val){
        this.invselectrows=val;
      },
      downloadinv(row,type){
        var filename="";
        if(type==1){
          filename=row.file_pdf;
          
        }
        if(type==2){
          filename=row.file_ofd;
        }
  
        if(type==3){
          filename=row.file_xml;
        }
        window.open("/api_v3/interface/getfile?file=/upload/" + filename, "_self");
      },
      getmounth(Value) {
        var data = new Date();
        if (Value != "") {
          data = new Date(parseInt(Value));
        }
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        console.log("getmounth", dateNumFun(data.getMonth() + 1));
        return dateNumFun(data.getMonth() + 1);
      },
      checkboxop(val, way) {
        this.ceckrowdata = val;
        this.checkrout = way;
        var ceckcode = "100" + Math.round(Math.random() * 10000000);
        this.ceckcode = ceckcode.substring(5, 9);
        this.ceckcodeinput = "";
  
        switch (way) {
          case 0:
            this.checktaxst =
              "发票确认后无法撤销，数据将通过接口提交至税控系统处理。请检查无误后输入验证码进行确认！！";
            break;
          case 4:
            this.checktaxst =
              "发票作废无法撤销，数据将通过接口提交至税控系统处理。请检查无误后输入验证码进行确认！！";
            break;
          case 20:
            this.checktaxst =
              "发票红冲无法撤销，数据将通过接口提交至税控系统处理。请检查无误后输入验证码进行确认！！";
            break;
          case 99:
            this.checktaxst =
              "待确认发票删除后无法恢复，发票生成时占用额度也不可恢复。如确需删除待确认发票，请输入验证码进行确认！！";
            break;
        }
        this.checkmark = true;
      },
      ceckmarkcech() {
        if (this.ceckcodeinput == this.ceckcode) {
          this.$bus.$emit("showloading");
          axios
            .post("/" + this.dialoginfo.modlecode + "/checkinvo", {
              data: { rowdata: this.ceckrowdata, way: this.checkrout },
            })
            .then(() => {
              //this.billssearchresults.rows = response.rows;
              this.flashdata();
              this.checkmark=false;
              this.viewinvovisable = false;
              
              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        } else {
          var ceckcode = "100" + Math.round(Math.random() * 10000000);
          this.ceckcode = ceckcode.substring(5, 9);
          this.ceckcodeinput = "";
        }
      },
      checkinvo() {
        ElMessageBox.confirm(
          "确认后数据将提交至税控接口开具发票，本操作不可撤销！！！\r确认开具本张发票吗？",
          "警告"
        )
          .then(() => {
            this.$bus.$emit("showloading");
            axios
              .post("/" + this.dialoginfo.modlecode + "/checkinvo", {
                data: this.viewinvodata,
              })
              .then(() => {
                //this.billssearchresults.rows = response.rows;
                this.viewinvovisable = false;
                this.$bus.$emit("hideloading");
              })
              .catch(() => {
                this.$bus.$emit("hideloading");
              });
          })
          .catch(() => {});
      },
      invoedittablesum(data) {
        const sums = [];
        let sumam=0;
        data.columns.forEach((element, index) => {
          var value = "";
          if (element.label == "商品名称") {
            sums[index] = "合计";
            return;
          }
          if (element.label == "数量") {
            value = Number(0);
            data.data.forEach((row) => {
              value += Number(row.num);
            });
            value = (value / 1000000).toFixed(2);
          }
          if (element.label == "含税金额") {
            value = Number(0);
            data.data.forEach((row) => {
              if(row.taxamount!=0 && row.taxamount!=null){
                value += Number(row.taxamount);
                sumam+=Number(row.taxamount);
              }else{
                value += Number(row.notaxamount) + Number(row.tax);
                sumam+=Number(row.notaxamount) + Number(row.tax);
              }
              
            });
            value = (value / 100).toFixed(2);
          }
          if (element.label == "含税折扣额") {
            value = Number(0);
            data.data.forEach((row) => {
              if(row.taxamount!=0 && row.taxamount!=null){
                value += Number(row.taxdiscont);
                sumam-= Number(row.taxdiscont);
              }
              
            });
            value = (value / 100).toFixed(2);
          }
          if (element.label == "税额") {
            value = Number(0);
            data.data.forEach((row) => {
              if(row.taxamount!=0 && row.taxamount!=null){
                if(row.taxlv!=-1 &&row.taxlv!='-1'){
                  value += Number(row.taxamount)*Number(row.taxlv)/1000000;
                }
              }else{
                value += Number(row.tax);
              }
              
            });
            value = (value / 100).toFixed(2);
          }
          this.editsinvodata.total_amount=sumam;
  
          sums[index] = value;
        });
  
        return sums;
      },
      reprinte(data){
        console.log('updateinv',data);
        ElMessageBox.confirm(
          "确认后数据将提交至税控接口开具发票，本操作不可撤销！！！\r确认更改并开具本张发票吗？",
          "警告"
        )
          .then(() => {
            this.$bus.$emit("showloading");
            axios
              .post("/" + this.dialoginfo.modlecode + "/updateinvodata", {
                data: data,
              })
              .then(() => {
                //this.billssearchresults.rows = response.rows;
                this.editsinvovisable = false;
                this.flashdata();
                this.$bus.$emit("hideloading");
              })
              .catch(() => {
                this.$bus.$emit("hideloading");
              });
          })
          .catch(() => {});
  
      },
      invoviewtablesum(data) {
        const sums = [];
        let suminam=0;
        data.columns.forEach((element, index) => {
          var value = "";
          if (element.label == "商品名称") {
            sums[index] = "合计";
            return;
          }
          if (element.label == "数量") {
            value = Number(0);
            data.data.forEach((row) => {
              value += Number(row.num);
            });
            value = (value / 1000000).toFixed(2);
          }
          if (element.label == "含税金额") {
            value = Number(0);
            data.data.forEach((row) => {
              if(row.taxamount!=0 && row.taxamount!=null){
                value += Number(row.taxamount);
                suminam+=Number(row.taxamount);
              }else{
                value += Number(row.notaxamount) + Number(row.tax);
                suminam+=Number(row.notaxamount) + Number(row.tax);
              }
              
            });
            value = (value / 100).toFixed(2);
          }
          if (element.label == "含税折扣额") {
            value = Number(0);
            data.data.forEach((row) => {
              if(row.taxamount!=0 && row.taxamount!=null){
                value += Number(row.taxdiscont);
                suminam-=Number(row.taxdiscont);
              }
              
            });
            value = (value / 100).toFixed(2);
          }
          if (element.label == "税额") {
            value = Number(0);
            data.data.forEach((row) => {
              if(row.taxamount!=0 && row.taxamount!=null){
                if(row.taxlv!=-1 &&row.taxlv!='-1'){
                  value += Number(row.taxamount)*Number(row.taxlv)/1000000;
                }
              }else{
                value += Number(row.tax);
              }
              
            });
            value = (value / 100).toFixed(2);
          }
  
          sums[index] = value;
        });
        this.viewinvodata.total_amount= suminam ;
  
        return sums;
      },
      finallnum(val) {
        if (val == "" || val == null || val == 0) {
          return "0.00";
        }
        var num = val;
        num = num.toFixed(2);
        var fh = "";
        if (num.substring(0, 1) == "-") {
          fh = "-";
          num = num.substring(1);
        }
        var cutnum = 0;
        while (num.length > 6) {
          if (num.length % 3 != 0) {
            cutnum = num.length % 3;
            fh += num.substring(0, cutnum);
            fh += ",";
            num = num.substring(cutnum);
          } else {
            fh += num.substring(0, 3);
            fh += ",";
            num = num.substring(3);
          }
        }
        fh += num;
        return fh;
      },
      editerrorinvos(val){
        this.$bus.$emit("showloading");
        axios
          .post("/" + this.dialoginfo.modlecode + "/getbilldtl", { data: val })
          .then((response) => {
            //this.billssearchresults.rows = response.rows;
            //this.searchdata = JSON.parse(JSON.stringify(data)),
            var tp=JSON.parse(JSON.stringify(val))
            tp.dtl = response;
            this.editsinvodata = tp;
            this.editsinvovisable = true;
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },
      viewinvos(val) {
        this.$bus.$emit("showloading");
        axios
          .post("/" + this.dialoginfo.modlecode + "/getbilldtl", { data: [val] })
          .then((response) => {
            //this.billssearchresults.rows = response.rows;
            val.dtl = response;
            this.viewinvodata = val;
            this.viewinvovisable = true;
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },
      delinvos(Value){
        ElMessageBox.confirm(
          "即将删除本申请单；本操作不可撤销，是否确定！！！！！",
          "警告",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.$bus.$emit("showloading");
          axios
            .post("/" + this.dialoginfo.modlecode + "/deleitem", { data: Value })
            .then(() => {
              this.flashdata();

              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        });
      },
      dateformart2(Value) {
        //console.log("datetimeformart", Value);
        let time = Value;
        if (time == 0 || time == "") {
          return "";
        }
        const date = new Date(parseInt(time));
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        const [Y, M, D] = [
          date.getFullYear(),
          dateNumFun(date.getMonth() + 1),
          dateNumFun(date.getDate()),
        ];
        var rtdate = `${Y}-${M}-${D}`;
        return rtdate;
      },
      chcat() {
        this.$bus.$emit("showloading");
  
        if (this.dataflite == "") {
          ElMessage.error({
            message: "请先选择商户和往来信息！！！",
            duration: 2000,
          });
          this.$bus.$emit("hideloading");
        } else {
          this.dialogdata = this.dataflite;
          this.cdvisiable = false;
          this.dialogvis = true;
          this.$bus.$emit("hideloading");
        }
      },
      clearccdata() {
        this.contactinfo = "";
      },
      contactscg(val) {
        this.contactinfo = val[0].value;
      },
      statusformart(row, column, cellValue) {
        if (cellValue == 0) {
          return "待确认";
        }
        if (cellValue == 50) {
          return "已确认";
        }
        if (cellValue == 99) {
          return "作废";
        }
        return "数据错误";
      },
      dateformart(row, column, cellValue) {
        if (cellValue == 0) {
          return "";
        }
        const date = new Date(parseInt(cellValue));
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        const [Y, M, D, h, m, s] = [
          date.getFullYear(),
          dateNumFun(date.getMonth() + 1),
          dateNumFun(date.getDate()),
          dateNumFun(date.getHours()),
          dateNumFun(date.getMinutes()),
          dateNumFun(date.getSeconds()),
        ];
        var rtdate = `${Y}-${M}-${D} ${h}:${m}:${s}`;
        rtdate = `${Y}-${M}-${D}`;
        return rtdate;
      },
  
      handleSizeChange(val) {
        let pgs = Math.ceil(this.totalrow / val);
        this.pagesize = val;
        if (pgs >= this.pagenow) {
          this.flashdata();
        }
      },
      handleCurrentChange(val) {
        this.pagenow = val;
        this.flashdata();
      },
      editclose(data) {
        this.dialogvis = false;
        console.log(data);
      },
      flashdata() {
        this.$bus.$emit("showloading");
        let mysearchdata = JSON.parse(JSON.stringify(this.searchdata));
        mysearchdata.pagenow = this.pagenow;
        mysearchdata.pagesize = this.pagesize;
        this.$refs.hdatatable.clearSelection();
  
        axios
          .post("/" + this.selfmodul["code"] + "/getlist", { data: mysearchdata })
          .then((response) => {
            this.dataflites = response["datafield"];
            this.tabledata = response["rows"];
            this.totalrow = response["total"] * 1;
  
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },
      openview(data) {
        console.log("view", data);
        this.dialoginfo.name = "查看" + this.selfmodul["name"] + "";
        this.dialoginfo.modle = "view";
        this.dialogdata = {canchange:true,status:-1,hdatas:data,bills:[], dtl: [], frees: [] };
        this.dialogvis = true;
      },
  
      openedit(data) {
        console.log("edit", data);

        if(data.length==0 ){
          ElMessage.error({
            message: '请先选择单据！！！！',
            duration: 2000,
          });
          return;
        }
        if(data.length==1 ){
          this.dialoginfo.name = "编辑" + this.selfmodul["name"] + "";
          this.dialoginfo.modle = "edit";
          this.dialogdata = {canchange:true,status:-1,hdatas:data,bills:[], dtl: [], frees: [] };
          this.dialogvis = true;
        }else{
          var contacode='';
          var status=-99;
          var checkpass=true;
          data.map((item)=>{
            if(status==-99){
              contacode=item.contacts_code;
              status=item.status;
            }else{
              console.log('aaa',contacode,item.contacts_code,status,item.status)
              if(contacode!=item.contacts_code || status!=item.status){
                ElMessage.error({
                  message: '合并的单据所属合同及状态必须相同，请重新选择单据！！！！',
                  duration: 2000,
                });
                this.$refs.hdatatable.clearSelection();
                checkpass=false;
                return;
              }
            }
          })
          if(!checkpass){
            return;
            
          }
          this.dialoginfo.name = "编辑" + this.selfmodul["name"] + "";
          this.dialoginfo.modle = "edit";
          this.dialogdata = {canchange:true,status:-1,hdatas:data,bills:[], dtl: [], frees: [] };
          this.dialogvis = true;

        }
      },
      openadd() {
        this.cdvisiable = true;
        this.dialogdata = {canchange:true,status:-1,hdatas:[],bills:[], dtl: [], frees: [] };
        this.dialoginfo.name = '新建' + this.selfmodul['name'] + ''
        this.dialoginfo.modle = 'add'
        this.dialogvis = true
      },
  
      deldata(data) {
        console.log("del", data);
        axios
          .post("/" + this.selfmodul["code"] + "/delet", { data: data })
          .then(() => {
            this.flashdata();
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      },
  
      tableheadClass() {
        return "text-align:center";
      },
    },
    watch: {
      searchdata: function () {
        if (JSON.stringify(this.mysearch) != JSON.stringify(this.searchdata)) {
          //查询条件变动，重新拉取数据
          this.mysearch = JSON.parse(JSON.stringify(this.searchdata));
          this.flashdata();
        }
      },
      areaheight: function () {
        this.$nextTick(() => {
          this.tableheight = this.areaheight - 284;
        });
      },
    },
  };
  </script>
  <style scoped>
  :deep(.invoviewdata) {
    width: 340px;
    display: inline-block;
    padding: 0px;
    margin-bottom: 8px;
  }
  
  :deep(.myadditem .el-form-item__label) {
    font-weight: 700;
    padding: 0px;
    margin: 0px;
  }
  
  :deep(.invoviewtool) {
    width: 180px;
    display: inline-block;
    padding: 0px;
    margin-bottom: 8px;
    text-align: right;
  }
  </style>